import React, { useState, useEffect } from 'react';
import { httpGetCourseDetailsForUser } from '../../../../Hooks/admin/admin.get.hooks';
import { useParams } from 'react-router-dom';
import { FormContainer } from '../../../CreateRoutes/CreateForm.styles';
import { AuthCheckAdmin } from '../../../../Utils/AuthCheck/AdminCheck.auth';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import CourseDetails from './CourseDetails.component';
import Certificate from './CertificateAdminGenerator.component';
import TDGCertificate from './TDGCertificateAdmin.component';
import DeleteCourseFromUser from './DeleteCourse.component';
// Verify that the user is an admin
const UserCourseDetails = () => {
  const { id, courseId } = useParams();
  const [user, setUser] = useState(null); // Initialize as null to differentiate between loading and empty state
  const [course, setCourse] = useState(null); // Initialize as null
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const GetInfo = async () => {
      try {
        const res = await httpGetCourseDetailsForUser(id, courseId);
        setUser(res.foundUser || {});
        setCourse(res.course[0] || []);
        console.log(res, 'res');
      } catch (err) {
        setError('An error happened');
      } finally {
        setIsLoading(false); // Set loading to false after the API call
      }
    };
    GetInfo();
  }, [id, courseId]);

  if (isLoading) {
    return <p>Loading...</p>; // Show a loading message while fetching data
  }

  if (error) {
    return <p>{error}</p>; // Show an error message if something goes wrong
  }

  return (
    <>
      <AuthCheckAdmin>
        <HelmetProvider>
          <Helmet>
            <title>Course Details for User | Yoda Safety Services</title>
            <meta
              name="Course For User"
              content="Displays content of course for a user"
            />
          </Helmet>
        </HelmetProvider>
        <FormContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <BreadCrumbs one="Admin Home" oneLink="/admin" two={'Edit User'} />
          </div>
          <h2>COURSE DETAILS for {user?.firstName || 'Unknown User'}</h2>
          <CourseDetails c={course} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {submitted ? (
              <div>
                <p>
                  Certificate is uploading to the database, this may take a
                  moment...
                </p>
                <span>Once uploaded refresh the page</span>
              </div>
            ) : (
              <>
                {/* Render Certificate only if course and user data are available */}
                {course?.courseName &&
                  user?.firstName &&
                  !course?.courseName.includes('TDG') && (
                    <Certificate
                      name={`${user.firstName} ${user.lastName}`}
                      course={course.courseName}
                      dateCompleted={course.date}
                      expiryDate={course.expiryDate}
                      certNumber={course.certNum}
                      courseId={course.courseId}
                      userId={user._id}
                      setSubmitted={setSubmitted}
                    />
                  )}

                {/* Render TDGCertificate only if course and user data are available */}
                {course?.courseName &&
                  user?.firstName &&
                  course?.courseName.includes('TDG') && (
                    <TDGCertificate
                      name={`${user.firstName} ${user.lastName}`}
                      dateCompleted={course.date}
                      expiryDate={course.expiryDate}
                      employer={course.employer}
                      address={course.address}
                      certNumber={course.certNum}
                      courseId={course.courseId}
                      userId={user._id}
                      setSubmitted={setSubmitted}
                    />
                  )}
              </>
            )}
          </div>
          <DeleteCourseFromUser userId={id} courseId={course._id} />
        </FormContainer>
      </AuthCheckAdmin>
    </>
  );
};

export default UserCourseDetails;
