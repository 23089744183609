import Logo from '../../../Visuals/Images/Logo.png';
import React from 'react';
import {
  FooterDiv,
  LeftFooter,
  RightFooter,
  OuterDiv,
} from './FooterStyles.styles';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <FooterDiv>
      <OuterDiv>
        <LeftFooter>
          <img src={Logo} alt="Yoda Safety Services Logo" />
        </LeftFooter>
        <RightFooter>
          <p>Owned & Operated by Jim Benoit</p>
          <p>Contact us as at yodasafetyservices1@outlook.com</p>
          <span>
            Registered under the Nova Scotia Workers Compensation Board to
            Safety Certified Companies
          </span>
          <div style={{ display: 'flex' }}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </div>
        </RightFooter>
      </OuterDiv>
    </FooterDiv>
  );
};

export default Footer;
