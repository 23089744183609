import TagSelect from './Tags';
import React from 'react';
import PropTypes from 'prop-types';
import TagIcon from '../../../Visuals/Icons/TagIcon.icon';
import DeleteIcon from '../../../Visuals/Icons/DeleteIcon.icon';
import { TagsContainer } from './Tags.styles';
// Displays the current selected tages
const TagsSelectedContainer = ({ tags = [], setTags }) => {
  const validTags = Array.isArray(tags) ? tags : [];
  return (
    <TagsContainer>
      <h4>
        Tags <TagIcon />
      </h4>
      <br />
      <ul>
        {validTags?.map((t, idx) => {
          return (
            <li
              key={idx}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5rem',
              }}
            >
              <p value={t}>
                {idx + 1}. {t}
              </p>
              <DeleteIcon
                functionName={(e) => {
                  e.preventDefault();
                  let filtered = tags.filter((ti) => ti !== t);
                  setTags(filtered);
                }}
              />
            </li>
          );
        })}
      </ul>
      <TagSelect tags={tags} setTags={setTags} />
    </TagsContainer>
  );
};
TagsSelectedContainer.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
};

export default TagsSelectedContainer;
