import {
  CourseHomepageContainer,
  BreadcrumbsContainer,
  CourseHomeRight,
  LinksContainer,
  SubsectionContainer,
  CouseHomeLeft,
  // PresentationContainer,
  EachSection,
  TakeQuizButton,
} from './CourseHome.styles';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import { useParams, useNavigate } from 'react-router-dom';
import React from 'react';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { useQuery } from '@tanstack/react-query';
import PrimaryButton from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import NotFound from '../Routes/MainComponents/not-found/NotFoundPage.component';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import PresentationIcon from '../Visuals/Icons/PresentationIcon.icon';
import LinkIcon from '../Visuals/Icons/LinkIcon.icon';
import PlayIcon from '../Visuals/Icons/PlayIcon.icon';
import Loader from '../Components/Loader/LoaderComponent.component';
import ArrowIcon from '../Visuals/Icons/ChevronRightIcon.icon';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import PDFViewer from './PdfViewer.component';

// Taking the course the homepage that displays the presentation, documentation and video links
const CourseHomepage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const [iframeError, setIframeError] = useState(false);
  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h3>An error has occured</h3>;
  }
  // console.log('Course data', data);

  return (
    <AuthCheckUser>
      <HelmetProvider>
        <Helmet>
          <title>
            {data?.courseName ? `${data?.courseName} Home ` : 'Course Home '}|
            Yoda Safety Services
          </title>
          <meta name="Course Homepage" content="Homepage for taking a course" />
        </Helmet>
      </HelmetProvider>
      <CourseHomepageContainer>
        <CourseHomeRight>
          <LinksContainer>
            <BreadcrumbsContainer>
              <BreadCrumbs one="Home" oneLink="/user" two={data?.courseName} />
            </BreadcrumbsContainer>
            <a href="#presentation">
              <PresentationIcon /> Presentation
            </a>
            <a href="#documentation">
              <LinkIcon />
              Documentation
            </a>
            <a href="#videos">
              <PlayIcon />
              Video Links
            </a>
            <span className="courseStatus">Course is currently incomplete</span>
          </LinksContainer>
          <PrimaryButton
            functionName={(e) => {
              e.preventDefault();
              navigate(`/course/quiz/${id}`);
            }}
            span="Take Quiz"
          />
        </CourseHomeRight>
        <CouseHomeLeft>
          {!data.courseName ? (
            <>
              <NotFound />
            </>
          ) : (
            <>
              <h2>{data?.courseName}</h2>
              <p>{data?.description}</p>
              <EachSection>
                <SubsectionContainer id="presentation">
                  <PresentationIcon />
                  <h3>Presentation</h3>
                </SubsectionContainer>
                {/* <span>
                  The presentation is currently only available to view on a
                  desktop
                </span> */}
                {/* <PDFViewer pdfUrl={data?.pdf} /> */}
                {/* #toolbar=0 */}
                {/* <PresentationContainer>
                  {iframeError ? (
                    <p>Presentation not found</p>
                  ) : (
                    <iframe
                      src={`${data?.pdf}#toolbar=0&view=FitH`}
                      title="presentation"
                      width="100%"
                      height="100%"
                      onError={() => setIframeError(true)}
                    />
                  )}
                </PresentationContainer> */}
                {/* <PDFViewer
                  setIframeError={setIframeError}
                  iframeError={iframeError}
                  data={data}
                /> */}
                {/* THis one does work - like sort of */}
                <PDFViewer pdfUrl={data?.pdf} />
              </EachSection>
              <EachSection>
                <SubsectionContainer id="documentation">
                  <LinkIcon />
                  <h3>Documentation</h3>
                </SubsectionContainer>
                {data?.attachments?.map((a) => {
                  return (
                    <a
                      key={a}
                      href={a}
                      target="_blank"
                      rel="noreferrer"
                      alt={'Documentation link to ' + a}
                    >
                      <ArrowIcon />
                      {a.slice(52)}
                    </a>
                  );
                })}
              </EachSection>
              <EachSection>
                <SubsectionContainer id="videos">
                  <PlayIcon />
                  <h3>Videos</h3>
                </SubsectionContainer>
                {data?.videoLinks?.map((l, i) => {
                  return (
                    <a
                      key={i}
                      href={l}
                      onChange={(e) => e.target}
                      target="_blank"
                      rel="noreferrer"
                      alt={'Video link to ' + l}
                    >
                      <ArrowIcon />
                      {l}
                    </a>
                  );
                })}
              </EachSection>
              <TakeQuizButton>
                <PrimaryButton
                  functionName={(e) => {
                    e.preventDefault();
                    navigate(`/course/quiz/${id}`);
                  }}
                  span="Take Quiz"
                />
              </TakeQuizButton>
            </>
          )}
        </CouseHomeLeft>
      </CourseHomepageContainer>
    </AuthCheckUser>
  );
};
export default CourseHomepage;
