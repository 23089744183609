import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CompanyAdmin from '../../CreateRoutes/CreateCompany/CompanyAdmin/CompanyAdmin.component';
import { FormContainer } from '../../CreateRoutes/CreateForm.styles';
import DisableUserButton from '../EditUser/UserEditDisable.component';
import ActivateUserButton from '../EditUser/UserEditActivate.component';
import DeleteUserButton from '../EditUser/UserEditDelete.component';
import EditUserSubmit from '../EditUser/UserEditSubmit.component';
import Modal from '../../../Components/Modal/ModalComponent.component';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { useNavigate } from 'react-router-dom';
import useUser from '../../../Utils/Users/singleUser/useUser';
import { HelmetProvider, Helmet } from 'react-helmet-async';
// import AuthCheckManager from '../../../Utils/AuthCheck/ManagerCheck';

// prettier-ignore
const EditManagerOrEmployee = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { userId } = useParams();

  const { user, successState, setUser, setSuccessState } = useUser(userId);

  return (
    // <AuthCheckManager>
    <FormContainer>
      <HelmetProvider>
        <Helmet>
          <title>Edit Manager or Employee | Yoda Safety Services</title>
          <meta
            name="Edit Manager Or Employees "
            content="Edit a companies manager or employees"
          />
        </Helmet>
      </HelmetProvider>
      <CompanyAdmin
        title={`Edit ${user?.firstName} ${user?.lastName}`}
        user={user}
        setUser={setUser}
        setType={setType}
        type={type}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DeleteUserButton
          id={userId}
          setSuccessState={setSuccessState}
          setIsOpen={setIsOpen}
        />
        {user?.active ? (
          <DisableUserButton
            id={userId}
            setSuccessState={setSuccessState}
            setIsOpen={setIsOpen}
          />
        ) : (
          <ActivateUserButton
            id={userId}
            setSuccessState={setSuccessState}
            setIsOpen={setIsOpen}
          />
        )}
        <EditUserSubmit
          user={user}
          type={type}
          setSuccessState={setSuccessState}
          setIsOpen={setIsOpen}
        />
      </div>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          {successState && <p>{successState}</p>}
          <PrimaryButton
            functionName={() => navigate('/admin')}
            span="Go Home"
          />
        </Modal>
      )}
    </FormContainer>
    // {/* </AuthCheckManager> */}
  );
};

export default EditManagerOrEmployee;
