import React from 'react';
import { SlidingMenuContainer, CloseButton } from './TrainingStyles.styles';
import RadioTag from './RadioTag';
import PropTypes from 'prop-types';
import CloseIcon from '../../Visuals/Icons/CloseIcon.icon';

const SlidingTagMenu = ({
  isOpen,
  onClose,
  tagChange,
  selectedTag,
  setSelectedTag,
}) => {
  return (
    <>
      <SlidingMenuContainer isOpen={isOpen}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <h3>Select a Tag</h3>
        <RadioTag
          tagChange={tagChange}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      </SlidingMenuContainer>
    </>
  );
};
SlidingTagMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tagChange: PropTypes.func,
  selectedTag: PropTypes.string,
  setSelectedTag: PropTypes.func,
};

export default SlidingTagMenu;
