import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { httpLoginUser } from '../Hooks/user/user.post.hooks';
import AuthImage from '../Visuals/Images/Authentication.jpg';
import Auth from '../Helpers/auth';
import {
  AuthContainer,
  PictureSide,
  AuthenticationSide,
  AuthContainer2,
} from './AuthStyles.styles';
import PrimaryButton from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import InputContainer from '../Components/Form/Input';
import PasswordInput from '../Components/Form/PasswordInput';
import LoginOptions from './LoginOptions.component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// prettier-ignore
// Form for login
const LoginComponent = ({ setPage }) => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    if (error.length) {
      setError('');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formState.email.length < 6 || !formState.email.includes('@')) {
      setError('Not a valid email');
      return;
    }
    if (formState.password.length < 6) {
      setError('Password is not valid');
      return;
    }
    setIsSubmitting(true);
    const response = await httpLoginUser(formState);
    const data = await response.json();

    if (response.ok) {
      Auth.login(data.token);
      setFormState({
        email: '',
        password: '',
      });
    } else {
      setError(data.msg);
      return;
    }
  };
  useEffect(() => {
    if(isSubmitting && Auth.getToken() !== null) {
      // console.log('Checked token lengt', Auth.getToken());
      navigate('/user');
    }
  })
  return (
    <AuthContainer>
      <HelmetProvider>
        <Helmet>
          <title>Login | Yoda Safety Services</title>
          <meta name="Login" content="Login to your account" />
        </Helmet>
      </HelmetProvider>
      <AuthContainer2>
        <PictureSide>
          <img src={AuthImage} loading="eager" alt="Yellow Helmets" />
        </PictureSide>
        <AuthenticationSide>
          <form onSubmit={handleFormSubmit}>
            <h2>Login</h2>
            {/* Email */}
            <InputContainer
              placeholder="yoda@safety.com"
              label="Email"
              value={formState.email}
              name="email"
              onChange={handleChange}
            />
            {/* Password */}
            <PasswordInput
              label="Password"
              span="Must be at least 6 characters"
              handleChange={(e) => {
                e.preventDefault();
                setShowPass((prev) => !prev);
              }}
              showPass={showPass}
              value={formState.password}
              name="password"
              onChange={handleChange}
            />
            {error && <p className="error">{error}</p>}
            <PrimaryButton span="Login" />
          </form>
            <LoginOptions setPage={setPage} />
        </AuthenticationSide>
      </AuthContainer2>
    </AuthContainer>
  );
};
LoginComponent.propTypes = {
  setPage: PropTypes.func,
};

export default LoginComponent;
