import { QuestionContainer } from './Quiz.styles';
import DeleteQuestion from './DeleteQuestion.component';
import EditButton from './EditButtonQuestion.component';
import React from 'react';
import PropTypes from 'prop-types';

// Displays each question and its answers
const DisplayQuestion = ({
  questions,
  setQuestions,
  setEditQuestion,
  setCreateOrEdit,
}) => {
  return (
    <aside>
      {questions?.map((quest, index) => {
        return (
          <QuestionContainer key={quest?.id}>
            <p>
              Question {index + 1}: <span> {quest?.question} </span>
            </p>
            {quest?.answer1 && (
              <p>
                Answer 1: <span>{quest?.answer1}</span>{' '}
              </p>
            )}
            {quest?.answer2 && (
              <p>
                Answer 2: <span>{quest?.answer2}</span>
              </p>
            )}
            {quest?.answer3 && (
              <p>
                Answer 3: <span>{quest?.answer3}</span>
              </p>
            )}
            {quest?.answer4 && (
              <p>
                Answer 4: <span>{quest?.answer4}</span>
              </p>
            )}
            {quest?.answer5 && (
              <p>
                Answer 5: <span>{quest?.answer5}</span>
              </p>
            )}
            {quest?.answer6 && (
              <p>
                Answer 6: <span>{quest?.answer6}</span>
              </p>
            )}
            {quest?.answer7 && (
              <p>
                Answer 7: <span>{quest?.answer7}</span>
              </p>
            )}
            {quest?.answer8 && (
              <p>
                Answer 8: <span>{quest?.answer8}</span>
              </p>
            )}

            <p>
              Correct Answer: <span>{quest?.correctAnswer}</span>
            </p>
            <div style={{ display: 'flex', gap: '2rem' }}>
              <DeleteQuestion
                questions={questions}
                setQuestions={setQuestions}
                quest={quest}
              />
              <EditButton
                quest={quest}
                setCreateOrEdit={setCreateOrEdit}
                setEditQuestion={setEditQuestion}
              />
            </div>
          </QuestionContainer>
        );
      })}
    </aside>
  );
};
DisplayQuestion.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      question: PropTypes.string,
      answer1: PropTypes.string,
      answer2: PropTypes.string,
      answer3: PropTypes.string,
      answer4: PropTypes.string,
      answer5: PropTypes.string,
      answer6: PropTypes.string,
      answer7: PropTypes.string,
      answer8: PropTypes.string,
      correctAnswer: PropTypes.string,
    })
  ),
  setQuestions: PropTypes.func,
  setEditQuestion: PropTypes.func,
  setCreateOrEdit: PropTypes.func,
};

export default DisplayQuestion;
