import { useState, useEffect } from 'react';
import { fetchUserAdmin } from '../singleUser/getUserAdmin';

const useUserAdmin = (id) => {
  const [user, setUser] = useState();
  const [successState, setSuccessState] = useState('');

  useEffect(() => {
    fetchUserAdmin(id, setUser, setSuccessState);
  }, [id]);

  return { user, successState, setUser, setSuccessState };
};

export default useUserAdmin;
