import React, { useState } from 'react';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import InputText from '../Components/Form/InputText';
import { httpSignupNewsletter } from '../Hooks/newsletter/newsletter.post.hooks';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await httpSignupNewsletter(email);
      const data = await res.json();
      if (res.ok) {
        setStatus(data.msg || 'You have been signed up for our newsletter!');
      } else {
        setStatus(data.msg || 'Failed to sign up for the newsletter');
      }
    } catch (err) {
      setStatus('An error occured. Please try again');
    }
  };
  return (
    <div
      style={{
        padding: '2rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        marginTop: '1rem',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Sign Up for our Newsletter</h2>
      <div className="center" style={{ textAlign: 'center' }}>
        <p
          style={{
            fontStyle: 'italic',
            color: 'var(--grey1)',
            paddingBottom: '0.5rem',
          }}
        >
          Sign up for monthly emails about industry updates
        </p>
        <span
          style={{
            fontStyle: 'italic',
            color: 'var(--grey2)',
          }}
        >
          Unsubscribe at any time
        </span>
        <p>{status}</p>
        <form
          style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '1rem',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InputText
            width={80}
            title="Email"
            placeholder="Yoda@safety.com"
            value={email}
            setValue={setEmail}
            name="email"
            requiredInput={true}
          />
          <PrimaryButtonComponent span="Sign up" functionName={handleSubmit} />
        </form>
      </div>
    </div>
  );
};

export default NewsletterSignup;
