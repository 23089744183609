import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { MainContentContainer, MainSection } from '../OtherPages.styles';
import SideContainerComponent from '../SideContainer.component';

const WCBCertified = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>WCB Certified | Yoda Safety Services</title>
          <meta
            name="WCB Certified"
            content="Yoda Safety Services is WCB Certified"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Approved By NS WCB Safety Certified</h2>
        <p>Under development</p>
      </MainSection>
    </MainContentContainer>
  );
};

export default WCBCertified;
