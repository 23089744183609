import Auth from '../../Helpers/auth';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { httpGetCertificateInfo } from '../../Hooks/user/user.get.hooks';

// Page from when the user has not completed the course
const UserIncompleteComponent = ({ id = 0 }) => {
  const { data } = Auth.getProfile();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    const FindInfo = async () => {
      let found = await httpGetCertificateInfo(data?.id, id);
      setUserInfo(found);
    };
    FindInfo();
  }, [data.id, id]);
  console.log('Incomplete found info', userInfo);
  return (
    <>
      <h1>
        Sorry, You have not completed this course
        <br />
        Your score: {userInfo?.courses[0]?.score}
      </h1>
    </>
  );
};
UserIncompleteComponent.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
};

export default UserIncompleteComponent;
