import React from 'react';
import {
  FormContainer,
  MappedResults,
  CreateFormContainer,
} from '../../CreateRoutes/CreateForm.styles';
import { useParams } from 'react-router-dom';
import CreateManager from './CreateManager.component';
import MappedUsersOrEmployee from './MappedManagersUsers.component';
import useManagers from '../../../Utils/Companies/Managers-Employees/allManagers/useManagers';
import useEmployees from '../../../Utils/Companies/Managers-Employees/allEmployees/useEmployees';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const EditManagersAndUsers = () => {
  const { id } = useParams();
  // successState, setManagers, setSuccessState - from useManagers
  const { managers } = useManagers(id);
  // setEmployees, success, setSuccess - from useEmployees
  const { employees } = useEmployees(id);

  return (
    <AuthCheckAdmin>
      <HelmetProvider>
        <Helmet>
          <title>Edit Managers & Users | Yoda Safety Services</title>
          <meta
            name="Edit Managers and Users"
            content="Edit the companies managers and users"
          />
        </Helmet>
      </HelmetProvider>
      <FormContainer>
        <h2>Edit Managers and Employees</h2>
        <CreateFormContainer>
          <CreateManager />
        </CreateFormContainer>
        <MappedResults>
          {managers && (
            <MappedUsersOrEmployee users={managers} title="Company Managers" />
          )}
        </MappedResults>
        <MappedResults>
          {employees && (
            <MappedUsersOrEmployee
              users={employees}
              title="Company Employees"
            />
          )}
        </MappedResults>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default EditManagersAndUsers;
