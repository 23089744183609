import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import React from 'react';
import PropTypes from 'prop-types';

// Button to change to edit the question
const EditButton = ({ quest, setEditQuestion, setCreateOrEdit }) => {
  const handleEdit = (e) => {
    e.preventDefault();
    setCreateOrEdit(false);
    setEditQuestion(quest);
  };

  return <SecondaryButton functionName={handleEdit} span="Edit Question" />;
};
EditButton.propTypes = {
  quest: PropTypes.object,
  setEditQuestion: PropTypes.func,
  setCreateOrEdit: PropTypes.func,
};

export default EditButton;
