import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import { ArrayContainer } from './FormElements.styles';
import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '../../Visuals/Icons/DeleteIcon.icon';
// Any Input that comes into an array
const ArrayInput = ({
  label,
  single,
  arrayItems = [],
  setSingle,
  setArray,
  icon,
}) => {
  return (
    <ArrayContainer>
      <h4>
        {label}
        {icon}
      </h4>
      <div className="mappedElements">
        {arrayItems?.map((i, k) => {
          return (
            <div
              key={k}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1em',
              }}
            >
              <p> - {i}</p>
              <DeleteIcon
                functionName={(e) => {
                  e.preventDefault();
                  const filtered = arrayItems?.filter((item) => {
                    return item !== i;
                  });
                  setArray(filtered);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="inputDiv">
        <input value={single} onChange={(e) => setSingle(e.target.value)} />
        <SecondaryButton
          functionName={(e) => {
            e.preventDefault();
            setArray([...arrayItems, single]);
            setSingle('');
          }}
          span={'Add ' + label}
        />
      </div>
    </ArrayContainer>
  );
};
ArrayInput.propTypes = {
  label: PropTypes.string,
  single: PropTypes.string,
  arrayItems: PropTypes.array,
  setSingle: PropTypes.func,
  setArray: PropTypes.func,
  icon: PropTypes.object,
};

export default ArrayInput;
