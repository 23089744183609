import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// GET: Gets the users profile - protected route
async function httpGetUser(userId) {
  const token = Auth.getToken();
  try {
    let user = await fetch(`${API_URL}/users/${userId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets the users certificate information - protected route
async function httpGetCertificateInfo(id, course) {
  const token = Auth.getToken();
  try {
    let user = await fetch(`${API_URL}/users/cert/${id}/${course}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

export { httpGetCertificateInfo, httpGetUser };
