import { useState, useEffect } from 'react';
import { fetchEmployees } from '../allEmployees/getEmployees';

const useEmployees = (id) => {
  const [employees, setEmployees] = useState([]);
  const [successState, setSuccessState] = useState('');

  useEffect(() => {
    fetchEmployees(id, setEmployees, setSuccessState);
  }, [id]);

  return { employees, successState, setEmployees, setSuccessState };
};

export default useEmployees;
