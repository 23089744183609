import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

//GET: Gets all companies for admin page - Protected route
async function httpGetAllCompanies() {
  const token = Auth.getToken();

  try {
    let companies = await fetch(`${API_URL}/admin/companies`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return companies.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets all the users for admin page - protected route
async function httpGetAllUsers() {
  const token = Auth.getToken();

  try {
    let users = await fetch(`${API_URL}/admin/users`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return users.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets a single user - protected route
async function httpGetSingleUser(id) {
  const token = Auth.getToken();

  try {
    let user = await fetch(`${API_URL}/admin/user/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets all the images for the course- protected route
async function httpGetAllImages() {
  const token = Auth.getToken();

  try {
    let images = await fetch(`${API_URL}/admin/images`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return images.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets a single user with course - protected route
async function httpGetCourseDetailsForUser(id, courseId) {
  const token = Auth.getToken();
  try {
    let user = await fetch(`${API_URL}/admin/user/${id}/course/${courseId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets a single user with course - protected route
async function httpGetSubscribers() {
  const token = Auth.getToken();
  try {
    let user = await fetch(`${API_URL}/admin/newsletter-subscribers`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

export {
  httpGetAllCompanies,
  httpGetSingleUser,
  httpGetAllUsers,
  httpGetAllImages,
  httpGetCourseDetailsForUser,
  httpGetSubscribers,
};
