import styled from 'styled-components';

export const ImageContainer = styled.main`
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  padding-top: 2rem;
  h4 {
    display: none;
  }
  input {
    width: 50%;
  }
`;
