import { httpEditUser } from '../../../Hooks/admin/admin.put.hooks';

export const editUser = async (
  user,
  setSuccessState,
  setIsOpen,
  setLoading
) => {
  setLoading(true);
  try {
    let res = await httpEditUser(user._id, user);
    let data = await res.json();
    if (res.ok) {
      setSuccessState('User has been edited');
    } else {
      setSuccessState(data.msg);
    }
  } catch (err) {
    setSuccessState('An error occurred while editing the company');
    console.error('Error editing company:', err);
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
