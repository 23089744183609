import { useState, useEffect } from 'react';
import { fetchUser } from '../singleUser/getUser';

const useUser = (id) => {
  const [user, setUser] = useState([]);
  const [successState, setSuccessState] = useState('');

  useEffect(() => {
    fetchUser(id, setUser, setSuccessState);
  }, [id]);

  return { user, successState, setUser, setSuccessState };
};

export default useUser;
