import styled, { keyframes } from 'styled-components';

export const QuizCompleteContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xl};
  margin-block: ${({ theme }) => theme.spacing.xxl};
  z-index: 2;
  position: absolute;
  top: 38%;
  left: 10%;
  button {
    width: 250px;
    height: 50px;
  }
  button:last-child {
    width: auto;
  }
  @media screen and (width <= 600px) {
    margin-block: ${({ theme }) => theme.spacing.m};
    flex-direction: column-reverse;
    //border: 1px solid green;
    gap: ${({ theme }) => theme.spacing.m};
    button {
      min-width: 100%;
      max-height: 40px;
    }
  }
`;
const fadeInScaleUp = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const ResultsContainer = styled.section`
  /* border: 1px solid red; */
  width: 100%;
  max-height: 30vw;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  h1 {
    color: ${({ theme }) => theme.colors.grey};
    margin-top: ${({ theme }) => theme.spacing.xs};
    letter-spacing: ${({ theme }) => theme.spacing.xxs};
    z-index: 2;
    position: absolute;
    top: 15%;
    left: 10%;
  }
  img {
    min-height: 100%;
    min-width: 100%;
    z-index: -2;
    /* border: 2px solid green; */
    background-color: ${({ theme }) => theme.colors.grey6};
  }
  .courseName {
    color: ${({ theme }) => theme.colors.orange6};
    animation: ${fadeInScaleUp} 1s ease-in-out;
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
  @media screen and (width <= 768px) {
    /* border: 2px solid blue; */
    max-height: 80vw;
    h1 {
      margin-top: ${({ theme }) => theme.spacing.xs};
      font-weight: 500;
      top: 6%;
      left: 10%;
    }
  }
`;

export const EachAnswer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.xxl};
  width: 80%;
  box-shadow: ${({ theme }) => theme.shadow3};
  @media screen and (width <= 768px) {
    margin-left: ${({ theme }) => theme.spacing.m};
    width: 90%;
  }
`;

export const ReviewQuizContainer = styled.section`
  width: 95%;
  /* border: 2px solid orange; */
  scroll-behavior: smooth;
  padding: ${({ theme }) => theme.spacing.m};
  scroll-snap-type: y mandatory;
  margin-top: ${({ theme }) => theme.spacing.l};
  .topDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 5px solid ${({ theme }) => theme.colors.yellow4};
  }
  h2::after {
    width: 0;
  }
  button {
    text-align: right;
  }
  section {
    /* border: 1px solid orange; */
    min-height: 100vh;
  }
`;
