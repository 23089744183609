import { MainContentContainer, MainSection } from '../OtherPages.styles';
import SideContainerComponent from '../SideContainer.component';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Insurance = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Insurance | Yoda Safety Services</title>
          <meta
            name="Insurance"
            content="Insurance from yoda safety services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Insurance</h2>
        <p>
          {' '}
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea
          ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci
          repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente
          eveniet aut expedita praesentium dolor.
        </p>
      </MainSection>
    </MainContentContainer>
  );
};

export default Insurance;
