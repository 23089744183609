import React from 'react';
import PropTypes from 'prop-types';
import { InforCardContainer } from './InfoCard.styles';
import InformationIcon from '../../Visuals/Icons/InformationIcon.icon';

const InfoCard = ({ paragraph, span }) => {
  return (
    <InforCardContainer>
      <InformationIcon />
      <div>
        <p>{paragraph}</p>
        <br />
        <span className="italicSpan">{span}</span>
      </div>
    </InforCardContainer>
  );
};
InfoCard.propTypes = {
  paragraph: PropTypes.string,
  span: PropTypes.string,
};

export default InfoCard;
