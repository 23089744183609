import React from 'react';
import PrimaryButtonComponent from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import PropTypes from 'prop-types';
import { ButtonContainers } from './ComInc.styles';

const CompleteIncompleteButton = ({
  primarySpan,
  primaryFunction,
  secondarySpan,
  secondaryFunction,
}) => {
  return (
    <ButtonContainers>
      <SecondaryButton span={secondarySpan} functionName={secondaryFunction} />
      <PrimaryButtonComponent
        span={primarySpan}
        functionName={primaryFunction}
      />
    </ButtonContainers>
  );
};
CompleteIncompleteButton.propTypes = {
  primarySpan: PropTypes.string,
  primaryFunction: PropTypes.func,
  secondarySpan: PropTypes.string,
  secondaryFunction: PropTypes.func,
};

export default CompleteIncompleteButton;
