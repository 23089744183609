import React, { useState, useEffect } from 'react';
import { httpGetAllImages } from '../../../Hooks/admin/admin.get.hooks';

const DisplayAllImages = () => {
  const [images, setImages] = useState([]);
  const [status, setStatus] = useState('');
  useEffect(() => {
    const FetchImages = async () => {
      try {
        setStatus('Fetching Images');
        let res = await httpGetAllImages();
        if (res) {
          setImages(res);
          setStatus('');
        } else {
          setStatus('Images have not been fetched');
        }
      } catch (err) {
        setStatus('Error occured', err);
      }
    };
    FetchImages();
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        // border: '1px solid red',
        padding: '1rem',
        // flexDirection: 'column',
        gap: '1rem',
        height: '60vh',
        overflowY: 'scroll',
      }}
    >
      <p>{status}</p>
      {images?.map((image) => (
        <div key={image?._id}>
          <img
            src={image?.url}
            alt={image?.name}
            height="100px"
            width="200px"
          />
        </div>
      ))}
    </div>
  );
};

export default DisplayAllImages;
