import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormContainer } from '../../CreateRoutes/CreateForm.styles';
import Modal from '../../../Components/Modal/ModalComponent.component';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import FreeCourse from './FreeCourse.component';
import InputsForEditUser from './FormEditUser/InputsEditUser.component';
import ButtonEditUser from './FormEditUser/ButtonsEditUser.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import useUserAdmin from '../../../Utils/Users/singleUser/userUserAdmin';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadcrumbsComponent.component';

// Form for editing a user
const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState('');
  const { user, successState, setSuccessState } = useUserAdmin(id);
  const [userState, setUserState] = useState(user || {});
  useEffect(() => {
    if (user && !Array.isArray(user)) {
      setUserState(user);
    }
  }, [user]);
  // console.log('USER STATE', userState);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserState((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  return (
    <AuthCheckAdmin>
      <HelmetProvider>
        <Helmet>
          <title>Edit User | Yoda Safety Services</title>
          <meta name="Edit User" content="Form for updating a user" />
        </Helmet>
      </HelmetProvider>
      <FormContainer>
        <div style={{ marginBottom: '1.5rem' }}>
          <BreadCrumbs one="Admin Home" oneLink="/admin" two={'Edit User'} />
        </div>
        <h2>EDIT USER</h2>
        <form>
          {/* Inputs  */}
          <InputsForEditUser
            user={userState}
            setUser={setUserState}
            password={password}
            setPassword={setPassword}
          />
          {/* Free Course */}
          <FreeCourse
            id={userState?._id}
            userName={userState?.firstName}
            email={userState?.email}
            userCourses={userState?.courses}
          />
          {/* Pop Up Modal */}
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <p>{successState}</p>
              <PrimaryButton
                functionName={() => navigate('/admin')}
                span="Go Home"
              />
            </Modal>
          )}
          {/* Submit buttons */}
          <ButtonEditUser
            user={userState}
            password={password}
            setIsOpen={setIsOpen}
            setSuccessState={setSuccessState}
          />
        </form>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default EditUser;
