import PropTypes from 'prop-types';
import React from 'react';
import { tags } from './Tags';
import {
  FilterTagsContainer,
  FilterTagsOuterContainer,
} from './TrainingStyles.styles';

const RadioTag = ({ tagChange, setSelectedTag }) => {
  const onChange = async (e) => {
    await setSelectedTag(e.target.value);
    await tagChange(e.target.value);
  };

  return (
    <FilterTagsOuterContainer>
      {tags.map((tag) => (
        <FilterTagsContainer key={tag.id}>
          <input
            defaultChecked={tag.id === 'all'}
            type="radio"
            id={tag.id}
            name="tag"
            value={tag.label}
            onClick={onChange}
          />
          <label htmlFor={tag.id}>{tag.label}</label>
        </FilterTagsContainer>
      ))}
    </FilterTagsOuterContainer>
  );
};
RadioTag.propTypes = {
  tagChange: PropTypes.func,
  setSelectedTag: PropTypes.func,
};

export default RadioTag;
