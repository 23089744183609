import { v4 as uuidv4 } from 'uuid';
export const ClearInput = (setQuest, setQuest2) => {
  setQuest({
    id: uuidv4(),
    question: '',
    answer1: '',
    answer2: '',
    answer3: '',
    answer4: '',
    answer5: '',
    answer6: '',
    answer7: '',
    answer8: '',
    correctAnswer: '',
  });
  setQuest2({
    question: '',
    answer1: 'true',
    answer2: 'false',
    correctAnswer: 'true',
    id: uuidv4(),
  });
};
