// import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// POST: Login the user - Not a protected route
async function httpLoginUser(userInfo) {
  try {
    return await fetch(`${API_URL}/users/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
  } catch (err) {
    return err;
  }
}

//POST: Sign Up User - Not a protected route
async function httpSignUpUser(userInfo) {
  try {
    return await fetch(`${API_URL}/users/signup`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
  } catch (err) {
    return err;
  }
}

//POST: Forgot Password - Not a protected route
async function httpForgotPassword(email) {
  try {
    return await fetch(`${API_URL}/users/forgot-password`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  } catch (err) {
    return err;
  }
}

//POST: Reset Password - Not a protected route
async function httpResetPassword(token, password) {
  try {
    return await fetch(`${API_URL}/users/reset-password`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, password }),
    });
  } catch (err) {
    return err;
  }
}

export { httpForgotPassword, httpLoginUser, httpResetPassword, httpSignUpUser };
