import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// POST: Creates a Manager or User for a company - protected route
async function httpCreateManagerOrUser(user) {
  const token = Auth.getToken();

  return await fetch(`${API_URL}/companies/create-manager-or-user`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify(user),
  });
}

export { httpCreateManagerOrUser };
