import React, { useState } from 'react';
import { FormContainer } from '../CreateForm.styles';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import InputText from '../../../Components/Form/InputText';
import TextareaDiv from '../../../Components/Form/Textarea';
import NewsletterSubmit from './NewsletterSubmit.component';

const CreateNewsletter = () => {
  const [mainTitle, setMainTitle] = useState('');
  const [month, setMonth] = useState('');
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');
  const [content1, setContent1] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');

  return (
    <>
      <AuthCheckAdmin>
        <HelmetProvider>
          <Helmet>
            <title>Create Newsletter | Yoda Safety Services</title>
            <meta name="Create Newsletter" content="Create a user" />
          </Helmet>
        </HelmetProvider>
        <FormContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <BreadCrumbs
              one="Admin Home"
              oneLink="/admin"
              two={'Create Newsletter'}
            />
          </div>
          <h2>CREATE NEWSLETTER</h2>
          <form>
            {/* Main Title for the newsletter */}
            <InputText
              title="Title"
              placeholder="March newsletter"
              value={mainTitle}
              setValue={setMainTitle}
              name="mainTitle"
              requiredInput="true"
              containerClassName="formInputUnderline"
            />
            {/* For this month */}
            <InputText
              title="Month"
              placeholder="March"
              value={month}
              setValue={setMonth}
              name="month"
              requiredInput="true"
              containerClassName="formInputUnderline"
            />
            {/* Title 1 */}
            <InputText
              title="Title #1"
              placeholder="Subsection title 1"
              value={title1}
              setValue={setTitle1}
              name="title1"
              containerClassName="formInputUnderline"
            />
            {/* Content for 1 */}
            <TextareaDiv
              title="Content for #1"
              value={content1}
              setValue={setContent1}
              placeholder="Content for section 1"
              containerClassName="formInputUnderline"
            />
            {/* Title 2 */}
            <InputText
              title="Title #2"
              placeholder="Subsection title 2"
              value={title2}
              setValue={setTitle2}
              name="title2"
              containerClassName="formInputUnderline"
            />
            {/* Content for 2 */}
            <TextareaDiv
              title="Content for #2"
              value={content2}
              setValue={setContent2}
              placeholder="Content for section 2"
              containerClassName="formInputUnderline"
            />
            {/* Title 3 */}
            <InputText
              title="Title #3"
              placeholder="Subsection title 3"
              value={title3}
              setValue={setTitle3}
              name="title3"
              containerClassName="formInputUnderline"
            />
            {/* Content for 3 */}
            <TextareaDiv
              title="Content for #3"
              value={content3}
              setValue={setContent3}
              placeholder="Content for section 3"
              containerClassName="formInputUnderline"
            />
            <NewsletterSubmit
              mainTitle={mainTitle}
              month={month}
              title1={title1}
              title2={title2}
              title3={title3}
              content1={content1}
              content2={content2}
              content3={content3}
            />
          </form>
        </FormContainer>
      </AuthCheckAdmin>
    </>
  );
};

export default CreateNewsletter;
