import styled from 'styled-components';

export const NotFoundContainer = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: 1px solid green; */
  width: 100%;
  height: 100vh;
  gap: 2rem;
  img {
    height: 600px;
    width: 500px;
  }
  button {
    margin-top: 1rem;
  }
  @media screen and (width <= 600px) {
    flex-direction: column;
    justify-content: start;
    img {
      height: 300px;
      width: 250px;
    }
  }
`;
