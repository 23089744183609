import { httpGetAllNewsletters } from '../../Hooks/newsletter/newsletter.get.hooks';

export const fetchNewsletters = async (setNewsletters, setSuccess) => {
  try {
    const found = await httpGetAllNewsletters();
    if (found) {
      setNewsletters(found);
    } else {
      setSuccess('No newsletters found');
    }
  } catch (error) {
    setSuccess('An error occurred while fetching the newsletters');
    console.error('Error fetching newsletterss:', error);
  }
};
