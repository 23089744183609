import styled from 'styled-components';

// prettier-ignore
export const OnlineCoursesContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  padding: 1rem;
  /* border: 3px solid green; */
  /* padding-inline: ${({ theme }) => theme.spacing.xxl}; */
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (width <= 768px) {
    min-width: 100%;
    padding-inline: ${({ theme }) => theme.spacing.xs};
  }

`;

export const SearchContainer = styled.aside`
  width: 100%;
  margin-block: ${({ theme }) => theme.spacing.m};
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid blue; */
  input {
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 250px;
    height: 45px;
    padding: ${({ theme }) => theme.spacing.xs};
  }
  @media screen and (width <= 768px) {
    /* border: 1px solid orange; */
    flex-direction: column;
    align-items: start;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

export const SearchBarContainer = styled.div`
  @media screen and (width <= 768px) {
    width: 100%;
    display: flex;
    input {
      width: 80%;
      height: 40px;
    }
    button {
      height: 40px;
    }
  }
`;
export const FilterButton = styled.aside`
  /* border: 1px solid red; */
  width: 100%;
  margin-bottom: 1rem;
  button {
    padding-left: 0;
  }
`;

export const CourseContainer = styled.section`
  /* border: 5px solid orange; */
  min-height: 100vh;
  height: auto;
  @media screen and (width <= 768px) {
    width: 100%;
  }
`;

export const CourseCardComponent = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.offWhite};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  margin-inline: ${({ theme }) => theme.spacing.m};
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  /* border: 1px solid yellow; */
  width: 90%;
  @media screen and (width <= 768px) {
    /* border: 1px solid orange; */
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;

export const CourseImage = styled.div`
  margin-right: ${({ theme }) => theme.spacing.m};
  max-width: 30%;
  /* border: 1px solid yellow; */
  img {
    max-width: 100%;
    min-height: 100%;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
  }
  @media screen and (width <= 900px) {
    margin-right: 0;
  }
  @media screen and (width <= 600px) {
    width: 0;
    img {
      display: none;
    }
  }
`;
export const CourseInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-wrap: normal;
  padding-top: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.xs};
  /* border: 1px solid red; */
  border-radius: ${({ theme }) => theme.borderRadius};
  .topContainer {
    height: 70%;
  }
  .buttonContainer {
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }
  .buttonContainer button {
    padding-right: ${({ theme }) => theme.spacing.m};
    margin: 0;
    text-align: right;
  }
  h4 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey6};
    text-transform: uppercase;
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
  }
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey4};
    padding-right: ${({ theme }) => theme.spacing.xxs};
  }
  @media screen and (width <= 600px) {
    gap: ${({ theme }) => theme.spacing.xxs};
    padding-top: 0;
    padding: ${({ theme }) => theme.spacing.s};
    border-left: 10px solid ${({ theme }) => theme.colors.orange3};
  }
`;

export const SlidingMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 50%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey6};
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  padding: ${({ theme }) => theme.spacing.m};
  overflow-y: scroll;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;
export const FilterTagsContainer = styled.div`
  /* border: 1px solid orange; */
  label {
    color: ${({ theme }) => theme.colors.offWhite};
  }
`;
export const FilterTagsOuterContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
