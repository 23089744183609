import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import { useQuery } from '@tanstack/react-query';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { QuizCompleteContainer, ResultsContainer } from './QuizComplete.styles';
import DisplayUser from '../DisplayUserInfo/DisplayUserComponent.component';
import CompleteIncompleteButton from '../ComIncButtons/ComInc.component';
import ReviewQuiz from '../ReviewQuiz/ReviewQuiz.component';
import FinishedPicture from '../../Visuals/Images/finished.svg';
import { httpGetSingleCourse } from '../../Hooks/courses.hooks';
import { httpGetCertificateInfo } from '../../Hooks/user/user.get.hooks.js';
import { generateCertBlob } from '../Certificate/GenerateBlob.jsx';
import { uploadToS3 } from '../Certificate/UploadCertificate.jsx';
import { httpCertificateDetails } from '../../Hooks/user/user.put.hooks.js';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import { handleDownload } from '../Certificate/GenerateCert.jsx';
import Auth from '../../Helpers/auth';

// prettier-ignore
const CourseComplete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCertificateGenerated, setIsCertificateGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [courseFound, setCourse] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const { data } = Auth.getProfile();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch user certificate info
  useEffect(() => {
    const FetchCertificateInfo = async () => {
      try {
        const singleCourse = await httpGetSingleCourse(id);
        setCourse(singleCourse);
        setCoursesLoading(false);
        const found = await httpGetCertificateInfo(data.id, singleCourse?._id);
        setUserInfo(found);
      } catch (err) {
        console.error('Error fetching certificate info:', err);
      }
    };
    FetchCertificateInfo();
  }, [id]);
  // Generate and upload certificate
  useEffect(() => {
    const generateAndUploadCertificate = async () => {
      if (
        userInfo &&
        !isCertificateGenerated &&
        userInfo.courses?.[0]?.courseName &&
        userInfo.courses?.[0]?.certNum
      ) {
        const { courseName, date, expiryDate, certNum, courseId } =
          userInfo.courses[0];
        const name = `${data?.firstName} ${data?.lastName}`;

        setIsLoading(true);
        try {
          const pdfBlob = generateCertBlob(
            name,
            courseName,
            date,
            expiryDate,
            certNum
          );
          const fileName = `${name}-${courseName}.pdf`;
          const res = await uploadToS3(pdfBlob, fileName);

          if (res.Location) {
            await httpCertificateDetails(
              courseId,
              userInfo?._id,
              res.Location
            );
            setIsCertificateGenerated(true);
          } else {
            console.error('Certificate upload failed: No location returned.');
          }
        } catch (err) {
          console.error('Error generating or uploading certificate:', err);
        } finally {
          setIsLoading(false);
        }
      }
    };
    generateAndUploadCertificate();
  }, [userInfo, courseFound, isCertificateGenerated]);

  const courseName = courseFound?.courseName;
  const name = `${data?.firstName} ${data?.lastName}`;
  const course = courseFound?.courseName;
  const dateCompleted = userInfo?.courses?.[0]?.date;
  const expiryDate = userInfo?.courses?.[0]?.expiryDate;
  const certNumber = userInfo?.courses?.[0]?.certNum;
  console.log('Score', userInfo?.courses?.[0]?.score);
  console.log('User info', courseFound);

  return (
    <AuthCheckUser>
      <HelmetProvider>
        <Helmet>
          <title>Course Complete | Yoda Safety Services</title>
          <meta name="Course Complete" content="Completed the course" />
        </Helmet>
      </HelmetProvider>
      <QuizCompleteContainer>
        <ResultsContainer>
          <img src={FinishedPicture} alt="Course Complete" />
          <DisplayUser courseName={courseName} score={userInfo?.courses?.[0]?.score} />
        </ResultsContainer>

        {/* TDG Certificate Button */}
        {courseName?.includes('TDG') && (
          <CompleteIncompleteButton
            primaryFunction={() => navigate(`/course/tdg-certificate/${id}`)}
            primarySpan="View Certificate"
            secondaryFunction={() => navigate('/user')}
            secondarySpan="Go Home"
          />
        )}

        {/* Certificate Generation Status */}
        {isLoading ? (
          <p style={{ color: 'black' }}>Certificate is generating, this may take a moment...</p>
        ) : (
          !courseName?.includes('TDG') && (
            <CompleteIncompleteButton
              primaryFunction={() =>
                handleDownload(
                  name,
                  course,
                  dateCompleted,
                  expiryDate,
                  certNumber
                )
              }
              primarySpan="Download Certificate"
              secondaryFunction={() => navigate('/user')}
              secondarySpan="Go Home"
            />
          )
        )}

        {/* Quiz Review */}
        {coursesLoading ? (<p>Course answeres are loading...</p>) : (
          courseFound && (<ReviewQuiz dataCourse={courseFound} />)
        )}
      </QuizCompleteContainer>
    </AuthCheckUser>
  );
};

export default CourseComplete;
