import React from 'react';
import PropTypes from 'prop-types';
import InputContainer from '../../Components/Form/Input';

const TDGInputs = ({ employer, setEmployer, address, setAddress }) => {
  return (
    <>
      {/* label, value, name, onChange, placeholder  */}
      <InputContainer
        label="Employer's Name"
        value={employer}
        name={employer}
        placeholder="Yoda Safety Services"
        onChange={(e) => setEmployer(e.target.value)}
      />
      <InputContainer
        label="Employer's Address"
        value={address}
        placeholder="123 Safety Street, Town Name "
        name={address}
        onChange={(e) => setAddress(e.target.value)}
      />
    </>
  );
};
TDGInputs.propTypes = {
  employer: PropTypes.string,
  setEmployer: PropTypes.func,
  address: PropTypes.string,
  setAddress: PropTypes.func,
};

export default TDGInputs;
