import styled from 'styled-components';

export const UserSettingsMain = styled.main`
  // border: 1px solid orange;
  width: 90%;
  // tried vh, dvh and now trying %
  height: 100vh;
  margin: 0 auto;
  padding-block: ${({ theme }) => theme.spacing.xl};
  h2 {
    padding-top: 0.5rem;
  }
  @media screen and (width <=600px) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.l};
  }
`;

export const UserSettingsSection = styled.section`
  /* border: 1px solid purple; */

  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.m};
  padding-top: ${({ theme }) => theme.spacing.m};
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey1};
  height: 100%;
  label {
    width: 20%;
  }
  input {
    width: 80%;
  }
  @media screen and (width <=1000px) {
    flex-direction: column;
  }
  @media screen and (width <= 768px) {
    margin-top: ${({ theme }) => theme.spacing.xs};
    padding-top: ${({ theme }) => theme.spacing.xs};
  }
`;
export const TitleSection = styled.div`
  /* border: 1px solid green; */
  width: 40%;
  padding-bottom: ${({ theme }) => theme.spacing.l};
  h4 {
    text-transform: uppercase;
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
  }
  span {
    margin-bottom: ${({ theme }) => theme.spacing.l};
  }
  @media screen and (width <= 1000px) {
    width: 100%;
    span {
      margin-bottom: ${({ theme }) => theme.spacing.l};
    }
  }
  @media screen and (width <= 768px) {
    padding-bottom: ${({ theme }) => theme.spacing.s};
  }
`;

export const InputSection = styled.div`
  width: 60%;
  /* border: 1px solid red; */
  @media screen and (width <=1000px) {
    width: 100%;
    button {
      margin-bottom: ${({ theme }) => theme.spacing.m};
    }
  }
`;

export const BottomButtonOuter = styled.section`
  margin-top: ${({ theme }) => theme.spacing.l};
`;
