import { useState, useEffect } from 'react';
import { fetchCompany } from '../singleCompany/getCompany';

const useCompany = (id) => {
  const [company, setCompany] = useState();
  const [successState, setSuccessState] = useState('');

  useEffect(() => {
    fetchCompany(id, setCompany, setSuccessState);
  }, [id]);

  return { company, successState, setCompany, setSuccessState };
};

export default useCompany;
