import { InputContainer } from './FormElements.styles';
import React from 'react';
import PropTypes from 'prop-types';
import AsteriskIcon from '../../Visuals/Icons/AsteriskIcon.icon';

// prettier-ignore
// Input Number Component: title, placeholder, value, setValue
const InputNumber = ({
  title,
  requiredInput,
  detail,
  value,
  setValue,
  containerClassName = '',
}) => {
  return (
    <InputContainer className={containerClassName}>
      <label>
        {title} {requiredInput && <AsteriskIcon />} <span className="detail">{detail}</span>
        
      </label>
      <input
        min="0"
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputContainer>
  );
};

InputNumber.propTypes = {
  title: PropTypes.string,
  requiredInput: PropTypes.bool,
  detail: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  containerClassName: PropTypes.string,
};

export default InputNumber;
