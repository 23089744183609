import styled from 'styled-components';

export const InforCardContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-content: center;
  padding: ${({ theme }) => theme.spacing.s};
  gap: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.grey};
  margin-block: ${({ theme }) => theme.spacing.xs};
  div {
    width: 90%;
  }
  span {
    border-bottom: 2px solid ${({ theme }) => theme.colors.orange4};
  }
`;
