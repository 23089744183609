import React, { useState, useEffect } from 'react';
import Modal from '../../../Components/Modal/ModalComponent.component';
import { useNavigate } from 'react-router-dom';
import { FormContainer, SubmitContainer } from '../CreateForm.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
// import CompanyAdmin from './CompanyAdmin/CompanyAdmin.component';
import LogoInput from '../../../Components/Form/LogoInput';
import TextareaDiv from '../../../Components/Form/Textarea';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import CreateCompanySubmit from './CompanyCreateSubmit';
import InputTextNested from '../../../Components/Form/InputTextNested';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadcrumbsComponent.component';
// prettier-ignore
const CreateCompany = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState('');
  const [notes, setNotes] = useState('');
  const [image, setImage] = useState('');
  const [company, setCompany] = useState({
    companyName: '',
    mainContact: '',
    secondaryContact: '',
    email: '',
    phoneOffice: '',
    phoneMobile: '',
    address: '',
    website: '',
  });
  // const [user, setUser] = useState({
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   phoneNumber: '',
  //   password: '',
  //   type: 'Manager',
  // });
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      const isCompanyValid =
        company.companyName.trim() !== '' &&
        company.mainContact.trim() !== '' &&
        company.phoneOffice.trim() !== '';
      // const isUserValid = Object.values(user).every(
      //   (value) => value.trim() !== ''
      // );
      //&& isUserValid
      setValidForm(isCompanyValid);
    };
    checkFormValidity();

  }, [company]);

  return (
    <AuthCheckAdmin>
      <HelmetProvider>
      <Helmet>
              <title>Create Company | Yoda Safety Services</title>
              <meta name="Create Company" content="Create a company form" />
            </Helmet>
            </HelmetProvider>
      <FormContainer>
        <div style={{ marginBottom: '1.5rem' }}>
                  <BreadCrumbs one="Admin Home" oneLink="/admin" two={'Create Company'} />
                </div>
        <h2>CREATE COMPANY</h2>
        <form>
          {/* Company Name */}
          <InputTextNested
            title="Company Name"
            value={company.companyName}
            name="companyName"
            requiredInput={true}
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Main Contact */}
          <InputTextNested
            title="Main Contact"
            value={company.mainContact}
            name="mainContact"
            requiredInput={true}
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Secondary Contact */}
          <InputTextNested
            title="Secondary Contact"
            value={company.secondaryContact}
            name="secondaryContact"
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Email of company */}
          <InputTextNested
            title="Email"
            value={company.email}
            name="email"
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Main Office Phone Number */}
          <InputTextNested
            title="Office Phone Number"
            value={company.phoneOffice}
            name="phoneOffice"
            requiredInput={true}
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Mobile Phone Number */}
          <InputTextNested
            title="Mobile Phone Number"
            value={company.phoneMobile}
            name="phoneMobile"
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Company Website Link */}
          <InputTextNested
            title="Website"
            value={company.website}
            name="website"
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Company Address */}
          <InputTextNested
            title="Address"
            value={company.address}
            name="address"
            nestedValue={setCompany}
            upperValue={company}
            containerClassName="formInputUnderline"
          />
          {/* Notes */}
          <TextareaDiv title="Notes" value={notes} setValue={setNotes} containerClassName="formInputUnderline"/>
         {/* Logo */}
          <LogoInput image={image} setImage={setImage} containerClassName="formInputUnderline" />
          {/* Set an Admin for the company */}
          {/* <CompanyAdmin title="Create Manager" user={user} setUser={setUser} /> */}
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <p>{result}</p>
              <PrimaryButton
                functionName={() => navigate('/admin')}
                span="Go Home"
              />
            </Modal>
          )}
          <SubmitContainer>
            <CreateCompanySubmit
              // user={user}
              image={image}
              company={company}
              notes={notes}
              result={result}
              setIsOpen={setIsOpen}
              setResult={setResult}
              disabled={validForm}
            />
          </SubmitContainer>
        </form>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default CreateCompany;
