import styled from 'styled-components';

export const PasswordOuterContianer = styled.main`
  /* justify-content: center; */
  width: 50%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
  margin-top: ${({ theme }) => theme.spacing.xxxl};
`;
