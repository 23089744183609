import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createCourse } from '../../../Utils/Courses/createCourse/postCourse';

// prettier-ignore
// Submit Function for Handling the course Submit
const CreateCourseSubmit = ({
  questions = [],
  pdf = {},
  notes = '',
  videoLinks = [],
  // documentation = [],
  att = [],
  image = {},
  expiryDate = 0,
  certificatePrefix = '',
  courseName = '',
  tags = [],
  description = '',
  price = 0,
  setSuccessState,
  setIsOpen,
  disabled,
  successState,
  activeCourse = false,
  partTwo = false,
}) => {
  // Main Loading
  const [loading, setLoading] = useState(false);
  // Do a loader for each one of the formData
  const [successStateA, setSuccessStateA] = useState('');
  // const [successStateB, setSuccessStateB] = useState('');
  const [successStateC, setSuccessStateC] = useState('');
  // For each question, remove any empty strings
  const removeEmptyStrings = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== '' || _ === ''));
  };
  const mappedQuestions = questions.map((question) => {
    return removeEmptyStrings(question);
  });

  // Going into the handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // The outline of what the course should look like
    const fullCourse = {
      courseName: courseName,
      price: price,
      description: description,
      tags: tags,
      notes: notes,
      videoLinks: videoLinks,
      partTwo: partTwo,
      quiz: mappedQuestions,
      expiryDate: expiryDate,
      certificatePrefix: certificatePrefix,
      active: activeCourse,
      image,
      pdf: '',
      attachments: [],
      markedForDeletion: null,
      setForDelete: false,
    };
    // console.log('FULL COURSE CREATE', fullCourse);
    // We upload the actual information above
    const formDataA = new FormData();
    // const formDataB = new FormData();
    const formDataC = new FormData();
    // We upload the pdf if there is one
    if (pdf) {
      const pdfFile = new File([pdf], pdf.name.replace(/\s+/g, '_'), {
        type: pdf.type,
      });
      formDataA.append('pdf', pdfFile);
    }
    if (att && att.length > 0) {
      att.forEach((file, index) => {
        const attachmentFile = new File(
          [file],
          file.name.replace(/\s+/g, '_'),
          { type: file.type }
        );
        formDataC.append(`attachment_${index}`, attachmentFile);
      });
    }

    await createCourse(
      fullCourse,
      formDataA,
      // formDataB,
      formDataC,
      setSuccessState,
      setSuccessStateA,
      // setSuccessStateB,
      setSuccessStateC,
      setIsOpen,
      setLoading,
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
          Main Data: <p>{successState}</p>
          PDF: <p>{successStateA}</p>
          {/* Image: <p>{successStateB}</p> */}
          Attachments: <p>{successStateC}</p>
          </div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PrimaryButton
            functionName={(e) => handleSubmit(e)}
            span="Create Course"
            disabled={!disabled}
          />
        </div>
      )}
    </div>
  );
};

CreateCourseSubmit.propTypes = {
  questions: PropTypes.array,
  pdf: PropTypes.object,
  notes: PropTypes.string,
  videoLinks: PropTypes.array,
  documentation: PropTypes.array,
  att: PropTypes.array,
  image: PropTypes.string,
  expiryDate: PropTypes.string,
  certificatePrefix: PropTypes.string,
  courseName: PropTypes.string,
  tags: PropTypes.array,
  description: PropTypes.string,
  price: PropTypes.number,
  setSuccessState: PropTypes.func,
  setIsOpen: PropTypes.func,
  disabled: PropTypes.bool,
  successState: PropTypes.string,
  activeCourse: PropTypes.bool,
  partTwo: PropTypes.bool,
};

export default CreateCourseSubmit;
