import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { MainContentContainer, MainSection } from '../OtherPages.styles';
import SideContainerComponent from '../SideContainer.component';

const PrivacyPolicy = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Privacy Policy | Yoda Safety Services</title>
          <meta
            name="Privacy Policy"
            content="Privacy Policy for Yoda Safety Services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Privacy Policy</h2>
        <p>Last Changed: </p>
        <p>Coming Soon</p>
      </MainSection>
    </MainContentContainer>
  );
};

export default PrivacyPolicy;
