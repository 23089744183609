// import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// POST: Creates an order for paypayl (step 1)
async function httpCreateOrder(cart) {
  try {
    return await fetch(`${API_URL}/orders`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cart),
    });
  } catch (err) {
    return err;
  }
}

// Captures the order for paypayl (step 2)
async function httpCaptureOrder(orderID, userId, courseId) {
  try {
    return await fetch(`${API_URL}/orders/${orderID}/capture`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, courseId }),
    });
  } catch (err) {
    return err;
  }
}

export { httpCreateOrder, httpCaptureOrder };
