import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// DELETE: Deletes a user by id - protected route
async function httpDeleteUserAccount(id) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/users/delete-user/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

export { httpDeleteUserAccount };
