import { AdminTabs } from '../../AdminRoutes/Admin/AdminStyles.styles';
import PropTypes from 'prop-types';
import React from 'react';
import TabButton from '../../Components/Buttons/TabButton/TabButton.component';
// Tabs for selecting companies/courses/users on the admin homepage
// prettier-ignore
const CompanyTabsComponent = ({
    showEmployees,
    setShowEmployees,
    showForms,
    setShowForms,
}) => {
  const handleTabClick = (tab) => {
    setShowEmployees(tab === 'employees');
    setShowForms(tab === 'forms');
  };

  return (
    <AdminTabs>
      {/* Employees*/}
      <TabButton 
        active={showEmployees}
        functionName={() => handleTabClick('employees')}
        span="employees"
      />
      
      {/* Forms */}
      <TabButton 
      active={showForms}
      functionName={() => handleTabClick('forms')}
      span="forms"
      />
    </AdminTabs>
  );
};

CompanyTabsComponent.propTypes = {
  showEmployees: PropTypes.bool.isRequired,
  setShowEmployees: PropTypes.func.isRequired,
  showForms: PropTypes.bool.isRequired,
  setShowForms: PropTypes.func.isRequired,
};

export default CompanyTabsComponent;
