import PropTypes from 'prop-types';
import React from 'react';

const SearchInput = ({ value, name, setValue }) => {
  return (
    <>
      {/* <label style={{ marginRight: '1rem' }} htmlFor={name}>
        Search for {name}
      </label> */}
      <input
        type="text"
        name={name}
        placeholder={`Search for ${name}`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  setValue: PropTypes.func,
  // placeholder: PropTypes.string,
};

export default SearchInput;
