import PropTypes from 'prop-types';
import React from 'react';
import InputText from '../../../../Components/Form/InputText';
import ArrayInput from '../../../../Components/Form/ArrayInput';
import TextareaDiv from '../../../../Components/Form/Textarea';
import InputNumber from '../../../../Components/Form/InputNumber';
import PlayIcon from '../../../../Visuals/Icons/PlayIcon.icon';
import Attatchments from '../../../../Components/Form/Attatchments';
import ActiveCourse from '../../../../Components/Form/ActiveCourse';
import EditPdfInput from '../../../../Components/Form/S3BucketEle/EditPdf';
import SelectImage from '../../../../Components/Form/SelectImage';

const InputEditCourses = ({ incomingProps }) => {
  const {
    courseName = '',
    description = '',
    price = 0,
    expiryDate = 0,
    certificatePrefix = '',
    notes = '',
    video = '',
    setCertificatePrefix,
    setCourseName,
    setDescription,
    setPrice,
    setNotes,
    videoLinks = [],
    setExpiryDate,
    setVideo,
    setVideoLinks,
    pdf = '',
    setImage,
    setNewPdf,
    image = '',
    newPdf = '',
    att = [],
    setAtt,
    activeCourse = false,
    setActiveCourse,
    partTwo = false,
    setPartTwo,
  } = incomingProps;
  // console.log('Incoming Props', incomingProps);
  return (
    <>
      {/* Course Name */}
      <InputText
        value={courseName}
        requiredInput={true}
        title="Course Name"
        name="courseName"
        setValue={setCourseName}
        containerClassName="formInputUnderline"
      />
      {/* Description */}
      <TextareaDiv
        requiredInput={true}
        value={description}
        name="description"
        title="Course Description"
        setValue={setDescription}
        containerClassName="formInputUnderline"
      />
      {/* Price */}
      <InputNumber
        requiredInput={true}
        value={price}
        name="price"
        title="Course Price"
        setValue={setPrice}
        containerClassName="formInputUnderline"
      />
      {/* Expiry Date */}
      <InputNumber
        value={expiryDate}
        title="Expiry Date"
        name="expiryDate"
        detail="in years"
        setValue={setExpiryDate}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      {/* Certificate Prefix */}
      <InputText
        value={certificatePrefix}
        title="Certificate Prefix"
        detail="format: FP-"
        name="certificatePrefix"
        setValue={setCertificatePrefix}
        requiredInput={true}
        placeholder="FP-"
        containerClassName="formInputUnderline"
      />
      {/* Notes about the course */}
      <TextareaDiv
        value={notes}
        name="notes"
        title="Notes"
        setValue={setNotes}
        containerClassName="formInputUnderline"
      />
      {/* Checkbox to change if course is visible to public */}
      <ActiveCourse
        containerClassName="formInputUnderline"
        activeCourse={activeCourse}
        setActiveCourse={setActiveCourse}
        label="Should the course be made visibile to the public?"
      />
      {/* Is the course a part two */}
      <ActiveCourse
        containerClassName="formInputUnderline"
        activeCourse={partTwo}
        setActiveCourse={setPartTwo}
        label="Is this course a part 2?"
      />
      {/* Video Links */}
      <ArrayInput
        label="Video Links"
        single={video}
        name="video"
        arrayItems={videoLinks}
        setSingle={setVideo}
        setArray={setVideoLinks}
        containerClassName="formInputUnderline"
        icon={<PlayIcon />}
      />
      {/* Attachments */}
      <Attatchments
        att={att}
        setAtt={setAtt}
        containerClassName="formInputUnderline"
      />
      {/* PDF Upload */}
      <EditPdfInput
        pdf={pdf}
        newPdf={newPdf}
        setNewPdf={setNewPdf}
        containerClassName="formInputUnderline"
      />
      {/* Image Input */}
      <SelectImage
        image={image}
        name="image"
        setImage={setImage}
        containerClassName="formInputUnderline"
      />
    </>
  );
};

InputEditCourses.propTypes = {
  incomingProps: PropTypes.shape({
    courseName: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    expiryDate: PropTypes.number,
    certificatePrefix: PropTypes.string,
    notes: PropTypes.string,
    video: PropTypes.string,
    setCertificatePrefix: PropTypes.func,
    setCourseName: PropTypes.func,
    setDescription: PropTypes.func,
    setPrice: PropTypes.func,
    setNotes: PropTypes.func,
    videoLinks: PropTypes.arrayOf(PropTypes.string),
    setExpiryDate: PropTypes.func,
    setVideo: PropTypes.func,
    setVideoLinks: PropTypes.func,
    pdf: PropTypes.string,
    setImage: PropTypes.func,
    setNewPdf: PropTypes.func,
    image: PropTypes.string,
    newPdf: PropTypes.string,
    att: PropTypes.array,
    setAtt: PropTypes.func,
    activeCourse: PropTypes.bool,
    setActiveCourse: PropTypes.func,
    partTwo: PropTypes.bool,
    setPartTwo: PropTypes.func,
  }),
};

export default InputEditCourses;
