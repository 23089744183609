import React from 'react';
import PropTypes from 'prop-types';
import { FileContainer } from '../../../AdminRoutes/CreateRoutes/CreateForm.styles';
import PresentationIcon from '../../../Visuals/Icons/PresentationIcon.icon';
import AsteriskIcon from '../../../Visuals/Icons/AsteriskIcon.icon';

const CreatePdfInput = ({ pdf, setNewPdf }) => {
  return (
    <>
      <h4>
        Presentation <PresentationIcon /> <AsteriskIcon />{' '}
        <span> PDF Only</span>
      </h4>
      <FileContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <label>
            {pdf?.length > 50 && (
              <span style={{ color: 'teal', marginTop: '1rem' }}>
                Current PDF: {pdf.slice(52)}
              </span>
            )}
          </label>
          <input
            type="file"
            required
            accept="application/pdf"
            onChange={(e) => setNewPdf(e.target.files[0])}
          />
        </div>
      </FileContainer>
    </>
  );
};
CreatePdfInput.propTypes = {
  pdf: PropTypes.string,
  setNewPdf: PropTypes.func,
};

export default CreatePdfInput;
