export const theme = {
  colors: {
    white: '#FEFEFE',
    offWhite: '#F3F3F3',
    grey: '#e6e6e6',
    grey1: '#D1CECE',
    grey2: '#B3B0B0',
    grey3: '#757575',
    grey4: '#515151',
    grey5: '#343434',
    grey6: '#262626',
    grey7: '#171717',
    yellow1: '#ffc933',
    yellow2: '#FDDC5E',
    yellow3: '#FDD84C',
    yellow4: '#FED242',
    yellow5: '#FFC933',
    orange1: '#F6D29D',
    orange2: '#F2AF17',
    orange3: '#EDA845',
    orange4: '#EA9A26',
    orange5: '#E68B07',
    orange6: '#DC8507',
    black: '#1D1D1D',
    lightRed: '#ffe4e5',
    errorRed: 'hsl(354, 85%, 44%)',
    lightGreen: '#eefeef',
    successGreen: '#3b8239',
    buttonBackground: `linear-gradient(
    160deg,
    #fadd7f 0%,
    #FDDC5E 33%,
    #facc4c 66%,
    #f7b456 100%
  );`,
  },
  spacing: {
    xxxs: '0.15rem',
    xxs: '0.25rem', // 4px
    xs: '0.5rem', // 8px
    s: '0.75rem', // 12px
    m: '1rem', // 16px
    l: '1.5rem', // 24px
    xl: '2rem', // 32px
    xxl: '3rem', // 48px
    xxxl: '4rem', // 64px
    xxxxl: '6rem', // 96px
    xxxxxl: '8rem', // 128px
    xxxxxxl: '12rem', // 192px
    xxxxxxxl: '16rem', // 256px
    xxxxxxxxl: '24rem', // 384px
  },
  animations: {
    fadeIn: 'fade-in 0.3s ease-in-out',
    fadeOut: 'fade-out 0.3s ease-in-out',
    slideIn: 'slide-in 0.3s ease-in-out',
    slideOut: 'slide-out 0.3s ease-in-out',
  },
  shadows: {
    shadow1: '0 1px 3px hsla(0, 0%, 0%, 0.2)',
    shadow2: '0 4px 6px hsla(0, 0%, 0%, 0.2)',
    shadow3: '0 5px 15px hsla(0, 0%, 0%, 0.2)',
    shadow4: '0 10px 24px hsla(0, 0%, 0%, 0.2)',
    shadow5: '0 15px 35px hsla(0, 0%, 0%, 0.2)',
    overlapShadow: '0 4px 6px rgba(0,0,0,.7), 0 5px 15px rgba(0,0,0,0.1)',
    hover: '0 3px 6px 1px var(--orange2), 0 3px 5px 1px var(--orange3)',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)',
  },
  borderRadius: '6px',
  letterSpacing: {
    courseTitles: '0.08rem;',
  },
};
