import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import { editUser } from '../../../Utils/Users/editUsers/editUser';
import PropTypes from 'prop-types';

// Function for handling editing a user
const EditUserSubmit = ({
  user,
  password,
  setIsOpen,
  setSuccessState,
  type,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editedUser = {
      ...user,
      password,
      ...(type && { type }),
    };
    await editUser(editedUser, setSuccessState, setIsOpen, setLoading);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PrimaryButton functionName={(e) => handleSubmit(e)} span="Edit User" />
      )}
    </>
  );
};

EditUserSubmit.propTypes = {
  user: PropTypes.object,
  password: PropTypes.string,
  setIsOpen: PropTypes.func,
  setSuccessState: PropTypes.func,
  type: PropTypes.string,
};

export default EditUserSubmit;
