import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import DestructiveButton from '../../../Components/Buttons/DestructiveButton/DestructiveButton.component';
import Modal from '../../../Components/Modal/ModalComponent.component';
// import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';

const ConfirmDeleteButton = ({ onDelete, itemName }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteSubmit = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <DestructiveButton
        functionName={() => setIsModalOpen(true)}
        span={`${itemName}`}
      />
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete {itemName}?</p>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <DestructiveButton
                functionName={(e) => {
                  e.preventDefault();
                  handleDeleteSubmit();
                }}
                span="Confirm Delete"
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

ConfirmDeleteButton.propTypes = {
  onDelete: PropTypes.func,
  itemName: PropTypes.string,
};

export default ConfirmDeleteButton;
