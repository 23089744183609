import styled from 'styled-components';

export const MainContentContainer = styled.main`
  /* border: 2px solid red; */
  display: flex;
  height: 100%;
  gap: 2rem;
  /* border: 2px solid blue; */
  /* @media screen and (max-width: 768px) {
    border: 2px solid red;
  }
  @media screen and (max-width: 468px) {
    border: 2px solid green;
  } */
`;
export const SideContainer = styled.aside`
  width: 20%;
  padding: 1rem;
  /* margin-top: 1rem; */
  background-color: ${({ theme }) => theme.colors.offWhite};
  /* border: 1px solid orange; */
  ul {
    width: 100%;
  }
  li {
    list-style: none;
    margin: 10px 0;
    width: 100%;
    /* border: 1px solid red; */
  }
  li.active {
    background-color: #be6868;
    border-radius: 5px;
  }
  a {
    text-decoration: none;
    color: #333;
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.colors.orange5};
    }
  }
  a.active {
    color: ${({ theme }) => theme.colors.orange5};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.orange1};
    font-weight: bold;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: block;
    padding: 10px 10px 10px 2px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MainSection = styled.section`
  /* margin-top: ${({ theme }) => theme.spacing.m}; */
  overflow-x: hidden;
  width: 80%;
  /* border: 1px solid red; */
  padding: 1rem;
  height: 100vh;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const ResourcesContainer = styled.div`
  /* border: 1px solid green; */
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
  /* border: 1px solid red; */
  @media screen and (width <= 768px) {
    /* border: 1px solid blue; */
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  /* height: 80%; */
`;

export const EachProvince = styled.li`
  /* border: 1px solid red; */
  list-style-type: none;
  width: 25%;
  padding: 1rem;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.offWhite};
  transition: transform 0.2s ease-in-out;
  height: 10%;
  a {
    color: ${({ theme }) => theme.colors.grey5};
  }
  a:hover {
    color: ${({ theme }) => theme.colors.orange5};
  }
  @media screen and (width <= 768px) {
    width: 100%;
  }
`;
