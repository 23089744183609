import React from 'react';
import UpArrowIcon from '../../Visuals/Icons/UpArrowIcon.icon';
import DownArrowIcon from '../../Visuals/Icons/DownArrowIcon.icon';
import { ScrollButtonContainer } from '../../Quiz/QuizPageStyles.styles';

const PageScroller = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };
  return (
    <>
      <ScrollButtonContainer>
        <UpArrowIcon functionName={scrollToTop} />
        <DownArrowIcon functionName={scrollToBottom} />
      </ScrollButtonContainer>
    </>
  );
};

export default PageScroller;
