import {
  CourseContainer,
  // TagContainer,
  OnlineCoursesContainer,
  SearchContainer,
  SearchBarContainer,
  FilterButton,
} from './TrainingStyles.styles';
import CourseCardSingle from './CourseCardComponent.component';
import { httpGetAllCourses } from '../../Hooks/courses.hooks';
import React, { useState, useEffect } from 'react';
import { httpFilterBySearch, httpFilterByTag } from '../../Hooks/courses.hooks';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { useQuery } from '@tanstack/react-query';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import SlidingTagMenu from './SlidingTagMenu.component';
import TertiaryButton from '../../Components/Buttons/TertiaryButton/TertiaryButton.component';
import PageScroller from '../../Components/PageScroller/PageScroller.component';
import { MainContentContainer } from '../OtherPages.styles';
import SideContainerComponent from '../SideContainer.component';

const OnlineTraining = () => {
  const [allCourses, setAllCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedTag, setSelectedTag] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const [searching, setSearching] = useState('');

  const { data, error, isLoading } = useQuery({
    queryKey: ['allCourses'],
    queryFn: () => httpGetAllCourses(),
    retry: 5,
  });
  useEffect(() => {
    if (data) {
      setAllCourses(data);
      setFilteredCourses(data);
    }
  }, [data]);
  // Button function for searching
  const searchQuery = async (e) => {
    e.preventDefault();
    if (searching.length === 0) {
      setFilteredCourses(allCourses);
      return;
    }
    let searchedForResults = await httpFilterBySearch(searching);
    if (searchedForResults) {
      setFilteredCourses(searchedForResults);
    }
  };

  const tagChange = async (selectedTag) => {
    if (selectedTag === 'All Courses') {
      setFilteredCourses(allCourses);
    } else {
      let searchedForResults = await httpFilterByTag(selectedTag);
      if (searchedForResults) {
        setFilteredCourses(searchedForResults);
      }
    }
    setIsOpen(false);
  };

  return (
    // <OnlineCoursesContainer>
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Online Training | Yoda Safety Services</title>
          <meta
            name="Online Training"
            content="Training online from yoda safety services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <OnlineCoursesContainer>
        <SearchContainer>
          <h2>Online Courses</h2>
          <SearchBarContainer>
            <input
              type="text"
              onChange={(e) => setSearching(e.target.value)}
              value={searching}
              placeholder="Search for a course"
              required
              name="searching"
            />
            <PrimaryButton functionName={searchQuery} span="search" />
          </SearchBarContainer>
        </SearchContainer>
        {/* <span>Contact us for special course pricing</span> */}
        <FilterButton>
          <TertiaryButton
            functionName={() => setIsOpen(true)}
            span="Filter Courses"
          />
        </FilterButton>
        <section style={{ display: 'flex' }}>
          <CourseContainer>
            {isLoading && <h3>Loading...</h3>}
            {error && <h3>There was an error getting the courses</h3>}
            {filteredCourses.length > 0 ? (
              <>
                {filteredCourses?.map((c) => {
                  return <CourseCardSingle key={c._id} c={c} />;
                })}
              </>
            ) : (
              <p>No courses under this tag</p>
            )}
          </CourseContainer>
        </section>
        <PageScroller />
        <SlidingTagMenu
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          tagChange={tagChange}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      </OnlineCoursesContainer>
      {/* </OnlineCoursesContainer> */}
    </MainContentContainer>
  );
};

export default OnlineTraining;
