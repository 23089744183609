import styled from 'styled-components';

export const BreadcrumbContainer = styled.div`
  display: flex;
  width: auto;
  margin-top: ${({ theme }) => theme.spacing.xs};
  gap: ${({ theme }) => theme.spacing.xs};
  /* border: 1px solid orange; */
  // margin-left: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  .primaryArrow {
    fill: none;
  }
  .secondaryArrow {
    fill: ${({ theme }) => theme.colors.orange3};
  }
`;

export const EachBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  span {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.grey2};
  }
  span:hover {
    color: ${({ theme }) => theme.colors.orange3};
    text-decoration: underline;
  }
  &:last-child {
    content: '';
  }
`;
