import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const MappedUsersOrEmployee = ({ users, title }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const editUser = (userId) => {
    navigate(`/admin/edit-company/${id}/managers-employees/${userId}`);
  };

  return (
    <>
      <h4>{title}</h4>
      {users?.map((user) => (
        <li key={user?._id}>
          <p>
            {user?.firstName} {user?.lastName}
          </p>
          <p>{user?.email}</p>
          {user?.active ? (
            <p style={{ color: 'green' }}>active</p>
          ) : (
            <p style={{ color: 'red' }}>inactive</p>
          )}
          <SecondaryButton
            functionName={() => editUser(user?._id)}
            span={`Edit ${user?.type}`}
          />
        </li>
      ))}
    </>
  );
};

MappedUsersOrEmployee.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      active: PropTypes.bool,
      type: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

export default MappedUsersOrEmployee;
