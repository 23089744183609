import { LoaderContainer } from './LoaderStyles.styles';
import React from 'react';
// Loader Component - not sure if it works
const Loader = () => {
  return (
    <LoaderContainer>
      <div className="loader"></div>
    </LoaderContainer>
  );
};

export default Loader;
