import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { QuizContainer, ButtonContainer } from './Quiz.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import PropTypes from 'prop-types';
import { ClearInput } from './ClearInput';
import Option1Questions from './QuestionTypes/Option1';
import Option2Questions from './QuestionTypes/Option2';

// prettier-ignore
// Component for creating a question
const CreateQuestion = ({ questions, setQuestions }) => {
  const [option, setOption] = useState(true);
  // const [disabled, setDisabled] = useState(true);

  // 8 Options Question
  const [quest, setQuest] = useState({
    question: '',
    answer1: '',
    answer2: '',
    answer3: '',
    answer4: '',
    answer5: '',
    answer6: '',
    answer7: '',
    answer8: '',
    correctAnswer: '',
    id: uuidv4(),
  });

  // True or false question
  const [quest2, setQuest2] = useState({
    question: '',
    answer1: 'true',
    answer2: 'false',
    correctAnswer: '',
    id: uuidv4(),
  });
 
  // Handles the submit of a question for both kinds of questions
  const handleSubmit = (e) => {
    e.preventDefault();
    if (option) {
      setQuestions([...questions, quest]);
      ClearInput(setQuest, setQuest2)
    } else {
      const newQuestion = {
        id: uuidv4(),
        question: quest2.question,
        answer1: 'true',
        answer2: 'false',
        correctAnswer: quest2.correctAnswer,
      };
      setQuestions([...questions, newQuestion]);
      ClearInput(setQuest, setQuest2);
    }
  };

  // For making sure that there is a correctAnswer
  // useEffect(() => {
  //   if(option && quest.question &&
  //       quest.answer1 &&
  //       quest.answer2  && 
  //       quest.correctAnswer 
  //   ) {
  //     setDisabled(false);
  //   }
  // }, [option, quest]);

  return (
    <QuizContainer>
      {option ? (
        <>
          <Option1Questions quest={quest} setQuest={setQuest} />
        </>
      ) : (
        <>
        <Option2Questions quest2={quest2} setQuest2={setQuest2} />
        </>
      )}
      <ButtonContainer>
        <SecondaryButton
          functionName={(e) => {
            e.preventDefault();
            setOption(!option);
            ClearInput(setQuest, setQuest2);
          }}
          span="Select Type"
        />
        <PrimaryButton
          functionName={(e) => handleSubmit(e)}
          span="Add Question"
          // disabled={disabled}
        />
      </ButtonContainer>
    </QuizContainer>
  );
};
CreateQuestion.propTypes = {
  questions: PropTypes.array,
  setQuestions: PropTypes.func,
};

export default CreateQuestion;
