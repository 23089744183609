import React from 'react';
import PropTypes from 'prop-types';
import InputTextNested from '../../InputTextNested';

const Option1Questions = ({ quest, setQuest }) => {
  return (
    <>
      <InputTextNested
        title="Question"
        value={quest?.question}
        name="question"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 1"
        value={quest?.answer1}
        name="answer1"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 2"
        value={quest?.answer2}
        name="answer2"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 3"
        value={quest?.answer3}
        name="answer3"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 4"
        value={quest?.answer4}
        name="answer4"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 5"
        value={quest?.answer5}
        name="answer5"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 6"
        value={quest?.answer6}
        name="answer6"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 7"
        value={quest?.answer7}
        name="answer7"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <InputTextNested
        title="Answer 8"
        value={quest?.answer8}
        name="answer8"
        nestedValue={setQuest}
        upperValue={quest}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <p>
          Correct Answer:{' '}
          {quest?.correctAnswer.length > 0
            ? quest?.correctAnswer
            : 'No answer selected'}
        </p>
        <select
          multiple={false}
          value={quest?.correctAnswer || ''}
          name="correctAnswer"
          onChange={(e) =>
            setQuest({ ...quest, [e.target.name]: e.target.value })
          }
        >
          {quest?.answer1 && <option value={quest?.answer1}>Answer 1</option>}
          {quest?.answer2 && <option value={quest?.answer2}>Answer 2</option>}
          {quest?.answer3 && <option value={quest?.answer3}>Answer 3</option>}
          {quest?.answer4 && <option value={quest?.answer4}>Answer 4</option>}
          {quest?.answer5 && <option value={quest?.answer5}>Answer 5</option>}
          {quest?.answer6 && <option value={quest?.answer6}>Answer 6</option>}
          {quest?.answer7 && <option value={quest?.answer7}>Answer 7</option>}
          {quest?.answer8 && <option value={quest?.answer8}>Answer 8</option>}
        </select>
      </div>
    </>
  );
};
Option1Questions.propTypes = {
  quest: PropTypes.shape({
    question: PropTypes.string,
    answer1: PropTypes.string,
    answer2: PropTypes.string,
    answer3: PropTypes.string,
    answer4: PropTypes.string,
    answer5: PropTypes.string,
    answer6: PropTypes.string,
    answer7: PropTypes.string,
    answer8: PropTypes.string,
    correctAnswer: PropTypes.string,
  }),
  setQuest: PropTypes.func,
};

export default Option1Questions;
