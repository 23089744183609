import styled from 'styled-components';

export const FooterDiv = styled.footer`
  background-color: black;
  padding-block: ${({ theme }) => theme.spacing.m};
  width: 100%;
  margin-top: auto;
  height: auto;
`;

export const OuterDiv = styled.div`
  /* border: 1px solid orange; */
  display: flex;
  flex-wrap: nowrap;
  /* display: grid; */
  /* grid-template-columns: 1fr 2fr; */
  gap: ${({ theme }) => theme.spacing.m};
  /* @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  } */
  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 0;
    justify-content: start;
  }
`;

export const LeftFooter = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs};
  padding-inline: ${({ theme }) => theme.spacing.m};
  /* border: 1px solid green; */
  img {
    height: 80px;
    width: 180px;
  }

  @media screen and (width <= 768px) {
    img {
      height: 70px;
      width: 130px;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      /* border: 2px solid red; */
    }
  }
  @media screen and (width <= 500px) {
    img {
      height: 50px;
      width: 100px;
      margin-left: 0.3rem;
    }

    width: 90%;
    padding-inline: 0;
    padding-left: 0.5rem;
  }
`;

export const RightFooter = styled.div`
  width: 85%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  a {
    color: ${({ theme }) => theme.colors.yellow5};
    text-decoration: underline;
    padding-right: 1rem;
    font-size: 0.7rem;
  }
  p {
    color: ${({ theme }) => theme.colors.grey2};
    font-size: 1rem;
  }
  span {
    color: ${({ theme }) => theme.colors.grey3};
    font-style: italic;
    font-size: 0.8rem;
  }
  @media screen and (width <= 1000px) {
    padding-left: 0.5rem;
    /* border: 1px solid orange; */
    p,
    span {
      font-size: 0.9rem;
      padding-left: 0.8rem;
    }
    a {
      font-size: 0.7rem;
      padding-left: 0.8rem;
    }
  }
  @media screen and (width <= 500px) {
    /* width: 90%; */
    a {
      font-size: 0.4rem;
      padding-left: 0.5rem;
    }
    p,
    span {
      font-size: 0.6rem;
      padding-left: 0.5rem;
    }
  }
`;
