import { useNavigate } from 'react-router-dom';
import {
  CourseCardComponent,
  CourseImage,
  CourseInfo,
} from './TrainingStyles.styles';
import TertiaryButton from '../../Components/Buttons/TertiaryButton/TertiaryButton.component';
import React from 'react';
import PropTypes from 'prop-types';

const CourseCardSingle = ({ c }) => {
  const navigate = useNavigate();
  const learnMore = (e, id) => {
    e.preventDefault();
    navigate(`/course/${id}`);
  };
  if (!c?.active) {
    return null;
  }

  return (
    <CourseCardComponent key={c?._id}>
      <CourseImage>
        <img src={c?.image} alt={c?.courseName} />
      </CourseImage>
      <CourseInfo>
        <div className="topContainer">
          <h4>{c?.courseName}</h4>
          <p>
            {c?.description.length <= 100
              ? c?.description
              : c?.description.slice(0, 100) + ' ...'}
          </p>
        </div>
        <div className="buttonContainer">
          <TertiaryButton
            functionName={(e) => learnMore(e, c?._id)}
            span="Learn more"
          />
        </div>
      </CourseInfo>
    </CourseCardComponent>
  );
};
CourseCardSingle.propTypes = {
  c: PropTypes.shape({
    _id: PropTypes.string,
    image: PropTypes.string,
    courseName: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    active: PropTypes.bool,
  }),
};

export default CourseCardSingle;
