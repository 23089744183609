import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// GET: All the newsletters for the admin page - protected route
async function httpGetAllNewsletters() {
  const token = Auth.getToken();
  try {
    let newsletters = await fetch(`${API_URL}/newsletters`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return newsletters.json();
  } catch (err) {
    return err;
  }
}
// GET: Single Newsletter to Edit - protected route
async function httpGetSingleNewsletter(id) {
  const token = Auth.getToken();
  try {
    let news = await fetch(`${API_URL}/newsletters/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return news.json();
  } catch (err) {
    return err;
  }
}

export { httpGetAllNewsletters, httpGetSingleNewsletter };
