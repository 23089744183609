import React, { useState, useEffect } from 'react';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import { useParams, useNavigate } from 'react-router-dom';
import {
  BreadcrumbContainer,
  SingleCourseContainer,
  SingleCardContainer,
  SingleCourseImage,
  SingleCourseInfo,
  SingleTop,
  SingleBottom,
  SingleButton,
} from './EachCourse.styles';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import Auth from '../Helpers/auth';
import ImportantIcon from '../Visuals/Icons/ImportantIcon.icon';
import InfoCard from '../Components/InfoCard/InfoCard.component';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import NotFound from '../Routes/MainComponents/not-found/NotFoundPage.component';
import Loader from '../Components/Loader/LoaderComponent.component';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
// Showing a single course to purchase
// prettier-ignore
const SingleCourse = () => {
  const { id } = useParams();
  const [course, setCourse] = useState();
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const FetchCourse = async () => {
      setLoading(true);
      const data = await httpGetSingleCourse(id);
      if (!data) setErr('Error getting the course');
      setCourse(data);
      setLoading(false);
    };
    FetchCourse();
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  if (err || !course?.active) {
    return <NotFound />
  }

  return (
    <AuthCheckUser>
      <HelmetProvider>
      <Helmet>
              <title>{course?.courseName ? `${course?.courseName}` : 'Course '} | Yoda Safety Services</title>
              <meta name="About Course" content="Course details" />
            </Helmet>
            </HelmetProvider>
      <SingleCourseContainer>
        <BreadcrumbContainer>
          <BreadCrumbs
            one="courses"
            oneLink="/online"
            two={course?.courseName}
          />
        </BreadcrumbContainer>
        {course.active ? (
        <SingleCardContainer>
          <SingleTop>
            <SingleCourseImage>
              {err && <p>{err}</p>}
              <img src={course?.image} alt="course" />
            </SingleCourseImage>
            <SingleCourseInfo>
              <div className="courseTop">
                <h2>{course?.courseName}</h2>
                <h3>${course?.price}</h3>
              </div>

              <div className="courseMiddle">
                <InfoCard paragraph="With each course you will start with a detailed PowerPoint presentation that provides the information needed. 
                Alongside the presentation, you will have videos and documentation that dives more into the subject matter.
                After reviewing the course material, you will be able to take a quiz to test your knowledge. To pass the quiz you must get 85% or higher.
                If you do not pass the quiz, you will have the opportunity to take the quiz as many times as needed. 
                Upon passing the quiz, you will receive a certificate of completion."/>
                {/* <div className="courseInfo">
                  <div className="infoIconContainer">
              </div>
              </div> */}
              {course?.expiryDate > 0 ? (
                <p>
                <ImportantIcon />
                    Expires in {' '}
                      {course?.expiryDate} years
                  </p>
              ) : (<p>Course does not expire</p>)}
                </div>
                {course?.partTwo && (
                  <p> <ImportantIcon />PART 2
                  PRACTICAL TRAINING MUST BE COMPLETE BEFORE A VALID OPERATOR CERTIFICATE WILL BE ISSUED. SCHEDULE CALL OR EMAIL US: YODA SAFETY SERVICES  
                  OFFICE 902 478 1972 OR EMAIL hbenoit.yoda@outlook.com</p>
                )}

                <div className="industries">
                  <h4>Industries:</h4>
                  <ul style={{ paddingLeft: '2rem', listStyleType: 'square' }}>
                    {course?.tags?.map((t, x) => (
                      <li  className="industry-item" key={x}>{t}</li>
                    ))}
                  </ul>
              </div>
              <div className="courseDescription">
                <h4>About this course</h4>
                <p>{course?.description}</p>
              </div>
              <SingleButton>{Auth.loggedIn() ? (<PrimaryButtonComponent span="Buy Course" functionName={() => navigate(`/buy-course/${id}`)}/>) : (<p>Sign in to purchase a course</p>)}</SingleButton>
            </SingleCourseInfo>
          </SingleTop>
          <SingleBottom></SingleBottom>
        </SingleCardContainer>
        )
      : (<NotFound />)}
      </SingleCourseContainer>
    </AuthCheckUser>
  );
};

export default SingleCourse;
