import EachComponent from '../../../Components/Entity/EachComponent.component';
import React, { useState, useEffect } from 'react';
import SearchUsers from './SearchUsersBar.component';
import useUsers from '../../../Utils/Users/allUsers/useUsers';

// Results for Users tab on admin home page
const UsersTab = () => {
  const { users } = useUsers();
  const [filtered, setFiltered] = useState([]);
  const [err, setErr] = useState('');

  useEffect(() => {
    setFiltered(users);
  }, [users]);

  return (
    <section>
      <h3>USERS</h3>
      <SearchUsers users={users} setFiltered={setFiltered} setErr={setErr} />
      {err && <p style={{ color: 'red' }}>{err}</p>}
      {filtered?.map((c) => {
        return (
          <EachComponent
            key={c?._id}
            name={c?.firstName + ' ' + c?.lastName}
            route={`/admin/edit-user/${c?._id}`}
          />
        );
      })}
    </section>
  );
};

export default UsersTab;
