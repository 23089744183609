import EachComponent from '../../../Components/Entity/EachComponent.component';
import SearchCompanies from './SearchCompaniesBar.component';
import React, { useState, useEffect } from 'react';
import useCompanies from '../../../Utils/Companies/allCompanies/useCompanies';

// Results for the companies tab on admin page
const CompaniesTab = () => {
  const { companies, successState } = useCompanies();
  const [filtered, setFiltered] = useState([]);
  const [err, setErr] = useState('');

  useEffect(() => {
    setFiltered(companies);
  }, [companies]);

  return (
    <section>
      <h3>COMPANIES</h3>
      <SearchCompanies
        companies={companies}
        setFiltered={setFiltered}
        setErr={setErr}
      />
      <p style={{ color: 'red' }}>{err}</p>
      <p style={{ color: 'green' }}>{successState}</p>
      {filtered?.map((c, idx) => {
        return (
          <EachComponent
            id={c?._id || idx}
            key={c?._id || idx}
            name={c?.companyName}
            route={`/admin/edit-company/${c?._id}`}
          />
        );
      })}
    </section>
  );
};

export default CompaniesTab;
