import EachComponent from '../../../Components/Entity/EachComponent.component';
import React, { useState, useEffect } from 'react';
import SearchNewsletters from './SearchNewsletters.component';
import useNewsletters from '../../../Utils/Newsletters/useNewsletters';
import { useNavigate } from 'react-router-dom';
import TertiaryButton from '../../../Components/Buttons/TertiaryButton/TertiaryButton.component';

// Results for Users tab on admin home page
const NewsletterTab = () => {
  // This keeps running and running
  const { newsletters } = useNewsletters();
  const [filtered, setFiltered] = useState([]);
  const [err, setErr] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setFiltered(newsletters);
  }, [newsletters]);

  const goToSubscribers = () => {
    navigate('/admin/newsletter-subscribers');
  };

  return (
    <section>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>NEWSLETTERS</h3>
        <TertiaryButton
          functionName={goToSubscribers}
          span="Newsletter Subscribers"
        />
      </div>
      <SearchNewsletters
        newsletters={newsletters}
        setFiltered={setFiltered}
        setErr={setErr}
      />
      {err && <p style={{ color: 'red' }}>{err}</p>}
      {filtered?.map((c) => {
        return (
          <EachComponent
            key={c?._id}
            name={`${c?.newsletterTitle} for ${c?.month}`}
            route={`/admin/edit-newsletter/${c?._id}`}
          />
        );
      })}
    </section>
  );
};

export default NewsletterTab;
