import CertificateBackground from '../../Visuals/Images/TDG_Certificate.png';
import jsPDF from 'jspdf';

export const generateCertTDG = (
  name,
  dateCompleted,
  expiryDate,
  employer,
  address
) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [8, 11],
  });

  doc.addImage(
    CertificateBackground,
    'PNG',
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight()
  );
  // Top Left Corner
  doc.setFontSize(10);
  doc.setFont('helvetica');
  doc.text(name, 2, 0.75);
  doc.setFontSize(9);
  doc.text(employer, 1.4, 1.95);
  doc.setFontSize(9);
  doc.text(dateCompleted, 1.3, 2.35);
  doc.text(expiryDate, 2.7, 2.35);
  doc.setFontSize(7);
  doc.text(address, 1.4, 2.15);
  // Bottom Left Corner
  doc.setFontSize(10);
  doc.setFont('helvetica');
  doc.text(name, 2, 3.65);
  doc.setFontSize(9);
  doc.text(dateCompleted, 2.6, 4.2);
  doc.text(expiryDate, 2.6, 4.4);
  // Top Right Corner
  doc.text(dateCompleted, 5.75, 0.9);
  doc.text(dateCompleted, 5.75, 1.08);
  doc.text(dateCompleted, 5.75, 1.26);
  doc.text(dateCompleted, 5.75, 1.43);
  doc.text(dateCompleted, 5.75, 1.61);
  doc.text(dateCompleted, 5.75, 1.78);
  // Bottom Right corner
  doc.text(dateCompleted, 5.75, 3.37);
  doc.text(dateCompleted, 5.75, 3.54);
  doc.text(dateCompleted, 5.75, 3.71);
  doc.text(dateCompleted, 5.75, 3.89);
  doc.text(dateCompleted, 5.75, 4.07);
  doc.text(dateCompleted, 5.75, 4.24);
  doc.save(`${name}-TDG.pdf`);
};
