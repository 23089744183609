import { SideContainer } from './OtherPages.styles';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const SideContainerComponent = () => {
  const location = useLocation();
  const links = [
    { path: '/', label: 'Home' },
    { path: '/online', label: 'Online Training' },
    { path: '/contact-us', label: 'Contact Us' },
    { path: '/resources', label: 'Resources' },
    { path: '/program', label: 'Program Development' },
    { path: '/privacy-policy', label: 'Privacy Policy' },
    // { path: '/wcb-certified', label: 'WCB Certified' },
    // { path: '/classroom', label: 'Classroom Training' },
    // { path: '/insurance', label: 'Insurance' },
    { path: '/terms-and-conditions', label: 'Terms & Conditions' },
  ];

  return (
    <SideContainer>
      <ul>
        {links.map((link) => (
          <li key={link.path}>
            <NavLink
              to={link.path}
              className={({ isActive }) =>
                isActive || location.pathname === link.path ? 'active' : ''
              }
            >
              {link.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </SideContainer>
  );
};

export default SideContainerComponent;
