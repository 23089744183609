import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '../../Visuals/Icons/LinkIcon.icon';
import { FileContainer } from '../../AdminRoutes/CreateRoutes/CreateForm.styles';
import AsterikIcon from '../../Visuals/Icons/AsteriskIcon.icon';
import DeleteIcon from '../../Visuals/Icons/DeleteIcon.icon';

// prettier-ignore
const Attatchments = ({ setAtt, att }) => {
  const [localFiles, setLocalFiles] = useState([]);

  useEffect(() => {
    setLocalFiles(att)
  }, [att]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if(selectedFiles.length > 4) {
      alert('You can only upload 4 files');
      return;
    }
    const newFiles = [...localFiles, ...selectedFiles];
    setLocalFiles(newFiles);
    setAtt(newFiles);
  };

  const deleteFile = (e, deleting) => {
    e.preventDefault();
    let filtered = localFiles.filter((file) => file.name || file !== deleting);
    setLocalFiles(filtered);
    setAtt(filtered);
  };

  return (
    <div>
      <h4>
        Attachments
        <LinkIcon /> {' '}
        <AsterikIcon /> <span>PDF Only, Max 4 Files</span>
      </h4>
      <FileContainer>
        <label>
          <span style={{fontStyle: 'italic'}}>Highlight the files and select open</span>
          <br />
          {localFiles.length > 0 && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem', }}
            >
              {localFiles?.map((a, idx) => (
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '90%',
                    marginTop: '1rem',
                    alignItems: 'center',
                    
                  }}
                >
                  {a.name && <li>{a.name}</li>}
                  {a.length > 50 && <li>{a.slice(52)}</li>}
                  <DeleteIcon functionName={(e) => deleteFile(e, a?.name || a)} />
                </div>
              ))}
            </div>
          )}
        </label>
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleFileChange}
          style={{ height: '50%'}}
        />
      </FileContainer>
    </div>
  );
};
Attatchments.propTypes = {
  setAtt: PropTypes.func,
  att: PropTypes.array,
};

export default Attatchments;
