import styled from 'styled-components';
// For the main question page
export const QuizContainer = styled.main`
  /* width: 65%; */
  /* height: 100vh; */
  display: flex;
  /* height: 100vh; */
  /* margin: 0 auto; */
  /* padding: ${({ theme }) => theme.spacing.m}; */
  /* border: 15px solid red; */
  @media screen and (width <= 1000px) {
    width: 90%;
  }
  @media screen and (width <= 600px) {
    width: 95%;
  }
`;
export const CourseInfoContainer = styled.div`
  /* border: 1px solid red; */
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
  span {
    border-bottom: 2px solid ${({ theme }) => theme.colors.orange3};
    margin-top: ${({ theme }) => theme.spacing.m};
  }
  div {
    background-color: ${({ theme }) => theme.colors.grey};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: ${({ theme }) => theme.spacing.m};
  }
  section {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  section p {
    color: ${({ theme }) => theme.colors.grey4};
    border-bottom: 2px solid ${({ theme }) => theme.colors.orange3};
  }
`;

//For the bottom button mobile view
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  /* border: 1px solid orange; */
  gap: ${({ theme }) => theme.spacing.xl};
  button {
    min-width: 200px;
    margin-block: ${({ theme }) => theme.spacing.xl};
  }
  @media screen and (width <= 600px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.spacing.xs};
    button {
      min-width: 100%;
      margin-block: ${({ theme }) => theme.spacing.xs};
    }
  }
  @media screen and (width >= 1300px) {
    /* display: none; */
  }
`;
// For the button on the left side full screen
export const ButtonContainerFull = styled.div`
  /* display: flex; */
  /* justify-content: center; */
  /* border: 1px solid orange; */
  /* gap: ${({ theme }) => theme.spacing.xl}; */
  button {
    min-width: 90%;
    /* margin-block: ${({ theme }) => theme.spacing.xl}; */
  }
  @media screen and (width <= 1300px) {
    display: none;
  }
`;
// For Each Question
export const EachQuestionContainer = styled.form`
  display: flex;
  /* border: 1px solid green; */
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s};
  margin-block: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  border-left: 12px solid ${({ theme }) => theme.colors.orange3};
  /* background-color: ${({ theme }) => theme.colors.white}; */
  box-shadow: ${({ theme }) => theme.shadows.shadow3};
  /* border: 2px solid orange; */
  p {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grey5};
    margin-bottom: ${({ theme }) => theme.spacing.m};
    padding-left: ${({ theme }) => theme.spacing.s};
  }
  .answersContainer {
    /* border: 10px solid orange; */
    gap: ${({ theme }) => theme.spacing.m};
  }
  @media screen and (width <= 768px) {
    /* border: 1px solid blue; */
    padding: ${({ theme }) => theme.spacing.s};
    border-left: 6px solid ${({ theme }) => theme.colors.orange3};
    margin-block: ${({ theme }) => theme.spacing.s};
  }
`;

export const EachAnswer = styled.div`
  display: flex;
  width: 90%;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xxs};
  margin-left: ${({ theme }) => theme.spacing.xxxl};
  margin-top: ${({ theme }) => theme.spacing.s};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-left: ${({ theme }) => theme.spacing.m};
  /* border: 1px solid blue; */
  input {
    box-shadow: none;
  }
  label {
    font-weight: normal;
    margin-left: ${({ theme }) => theme.spacing.s};
    padding-left: ${({ theme }) => theme.spacing.xs};
  }
  @media screen and (width <= 768px) {
    margin-left: ${({ theme }) => theme.spacing.s};
    /* border: 12px solid red; */
  }
`;
export const CompleteContainer = styled.main`
  /* border: 1px solid red; */
  text-align: left;
  /* border: 1px solid purple; */
`;

export const IncompleteContainer = styled.main``;

export const ScrollButtonContainer = styled.div`
  position: fixed;
  bottom: 50%;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 50;
`;
