import React, { useState } from 'react';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import DestructiveButton from '../../../Components/Buttons/DestructiveButton/DestructiveButton.component';
import PropTypes from 'prop-types';
import { deleteCompany } from '../../../Utils/Companies/editCompany/deleteCompany';

// Deletes a company
const DeleteCompanyButton = ({ id = '', setSuccessState, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const deleteCompanySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!id) {
      setSuccessState('ID is required');
      setLoading(false);
      return;
    }
    await deleteCompany(id, setSuccessState, setIsOpen, setLoading);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DestructiveButton
          functionName={deleteCompanySubmit}
          span="Delete Company"
        />
      )}
    </>
  );
};

DeleteCompanyButton.propTypes = {
  id: PropTypes.string,
  setSuccessState: PropTypes.func,
  setIsOpen: PropTypes.func,
};

export default DeleteCompanyButton;
