import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SearchInput from '../SearchInputComponent.component';
import { InsideSearch } from '../AdminStyles.styles';

const SearchCourses = ({ courses, setFiltered, setErr }) => {
  const [search, setSearch] = useState('');
  const setSearchCourses = (e) => {
    e.preventDefault();
    setErr('');
    const searchResults = courses?.filter((c) =>
      c?.courseName.toLowerCase().includes(search.toLowerCase())
    );
    if (!searchResults.length) {
      setErr('No courses found');
    }
    setFiltered(searchResults);
  };
  return (
    <InsideSearch>
      <SearchInput value={search} name="courses" setValue={setSearch} />
      <PrimaryButton functionName={setSearchCourses} span="search" />
    </InsideSearch>
  );
};
SearchCourses.propTypes = {
  courses: PropTypes.array,
  setFiltered: PropTypes.func,
  setErr: PropTypes.func,
};

export default SearchCourses;
