import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// PUT: Edits a newsletter - protected route
async function httpEditNewsletter(id, newsletter) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/newsletters/${id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(newsletter),
    });
  } catch (err) {
    return err;
  }
}
// PUT: Deletes a newsletter - protected route
async function httpDeleteNewsletter(id) {
  console.log('Deleting by this id', id);
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/newsletters/delete-newsletter/${id}`, {
      method: 'put',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

export { httpEditNewsletter, httpDeleteNewsletter };
