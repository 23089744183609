import React from 'react';
import PropTypes from 'prop-types';

const UpArrowIcon = ({ functionName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="icon icon-arrow-thin-up-circle"
      onClick={functionName}
    >
      <circle cx="12" cy="12" r="10" className="primary" />
      <path
        className="secondary"
        d="M13 9.41V17a1 1 0 0 1-2 0V9.41l-2.3 2.3a1 1 0 1 1-1.4-1.42l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.42L13 9.4z"
      />
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="12"
        cy="12"
        r="10"
        className="primary"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        className="secondary"
        d="M13 9.41V17a1 1 0 0 1-2 0V9.41l-2.3 2.3a1 1 0 1 1-1.4-1.42l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.42L13 9.4z"
      />
    </svg>
  );
};

UpArrowIcon.propTypes = {
  functionName: PropTypes.func,
};

export default UpArrowIcon;
