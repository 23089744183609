import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import useCourses from '../../../Utils/Courses/allCourses/useCourses';
import { httpEmailCourseInfo } from '../../../Hooks/courses.hooks';
import { httpBuyCourse } from '../../../Hooks/courses.hooks';

// Buy a course for the user - no payment
const FreeCourse = ({ id = '', email, userCourses, userName }) => {
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [status, setStatus] = useState('');
  const [userIdCourses, setUserIdCourses] = useState([]);
  const [notPurchasedCourses, setNotPurchasedCourses] = useState([]);
  const [courseSent, setCourseSent] = useState('');

  const { courses } = useCourses();

  useEffect(() => {
    if (userCourses) {
      let mapped = userCourses?.map((course) => course.courseId);
      setUserIdCourses(mapped);
    }
    if (userIdCourses && courses) {
      let mapped2 = courses?.filter((course) => {
        return !userIdCourses.includes(course._id);
      });
      setNotPurchasedCourses(mapped2);
    }
  }, [userCourses, courses]);

  const purchaseCourse = async (e) => {
    e.preventDefault();
    setCourseSent('');
    // setLoading(true);
    if (!id) {
      setStatus('User ID is missing');
      // setLoading(false);
      return;
    }
    if (status.length) {
      setStatus('');
    }
    const course = { courseId: selectedCourse._id, userId: id };
    setStatus('Purchasing course...');
    const res = await httpBuyCourse(course);
    if (res.ok) {
      console.log('Continue steps is true');
      setStatus('Course has been purchased');
      setCourseSent('Email is sending...');
      let res1 = await httpEmailCourseInfo(
        email,
        selectedCourse.courseName,
        userName
      );
      let data = await res1.json();
      console.log('Email response', res1, data);
      if (res1.ok) {
        setCourseSent(data.msg || 'Email has been sent');
      } else {
        setCourseSent(data.msg || 'Email has not been sent');
      }
    } else {
      setStatus('Purchased course, email not sent');
      setCourseSent('Email has not been sent');
    }
  };

  return (
    <AuthCheckAdmin>
      <div style={{ marginBlock: '2rem' }}>
        <h4>
          Assign a course <span>courses that user doesnt have</span>
        </h4>
        <span style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
          An email will be sent to the course upon pressing buy course
        </span>
        <br />
        <select
          id="courses"
          value={selectedCourse ? JSON.stringify(selectedCourse) : ''}
          onChange={(e) => setSelectedCourse(JSON.parse(e.target.value))}
          style={{ marginRight: '2rem', marginBlock: '2rem' }}
        >
          <option value="">Select Course</option>
          {notPurchasedCourses?.map((course) => {
            return (
              <option key={course?._id} value={JSON.stringify(course)}>
                {course?.courseName}
              </option>
            );
          })}
        </select>
        <PrimaryButton functionName={purchaseCourse} span="Buy Course" />
      </div>

      {/* {process.env.NODE_ENV === 'development' && ( */}
      {/* <div style={{ marginTop: '2rem' }}>
        <h4 style={{ marginBottom: '2rem' }}>
          Assign Any Course <span>All the courses</span>
        </h4>
        <select
          id="dev-courses"
          value={selectedCourse ? JSON.stringify(selectedCourse) : ''}
          onChange={(e) => setSelectedCourse(JSON.parse(e.target.value))}
          style={{ marginRight: '2rem' }}
        >
          <option value="">Select Any Course</option>
          {courses?.map((course) => {
            return (
              <option key={course?._id} value={JSON.stringify(course)}>
                {course?.courseName}
              </option>
            );
          })}
        </select>
        <PrimaryButton
          functionName={async (e) => {
            e.preventDefault();
            if (!id) {
              setStatus('User ID is missing');
              return;
            }
            if (!selectedCourse) {
              setStatus('No course selected');
              return;
            }
            const course = { courseId: selectedCourse._id, userId: id };
            setLoading(true);
            await freeCourse(course, setStatus, setLoading, setContinueSteps);
            if (continueSteps === true) {
              let res1 = await httpEmailCourseInfo(
                email,
                selectedCourse.courseName,
                userName
              );
              let data = await res1.json();
              if (res1.ok) {
                setCourseSent(data.msg || 'Development: Email has been sent');
              } else {
                setCourseSent(
                  data.msg || 'Development: Email has not been sent'
                );
              }
            } else {
              setStatus('Development: Course has not been sent');
            }
            setLoading(false);
          }}
          span="Assign Any Course"
        />
      </div> */}
      {/* )} */}
      <p>{status}</p>
      <p>{courseSent}</p>
      {/* </div> */}
    </AuthCheckAdmin>
  );
};

FreeCourse.propTypes = {
  id: PropTypes.string,
  email: PropTypes.string,
  userCourses: PropTypes.array,
  userName: PropTypes.string,
};

export default FreeCourse;
