import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import { httpSendNewsletter } from '../../../Hooks/newsletter/newsletter.post.hooks';

const NewsletterEmail = ({ id }) => {
  const [status, setStatus] = useState('');
  const handleSubmit = async (e) => {
    e.stopPropagation();
    try {
      setStatus('Sending Newsletter to everyone...');
      let res = await httpSendNewsletter(id);
      let data = await res.json();
      if (res.ok) {
        setStatus(data.msg || 'Newsletter was sent out');
      } else {
        setStatus(data.msg || 'There was an error');
      }
    } catch (err) {
      console.log(err);
      setStatus('There was an error in the backend');
    }
  };
  return (
    <>
      <p>{status}</p>
      <SecondaryButton functionName={handleSubmit} span="Send Newsletter Out" />
    </>
  );
};
NewsletterEmail.propTypes = {
  id: PropTypes.string,
};
export default NewsletterEmail;
