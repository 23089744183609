import { EachAnswer } from '../Completed/QuizComplete.styles';
import React from 'react';
import PropTypes from 'prop-types';

// Displays the answer for each question
const DisplayAnswersEach = ({ answer, corrrectAnswer, answeredByUser }) => {
  let className;
  if (answer === corrrectAnswer) {
    className = 'correct';
  } else if (answeredByUser) {
    className = 'incorrect';
  }
  return (
    <EachAnswer>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <p key={answer} className={className} style={{ padding: '0.3rem' }}>
          {answer}
        </p>
      </div>
    </EachAnswer>
  );
};
DisplayAnswersEach.propTypes = {
  answer: PropTypes.string,
  corrrectAnswer: PropTypes.string,
  answeredByUser: PropTypes.bool,
};

export default DisplayAnswersEach;
