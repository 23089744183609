import { useState, useEffect } from 'react';
import { fetchNewsletters } from './getNewsletters';

const useNewsletters = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [successState, setSuccessState] = useState('');

  useEffect(() => {
    fetchNewsletters(setNewsletters, setSuccessState);
  }, []);

  return { newsletters, successState, setNewsletters, setSuccessState };
};

export default useNewsletters;
