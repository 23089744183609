import React from 'react';
import PropTypes from 'prop-types';
import { DestructiveButtonContainer } from './DestructiveButton.styles';

const DestructiveButton = ({ functionName, span }) => {
  return (
    <DestructiveButtonContainer onClick={functionName}>
      <span>{span}</span>
    </DestructiveButtonContainer>
  );
};
DestructiveButton.propTypes = {
  functionName: PropTypes.func,
  span: PropTypes.string,
};

export default DestructiveButton;
