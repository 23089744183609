import React from 'react';
import { EachDetail, IFrameContainer } from './CourseDetails.styles';
import PropTypes from 'prop-types';

const CourseDetails = ({ c }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
  if (!c) return null;
  return (
    <>
      <EachDetail>
        <p>
          Course Name: <span>{c?.courseName}</span>
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Certificate Number: <span>{c?.certNum}</span>
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Completed On: <span>{c?.date}</span>
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Expiry Date: <span>{c?.expiryDate}</span>
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Completed:{' '}
          {c?.complete ? <span>Completed</span> : <span>Not Complete</span>}
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Score: <span>{c?.score}%</span>
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Purchased On:{' '}
          <span>{c?.purchaseDate ? formatDate(c.purchaseDate) : ''}</span>
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Paid:{' '}
          {c?.paid ? (
            <span>User has paid for course</span>
          ) : (
            <span>Course was a gift</span>
          )}
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Expiry Email Sent:{' '}
          {c?.emailSent ? (
            <span>Expiry email has been sent</span>
          ) : (
            <span>Expiry email has not been sent </span>
          )}
        </p>
      </EachDetail>
      <EachDetail>
        <p>
          Course Available:{' '}
          {c?.isCourseAvailable ? (
            <span>User has access to the course</span>
          ) : (
            <span>User does not have access to the course</span>
          )}
        </p>
      </EachDetail>
      {c?.employer && (
        <EachDetail>
          <p>
            Employer (TDG Only): <span>{c?.employer}</span>
          </p>
        </EachDetail>
      )}
      {c?.address && (
        <EachDetail>
          <p>
            Address (TDG Only): <span>{c?.address}</span>
          </p>
        </EachDetail>
      )}

      <h6 style={{ marginBottom: '1rem' }}>User Answers for the Quiz</h6>
      {c?.userAnswers && (
        <>
          {Object.entries(c?.userAnswers).length ? (
            Object.entries(c?.userAnswers).map(([key, value], i) => (
              <EachDetail key={key}>
                <p style={{ borderBottom: '1px solid var(--grey)' }}>
                  {i + 1}: <span>{value}</span>
                </p>
              </EachDetail>
            ))
          ) : (
            <p>No user answers available</p>
          )}
        </>
      )}

      <IFrameContainer>
        {c?.certificateUrl ? (
          <>
            <a
              style={{ color: 'var(--orange5)' }}
              href={c?.certificateUrl}
              alt="course url"
            >
              Course Url - Click here to download the pdf
            </a>
            <iframe
              src={`${c?.certificateUrl}#toolbar=0&view=FitH`}
              title="course certificate"
              //   width="100%"
              //   height="100%"
            />
          </>
        ) : (
          <p>There is no certificate </p>
        )}
      </IFrameContainer>
    </>
  );
};
CourseDetails.propTypes = {
  c: PropTypes.shape({
    courseName: PropTypes.string,
    userAnswers: PropTypes.object,
    address: PropTypes.string,
    certNum: PropTypes.number,
    complete: PropTypes.bool,
    date: PropTypes.string,
    expiryDate: PropTypes.string,
    emailSent: PropTypes.bool,
    paid: PropTypes.bool,
    isCourseAvailable: PropTypes.bool,
    score: PropTypes.number,
    completed: PropTypes.bool,
    purchaseDate: PropTypes.string,
    employer: PropTypes.string,
    certificateUrl: PropTypes.string,
    timstamp: PropTypes.string,
  }),
};

export default CourseDetails;
