import { InputContainer } from './FormElements.styles';
import React from 'react';
import PropTypes from 'prop-types';
import AsteriskIcon from '../../Visuals/Icons/AsteriskIcon.icon';

// prettier-ignore
// Textarea Component: title, placeholder, value, setValue
const TextAreaNested = ({
  title = '',
  requiredInput = false,
  value = '',
  name = '',
  placeholder = '',
  nestedValue,
  containerClassName = '',
  upperValue,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    nestedValue({ ...upperValue, [name]: value });
  };
  return (
    <InputContainer className={containerClassName}>
      <label>
        {title}
        {requiredInput && <AsteriskIcon />}
      </label>
      <textarea
        type="text"
        name={name}
        onChange={handleChange}
        value={value}
        required={requiredInput}
        placeholder={placeholder}
      />
    </InputContainer>
  );
};

TextAreaNested.propTypes = {
  title: PropTypes.string,
  requiredInput: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  nestedValue: PropTypes.func,
  upperValue: PropTypes.array,
  containerClassName: PropTypes.string,
};

export default TextAreaNested;
