import React from 'react';
import { AuthCheckAdmin } from '../../Utils/AuthCheck/AdminCheck.auth';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ImageContainer } from '../CreateRoutes/CreateImage/CreateImage.styles';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import GetPaymentInfo from './GetPaymentInfo.component';

const GetInfo = () => {
  return (
    <>
      <AuthCheckAdmin>
        <HelmetProvider>
          <Helmet>
            <title>Information | Yoda Safety Services</title>
            <meta
              name="Information Queries"
              content="Form for creating a image"
            />
          </Helmet>
        </HelmetProvider>
        <ImageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <BreadCrumbs
              one="Admin Home"
              oneLink="/admin"
              two={'Information'}
            />
          </div>
          <h2>Information</h2>
          {/* Get the payment info between 2 months for users */}
          <GetPaymentInfo />
        </ImageContainer>
      </AuthCheckAdmin>
    </>
  );
};

export default GetInfo;
