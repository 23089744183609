import React from 'react';

const InformationIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon icon-information"
      >
        <path
          className="primary"
          d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
        />
        <path
          className="secondary"
          d="M11 12a1 1 0 0 1 0-2h2a1 1 0 0 1 .96 1.27L12.33 17H13a1 1 0 0 1 0 2h-2a1 1 0 0 1-.96-1.27L11.67 12H11zm2-4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        />
      </svg>
    </>
  );
};

export default InformationIcon;
