import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// PUT: Set complete course for user - Protected route
async function httpCompleteCourse(course, id) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/users/complete`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ course, id }),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Set incomplete course for user - Protected route
async function httpIncompleteCourse(course, id) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/users/incomplete`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ course, id }),
    });
  } catch (err) {
    return err;
  }
}

//PUT: Disbales a users account - protected account
async function httpDisableUser(id) {
  const token = Auth.getToken();
  return await fetch(`${API_URL}/users/disable-account/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

// PUT: Activates a users account - protected route
async function httpActivateUser(id) {
  const token = Auth.getToken();
  return await fetch(`${API_URL}/users/activate-account/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

// PUT: For updating the tdg certificate
async function httpTdgCertificateDetails(course, courseId, userId, url) {
  // console.log('Sending these', course, courseId, userId, url);
  const token = Auth.getToken();
  return await fetch(`${API_URL}/users/tdg-certificate-details`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ course, courseId, userId, url }),
  });
}
// PUT: For updating the certificate
async function httpCertificateDetails(courseId, userId, url) {
  // console.log('Sending these', courseId, userId, url);
  const token = Auth.getToken();
  return await fetch(`${API_URL}/users/certificate-details`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ courseId, userId, url }),
  });
}

// PUT: For updating the certificate
async function httpUploadCertificate(courseId, userId, url) {
  // console.log('Sending these', courseId, userId, url);
  const token = Auth.getToken();
  return await fetch(`${API_URL}/users/certificate-details`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ courseId, userId, url }),
  });
}
export {
  httpActivateUser,
  httpCompleteCourse,
  httpDisableUser,
  httpIncompleteCourse,
  httpTdgCertificateDetails,
  httpCertificateDetails,
  httpUploadCertificate,
};
