import styled from 'styled-components';

export const CourseInfoTable = styled.section`
  /* border: 2px solid orange; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  div {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* border: 3px solid pink; */
  }
`;
export const TotalDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  padding-top: 1rem;
  text-align: left;
  /* border: 2px solid red; */
  div {
    /* border: 1px solid blue; */
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    width: 20%;
  }
  @media screen and (max-width: 468px) {
    div {
      width: 50%;
      /* border: 1px solid green; */
    }
  }
`;
export const PayPalButtonContainer = styled.div`
  /* border: 2px solid blue; */
  width: 50%;
  padding-top: 2rem;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 468px) {
    width: 100%;
  }
`;
