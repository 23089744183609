import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
`;
export const CookieBannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: hsla(0, 0%, 20%, 0.7);
  color: #fff;
  text-align: center;
  padding: 1rem;
  z-index: 1000;
  animation: ${slideUp} 0.5s ease-out;
  p {
    margin: 0;
    padding: 0;
  }
  button {
    min-width: 10%;
    max-width: 50%;
  }
  button:hover {
    background-color: #0056b3;
  }
  div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
`;
