import styled from 'styled-components';

export const MainContainer = styled.main`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* width: 100%; */
  /* border: 5px solid red; */
  /* overflow-y: visible; */
`;
