import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as pdfjsLib from 'pdfjs-dist';
import { ButtonContainerPresentation } from './CourseHome.styles';
import 'pdfjs-dist/web/pdf_viewer.css';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SecondaryButton from '../Components/Buttons/SecondaryButton/SecondaryButton.component';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@5.0.375/build/pdf.worker.min.mjs`;

const PDFViewer = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
  const [pdf, setPdf] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [inputPage, setInputPage] = useState('');
  const [loading, setLoading] = useState(true);

  // Load the PDF document
  useEffect(() => {
    const loadPdf = async () => {
      try {
        setLoading(true);
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
        setTotalPages(loadedPdf.numPages);
        renderPage(1, loadedPdf);
      } catch (error) {
        console.error('Error loading PDF:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPdf();
  }, [pdfUrl]);

  // Render a specific page
  const renderPage = async (pageNumber, loadedPdf = pdf) => {
    if (!loadedPdf) return;

    try {
      setLoading(true);
      const page = await loadedPdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale for better quality
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Set canvas dimensions
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Render the page
      const renderContext = {
        canvasContext: context,
        viewport,
      };
      await page.render(renderContext).promise;
    } catch (error) {
      console.error('Error rendering page:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Next and Previous buttons
  const handleNext = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      renderPage(nextPage);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      renderPage(prevPage);
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setInputPage(e.target.value);
  };

  // Handle Go to Page
  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      renderPage(pageNumber);
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '1rem' }}>
      {loading && <p>Loading presentation...</p>}
      <canvas ref={canvasRef}></canvas>
      <ButtonContainerPresentation>
        <PrimaryButtonComponent
          functionName={handlePrevious}
          disabled={currentPage === 1}
          span="Previous"
        />
        <p style={{ margin: '0 10px' }}>
          Page {currentPage} of {totalPages || '...'}
        </p>
        <PrimaryButtonComponent
          functionName={handleNext}
          disabled={currentPage === totalPages}
          span="Next"
        />
      </ButtonContainerPresentation>
      <div style={{ marginBlock: '1rem' }}>
        <input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          placeholder="Enter page number"
          style={{ padding: '12px', width: '155px', marginRight: '10px' }}
        />
        <SecondaryButton functionName={handleGoToPage} span="Select Page" />
      </div>
    </div>
  );
};

PDFViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
};

export default PDFViewer;
