import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// PUT: Disables a company - protected route
async function httpDisableCompany(id) {
  const token = Auth.getToken();

  return await fetch(`${API_URL}/companies/disable-company/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

// PUT: Activates a company - protected route
async function httpActivateCompany(id) {
  const token = Auth.getToken();

  return await fetch(`${API_URL}/companies/activate-company/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

export { httpActivateCompany, httpDisableCompany };
