import { Outlet } from 'react-router-dom';
import { MainContainer } from './base.styles';
import { Toaster } from 'react-hot-toast';
import React from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

const BasePage = React.memo(() => {
  return (
    <ErrorBoundary>
      <MainContainer>
        <Toaster position="bottom-left" reverseOrder={false} />
        <Outlet />
      </MainContainer>
    </ErrorBoundary>
  );
});

BasePage.displayName = 'BasePage';

export default BasePage;
