// import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { quizPercentage } from '../Helpers/quiz-percentage';
import { addYears, format } from 'date-fns';
import Auth from '../Helpers/auth';
import React, { useState } from 'react';
import {
  httpCompleteCourse,
  httpIncompleteCourse,
} from '../Hooks/user/user.put.hooks';
import PrimaryButton from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import PropTypes from 'prop-types';
import { GetCourseData } from './CourseData';

// Form for submitting the quiz
const QuizSubmit = ({ id, correct, length, course, answered, userAnswers }) => {
  const navigate = useNavigate();
  const [err, setErr] = useState('');
  const { data } = Auth.getProfile();

  const submitQuiz = async (e, id) => {
    e.preventDefault();
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(`quizAnswers-`)) {
        localStorage.removeItem(key);
      }
    });
    let isCompleted = quizPercentage(correct, length);
    console.log('Quiz percent', isCompleted);
    let courseData = await GetCourseData(data.id, id);
    // console.log('Course Data', courseData);
    if (isCompleted >= 85) {
      console.log('Is completed', isCompleted);
      let date = Date.now();
      const dateFormatted = format(new Date(), 'MMMM dd, yyyy');
      let updatedCourse = {
        ...courseData,
        certNum: `${course.certificatePrefix}${String(course.certificateCount).padStart(4, '0')}`,
        complete: true,
        score: isCompleted,
        date: dateFormatted,
        userAnswers,
      };
      if (course?.expiryDate) {
        let expiryDate = addYears(date, course.expiryDate);
        let expired = format(expiryDate, 'MMMM dd, yyyy');
        updatedCourse.expiryDate = expired;
        updatedCourse.expiredDateLong = expiryDate.getTime();
      } else {
        updatedCourse.expiryDate = '';
        updatedCourse.expiredDateLong = '';
      }
      // console.log('Sending', updatedCourse, data.id);
      const res = await httpCompleteCourse(updatedCourse, data.id);
      const da = await res.json();
      if (res.ok) {
        navigate(`/course/quiz/complete/${id}`);
      } else {
        setErr(da.msg);
      }
    } else {
      console.log('Is not completed', isCompleted);
      let updatedCourse = {
        ...courseData,
        userAnswers: userAnswers,
        complete: false,
        score: isCompleted,
      };
      const res = await httpIncompleteCourse(updatedCourse, data.id);
      const da = await res.json();
      if (res.ok) {
        navigate(`/course/quiz/incomplete/${id}`);
      } else {
        setErr(da.msg || 'Error updating submitting the quiz');
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {err && <p className="error">{err}</p>}
      <PrimaryButton
        functionName={(e) => submitQuiz(e, id)}
        span="Submit Quiz"
        disabled={answered === length ? false : true}
      />
      <p>
        You have answered {answered} / {length} questions
      </p>
      <span>
        If the button is not yellow you have not answered all the questions
      </span>
    </div>
  );
};
QuizSubmit.propTypes = {
  id: PropTypes.string,
  correct: PropTypes.number,
  length: PropTypes.number,
  answered: PropTypes.number,
  userAnswers: PropTypes.object,
  course: PropTypes.shape({
    certificateCount: PropTypes.number,
    certificatePrefix: PropTypes.string,
    expiryDate: PropTypes.number,
  }),
};

export default QuizSubmit;
