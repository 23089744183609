import React from 'react';
import SideContainerComponent from '../SideContainer.component';
import { MainContentContainer, MainSection } from '../OtherPages.styles';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const ContactUs = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Contact Us | Yoda Safety Services</title>
          <meta name="Contact Us" content="Contact Us yoda safety services" />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Contact Us</h2>
      </MainSection>
    </MainContentContainer>
  );
};

export default ContactUs;
