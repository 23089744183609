import { httpDeleteCompany } from '../../../Hooks/admin/admin.delete.hooks';

export const deleteCompany = async (
  id,
  setSuccessState,
  setIsOpen,
  setLoading
) => {
  setLoading(true);
  try {
    const res = await httpDeleteCompany(id);
    // const data = await res.json();
    if (res.ok) {
      setSuccessState('Company has been deleted');
    } else {
      setSuccessState('Company has not been deleted');
    }
  } catch (error) {
    setSuccessState('An error occurred while deleting the company');
    console.error('Error deleting company:', error);
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
