import React, { useState, useEffect } from 'react';
import OfficeIcon from '../../Visuals/Icons/OfficeIcon.icon';
import PropTypes from 'prop-types';
import { httpGetAllImages } from '../../Hooks/admin/admin.get.hooks';
import PrimaryButtonComponent from '../Buttons/PrimaryButton/PrimaryButton.component';

const SelectImage = ({
  title = 'Logo',
  setImage,
  image,
  containerClassName = 'formInputUnderline',
}) => {
  // console.log('Selected image', image);
  const [allImages, setAllImages] = useState([]);
  useEffect(() => {
    const GetImages = async () => {
      let res = await httpGetAllImages();
      setAllImages(res);
    };
    GetImages();
  }, []);
  return (
    <div className={containerClassName}>
      <h4>
        {title} <OfficeIcon />
      </h4>
      <span>Current Image: {image || image?.url}</span>
      <div
        style={{
          marginBlock: '1rem',
          width: '100%',
        }}
      >
        {/* <label>
          {title}
          {image && <span style={{ color: 'green' }}>{image.name}</span>}
        </label> */}
        <div
          style={{
            width: '100%',
            overflowX: 'scroll',
            gap: '0.5rem',
            // border: '1px solid blue',
            display: 'flex',
          }}
        >
          {allImages?.map((img) => {
            const isSelected = img?.url === image;
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '35%',
                  minHeight: '50%',
                  maxHeight: '50%',
                  gap: '1rem',
                  borderRadius: '25px',
                  backgroundColor: isSelected
                    ? 'var(--orange3)'
                    : 'transparent', // Highlight selected image
                  // border: '1px solid red',
                }}
                key={img?.id}
              >
                <div
                  style={{
                    // border: '1px solid orange',
                    height: '50%',
                    display: 'flex',
                    overflow: 'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={img?.url}
                    alt={img?.name}
                    style={{
                      // border: '1px solid purple',
                      flexShrink: '0',
                      minWidth: '100%',
                      minHeight: '100%',
                      borderRadius: isSelected ? '25px' : '0px',
                    }}
                  />
                </div>
                <PrimaryButtonComponent
                  functionName={(e) => {
                    e.preventDefault();
                    setImage(img?.url);
                  }}
                  span="select image"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
SelectImage.propTypes = {
  title: PropTypes.string,
  setImage: PropTypes.func,
  image: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default SelectImage;
