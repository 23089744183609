import React, { useState } from 'react';
import { PasswordOuterContianer } from '../Authentication/PasswordReset/ForgotAndReset.styles';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { httpUnsubscribeNewsletter } from '../Hooks/newsletter/newsletter.post.hooks';

const UnsubscribeFromNewsletter = () => {
  const [status, setStatus] = useState('');
  let email;
  const handleSubmit = async () => {
    try {
      let res = await httpUnsubscribeNewsletter(email);
      let data = await res.json();
      if (res.ok) {
        setStatus(data.msg || 'You have been unsubscribed');
      } else {
        setStatus(
          data.msg || 'You have not been unsubscribed, please try again'
        );
      }
    } catch (err) {
      setStatus(
        'An error has occured, you have not been unsubscribed from our newsletter'
      );
    }
  };
  return (
    <>
      <PasswordOuterContianer>
        <h2>Unsubscribe From Our Newsletter</h2>
        <span>
          By selecting unsubscribe, you will no longer recieve emails from us.
          You can resubscribe at any time
        </span>
        <PrimaryButtonComponent
          span="Unsubscribe"
          functionName={handleSubmit}
        />
        <p>{status}</p>
      </PasswordOuterContianer>
    </>
  );
};

export default UnsubscribeFromNewsletter;
