import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SideContainerComponent from '../SideContainer.component';
import {
  MainContentContainer,
  MainSection,
  ResourcesContainer,
  EachProvince,
} from '../OtherPages.styles';

const Resources = () => {
  const Links = [
    { province: 'CCOHS', link: 'https://www.ccohs.ca' },
    {
      province: 'NS WCB Regulations',
      link: 'https://www.novascotia.ca/just/regulations/regs/wocgen.htm',
    },
    {
      province: 'Alberta',
      link: 'https://www.alberta.ca/ohs-act-regulation-code#jumplinks-2',
    },
    {
      province: 'British Columbia',
      link: 'https://www.worksafebc.com/en/law-policy/occupational-health-safety/searchable-ohs-regulation/ohs-regulation/part-01-definitions',
    },
    {
      province: 'Manitoba',
      link: 'https://www.gov.mb.ca/labour/safety/pdf/whs_workplace_safety_act_and_regs.pdf',
    },
    {
      province: 'New Brunswick',
      link: 'https://www.worksafenb.ca/policy-and-legal/legal/acts-and-regulations/',
    },
    {
      province: 'Newfoundland & Labrador',
      link: 'https://www.assembly.nl.ca/legislation/sr/statutes/o03.htm',
    },
    {
      province: 'Northwest Territories & Nunavut',
      link: 'https://www.cfib-fcei.ca/en/tools-resources/occupational-health-and-safety-act-workplace-ohs-nwt',
    },
    {
      province: 'Nova Scotia',
      link: 'https://www.alberta.ca/ohs-act-regulation-code#jumplinks-2',
    },
    { province: 'Ontario', link: 'https://www.ontario.ca/laws/statute/90o01' },
    {
      province: 'Prince Edward Island',
      link: 'https://www.wcb.pe.ca/workplace/ohsactandregulations#:~:text= The%20OHS%20Act%20and%20its,safety%20principles%20for%20Island%20workplaces',
    },
    {
      province: 'Quebec',
      link: 'https://www.legisquebec.gouv.qc.ca/en/document/cs/s-2.1',
    },
    {
      province: 'Saskatchewan',
      link: 'https://publications.saskatchewan.ca/#/products/112399',
    },
    {
      province: 'Yukon',
      link: 'https://laws.yukon.ca/cms/images/LEGISLATION/PRINCIPAL/2002/2002-0159/2002-0159.pdf',
    },
  ];
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Resources | Yoda Safety Services</title>
          <meta
            name="Resources"
            content="Resources from yoda safety services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Resources</h2>
        <ResourcesContainer>
          {Links.map((link) => (
            <EachProvince key={link.province}>
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                {link.province}
              </a>
            </EachProvince>
          ))}
        </ResourcesContainer>
      </MainSection>
    </MainContentContainer>
  );
};

export default Resources;
