import React from 'react';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { useNavigate } from 'react-router-dom';
import { FormContainer } from '../AdminRoutes/CreateRoutes/CreateForm.styles';

const CoursePurchasedPage = () => {
  const navigate = useNavigate();

  return (
    <FormContainer
      style={{
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginTop: '10%',
      }}
    >
      <h3>Purchase has been complete</h3>
      <p>Thank you for purchasing this course</p>
      <PrimaryButtonComponent
        span="Go to Courses"
        functionName={() => {
          navigate('/user');
        }}
      />
    </FormContainer>
  );
};

export default CoursePurchasedPage;
