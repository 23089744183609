import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import React, { useState } from 'react';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import PropTypes from 'prop-types';
import { httpSendAccountDetails } from '../../../Hooks/admin/admin.post.hooks';
import { createUserAdmin } from '../../../Utils/Users/createUserAdmin/postUserAdmin';

// Handles the submit for creating a user
const CreateUserSubmit = ({
  user,
  setIsOpen,
  setSuccessState,
  disabled,
  successState,
}) => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newUser = {
      ...user,
      type: 'User',
      courses: [],
    };
    await createUserAdmin(newUser, setSuccessState, setIsOpen, setLoading);
    let res = await httpSendAccountDetails(user.email, user.password);
    if (res.ok) {
      setEmailSent('Client has been sent login details');
    } else {
      setEmailSent('Client has not been sent login details');
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>{successState}</p>
          <p>{emailSent}</p>
          <PrimaryButton
            functionName={(e) => handleSubmit(e)}
            span="Create User"
            disabled={!disabled}
          />
        </div>
      )}
    </>
  );
};
CreateUserSubmit.propTypes = {
  user: PropTypes.object,
  setIsOpen: PropTypes.func,
  setSuccessState: PropTypes.func,
  disabled: PropTypes.bool,
  successState: PropTypes.string,
};

export default CreateUserSubmit;
