import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Components/Modal/ModalComponent.component';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import InputText from '../Components/Form/InputText';
import { httpSignupNewsletter } from '../Hooks/newsletter/newsletter.post.hooks';

const NewsletterModal = ({ setShowEmailModal }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await httpSignupNewsletter(email.trim());
      const data = await res.json();
      if (res.ok) {
        setStatus(data.msg || 'You have been signed up for our newsletter!');
      } else {
        setStatus(data.msg || 'Failed to sign up for the newsletter');
      }
    } catch (err) {
      setStatus('An error occured. Please try again');
    }
  };
  return (
    <Modal onClose={() => setShowEmailModal(false)}>
      <h2>Sign Up for our Newsletter</h2>
      <p style={{ fontStyle: 'italic', color: '#757575' }}>
        Sign up for monthly emails about industry updates
      </p>

      <form
        onSubmit={handleSubmit}
        style={{
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          marginTop: '0.5rem',
          // border: '1px solid red',
          // alignContent: 'center',
          // justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InputText
          width="75"
          title="Email"
          placeholder="Yoda@safety.com"
          value={email}
          setValue={setEmail}
          name="email"
          requiredInput="true"
        />
        <PrimaryButtonComponent span="Sign up" />
        <span style={{ fontStyle: 'italic' }}>Unsubscribe at any time</span>
      </form>
      <p>{status}</p>
    </Modal>
  );
};
NewsletterModal.propTypes = {
  setShowEmailModal: PropTypes.func,
};

export default NewsletterModal;
