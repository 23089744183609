import styled from 'styled-components';

// prettier-ignore
export const OuterContainer = styled.main`
  display: flex;
  /* width: 100%; */
  /* min-height: 100%; */
  /* margin: 0 auto; */
  /* height: 100%; */
  /* border: 1px solid red; */
  /* overflow-y: visible; */
  @media screen and (width <= 768px) {
    flex-direction: column;
    /* border: 1px solid orange; */
    /* height: auto; */
  }
`;

export const LeftContainer = styled.aside`
  width: 30%;
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.grey6};
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  min-height: 100vh;
  /* border: 1px solid red; */
  h2 {
    color: ${({ theme }) => theme.colors.offWhite};
    text-transform: capitalize;
    font-weight: 400;
  }
  h2::after {
    width: 0%;
  }
  @media screen and (width <= 768px) {
    width: 100%;
    /* height: 16vh; */
    height: auto;
    min-height: 100%;
  }
`;
export const UserInfo = styled.div`
  border-bottom: 3px solid ${({ theme }) => theme.colors.yellow5};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.m};
  padding: 1rem 0 1rem 1rem;
  img {
    border-radius: 50%;
    width: 125px;
    height: 125px;
    box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.grey3};
  }
  @media screen and (width <= 900px) {
    width: 100%;
    /* height: 60%; */
    height: auto;
    flex-direction: row;
    img {
      width: 50px;
      height: 50px;
    }
  }
  @media screen and (width <= 600px) {
    // padding: 1rem 0 2rem 1rem;
    h2 {
      font-size: 1.4rem;
    }
    img {
      width: 35px;
      height: 35px;
    }
  }
`;
export const UserLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.xl};
  /* border: 1px solid orange; */
  @media screen and (width <= 768px) {
    width: 100%;
    /* height: 50%; */
    height: auto;
    flex-direction: row;
    margin-left: 0;
    margin-top: ${({ theme }) => theme.spacing.m};
    justify-content: start;
    overflow-y: scroll;
    gap: 1.5rem;
    a {
      padding: 0;
      margin: 0;
    }
  }
  @media screen and (width <= 425px) {
    margin-top: ${({ theme }) => theme.spacing.m};
    gap: 0.5rem;
    a {
      font-size: 0.7rem;
    }
  }
`;

export const RightContainer = styled.section`
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey};
  /* padding: ${({ theme }) => theme.spacing.m}; */
  /* overflow-y: scroll; */
  /* border: 5px solid yellow; */
  @media screen and (width <= 768px) {
    width: 100%;
    min-height: 100vh;
  }
`;

export const TopRightContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xl};
  background-color: ${({ theme }) => theme.colors.grey5};
  padding-top: ${({ theme }) => theme.spacing.s};
  padding-left: ${({ theme }) => theme.spacing.m};
  padding-right: ${({ theme }) => theme.spacing.m};
  /* overflow-x: scroll; */
  /* border: 1px solid green; */
  @media screen and (width <= 768px) {
    display: none;
    /* overflow-x: scroll; */
  }
`;

export const CourseContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  height: auto;
  /* overflow-y: scroll; */
  /* border: 5px solid blue; */
  /* display: none; */
  @media screen and (max-width: 768px) {
    height: auto;
    overflow-y: visible;
  }
`;
