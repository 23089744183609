import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PrimaryButton from '../../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { uploadToS3 } from '../../../../Quiz/Certificate/UploadCertificate';
import { generateCertBlobTdg } from '../../../../Quiz/TDGCertificate/TDGCertBlob';
import { generateCertTDG } from '../../../../Quiz/TDGCertificate/TDGCertGenerator';
import { UpdateCourse } from '../../../../Quiz/TDGCertificate/TDGUpdateCourse';

// Creates the certificate for the tdg combo of update and download
const TDGCertificate = ({
  name,
  dateCompleted,
  expiryDate,
  fullCourse,
  employer,
  address,
  //   course,
  courseId,
  userId,
  setSubmitted,
}) => {
  const [status, setStatus] = useState('');
  const createCert = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      setSubmitted(true);
      const blob = generateCertBlobTdg(
        name,
        dateCompleted,
        expiryDate,
        employer,
        address
      );
      const fileName = `${name}_TDG.pdf`;
      const res = await uploadToS3(blob, fileName);
      if (res.Location) {
        setStatus(
          'Completed uploading, cert is now being updated in the database'
        );
        let resB = await UpdateCourse(
          fullCourse,
          courseId,
          userId,
          res.Location
        );
        let data = await resB.json();
        setStatus(
          data.msg ||
            'Certificate details has completed, cert is now being created on desktop'
        );
        generateCertTDG(name, dateCompleted, expiryDate, employer, address);
      } else {
        setStatus('An error happened');
      }
    } catch (err) {
      setStatus('An error happened in the catch');
    } finally {
      setSubmitted(false);
    }
  };
  return (
    <>
      <p>{status}</p>
      <PrimaryButton
        span="Download & Upload Certificate"
        functionName={createCert}
      />
    </>
  );
};
TDGCertificate.propTypes = {
  name: PropTypes.string,
  //   course: PropTypes.string,
  dateCompleted: PropTypes.string,
  expiryDate: PropTypes.string,
  certNumber: PropTypes.string,
  fullCourse: PropTypes.object,
  employer: PropTypes.string,
  address: PropTypes.string,
  courseId: PropTypes.string,
  userId: PropTypes.string,
  setSubmitted: PropTypes.func,
};

export default TDGCertificate;
