import React, { useState } from 'react';
import LogoInput from '../../../Components/Form/LogoInput';
import PrimaryButtonComponent from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { ImageContainer } from './CreateImage.styles';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { httpCreateImage } from '../../../Hooks/admin/admin.post.hooks';
import imageCompression from 'browser-image-compression';
import DisplayAllImages from './DisplayAllImages.component';

const CreateImage = () => {
  const [image, setImage] = useState();
  const [status, setStatus] = useState('');
  // console.log('Image', image);
  const submitImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (image) {
      setStatus('Preparing data');
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedImage = await imageCompression(image, options);
        const imageName = compressedImage.name.replace(/\s+/g, '_');
        const imageFile = new File(
          [compressedImage],
          imageName.replace(/\s+/g, '_'),
          {
            type: compressedImage.type,
          }
        );
        formData.append('image', imageFile);
        formData.append('name', imageName);
        setStatus('Sending Data');
        let res = await httpCreateImage(formData);
        let data = await res.json();
        if (res.ok) {
          setStatus(data.msg || 'Image has been uploaded');
        } else {
          setStatus(data.msg || 'Image has not been uploaded');
        }
      } catch (err) {
        // console.log('ERR', err);
        setStatus('Error occured', err);
      }
    }
  };

  return (
    <AuthCheckAdmin>
      <HelmetProvider>
        <Helmet>
          <title>Create Course | Yoda Safety Services</title>
          <meta name="Create a image" content="Form for creating a image" />
        </Helmet>
      </HelmetProvider>
      <ImageContainer>
        <div style={{ marginBottom: '1.5rem' }}>
          <BreadCrumbs one="Admin Home" oneLink="/admin" two={'Create Image'} />
        </div>
        <h2>Create Image to S3 Bucket</h2>
        {/* Thumbnail Upload */}
        <form>
          <LogoInput
            title="Current Image:  "
            image={image}
            setImage={setImage}
            containerClassName="formInputUnderline"
          />
          <p style={{ paddingBottom: '2rem' }}>Status: {status}</p>
          <PrimaryButtonComponent
            functionName={submitImage}
            span="Upload Image"
          />
        </form>
        <DisplayAllImages />
      </ImageContainer>
    </AuthCheckAdmin>
  );
};

export default CreateImage;
