import React, { useEffect, useState } from 'react';
import TDGCertificate from './TDGCertificateTemplate.component';
import Auth from '../../Helpers/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { httpGetCertificateInfo } from '../../Hooks/user/user.get.hooks';
import TDGInputs from './TDGInputs.component';
import InfoCard from '../../Components/InfoCard/InfoCard.component';
import {
  TDGCertContainer,
  TDGCertForm,
  TDGButtonContainer,
} from './TDGCerts.styles';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';

const TDGCertificateHomepage = () => {
  const { data } = Auth.getProfile();
  const { id } = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [address, setAddress] = useState('');
  const [employer, setEmployer] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const FindInfo = async () => {
      let found = await httpGetCertificateInfo(data.id, id);
      setUserInfo(found);
      if (found?.courses[0]?.employer) setEmployer(found?.courses[0]?.employer);
      if (found?.courses[0]?.address) {
        setAddress(found?.courses[0]?.address);
        setSubmitted(true);
      }
    };
    FindInfo();
  }, [data.id, id]);

  const name = data?.firstName + ' ' + data?.lastName;
  const dateCompleted = userInfo?.courses[0]?.date;
  const expiryDate = userInfo?.courses[0]?.expiryDate;
  const updatedCourse = {
    ...userInfo?.courses[0],
    address: address,
    employer: employer,
  };

  return (
    <TDGCertContainer>
      <h2>TDG Certificate</h2>
      <InfoCard paragraph="This certificate cannot be used for working with another company that is not specified on this certificate " />
      <InfoCard paragraph="The TDG Certificate requires extra information before downloading. Once the information has been submitted it cannot be redone without contacting us." />
      <TDGCertForm>
        {!userInfo?.courses[0]?.address && !submitted && (
          <TDGInputs
            address={address}
            setAddress={setAddress}
            setEmployer={setEmployer}
            employer={employer}
          />
        )}
      </TDGCertForm>
      <TDGButtonContainer>
        <SecondaryButton span="HOME" functionName={() => navigate('/user')} />
        <TDGCertificate
          userId={data.id}
          courseId={id}
          name={name}
          // fullCourse={course}
          dateCompleted={dateCompleted}
          expiryDate={expiryDate}
          employer={employer}
          updatedCourse={updatedCourse}
          address={address}
          setSubmitted={setSubmitted}
        />
      </TDGButtonContainer>
    </TDGCertContainer>
  );
};

export default TDGCertificateHomepage;
