import React from 'react';
import PropTypes from 'prop-types';

const DisplayUser = ({ courseName, score }) => {
  return (
    <>
      <h1>
        Congrats! You have completed {courseName}
        <br />
        Your score: {score}
      </h1>
    </>
  );
};

DisplayUser.propTypes = {
  courseName: PropTypes.string,
  score: PropTypes.number,
};

export default DisplayUser;
