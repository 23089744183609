import styled from 'styled-components';

export const EachDetail = styled.div`
  /* border: 1px solid blue; */
  p {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grey7};
    text-transform: capitalize;
  }
  span {
    font-style: italic;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

export const IFrameContainer = styled.div`
  iframe {
    height: 625px;
    min-width: 100%;
    /* border: 1px solid red; */
  }
`;
