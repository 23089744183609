import styled from 'styled-components';

export const CourseHomepageContainer = styled.main`
  display: flex;
  width: 100%;
  /* height: 100vh; */
  /* min-height: 100%; */
  /* border: 1px solid red; */
  background-color: ${({ theme }) => theme.colors.offWhite};
  a {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
  }
  a .icon {
    height: 20px;
  }
  @media screen and (width <= 1300px) {
    flex-direction: column-reverse;
    margin: 0 auto;
    /* border: 1px solid red; */
  }
`;

export const CouseHomeLeft = styled.section`
  /* border: 5px solid blue; */
  width: 75%;
  min-height: 100vh;
  /* min-height: 100vh; */
  padding: ${({ theme }) => theme.spacing.m};
  .disclaimer {
    color: ${({ theme }) => theme.colors.grey4};
    font-style: italic;
    font-size: 1rem;
    padding-inline: ${({ theme }) => theme.spacing.m};
  }
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
  @media screen and (width <= 1300px) {
    /* border: 2px solid green; */
    //margin-inline: ${({ theme }) => theme.spacing.xl};
    width: 100%;
    /* min-height: 100vh; */
    /* height: 100%; */
    /* height: 100vh; */
  }
  @media screen and (width <= 768px) {
    .disclaimer {
      font-size: 0.8rem;
    }
  }
`;

export const CourseHomeRight = styled.aside`
  /* border: 3px solid orange; */
  width: 25%;
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.grey6};
  min-height: 100%;
  /* height: 100vh; */
  /* overflow-y: scroll; */
  p {
    color: ${({ theme }) => theme.colors.yellow2};
    font-style: italic;
    margin-block: ${({ theme }) => theme.spacing.m};
  }
  button {
    margin-left: ${({ theme }) => theme.spacing.m};
    /* border: 1px solid orange; */
    margin-top: ${({ theme }) => theme.spacing.m};
    width: 200px;
  }
  @media screen and (width <= 1300px) {
    display: none;
    /* display: none; */
    /* height: 13%; */
    /* width: ${({ dissapear }) => (dissapear ? '0%' : '100%')}; */
    /* display: ${({ dissapear }) => (dissapear ? 'none' : 'flex')}; */
    /* justify-content: center; */
    /* background-color: ${({ theme }) => theme.colors.grey6}; */
    /* border: 1px solid orange; */
  }
`;
export const TakeQuizButton = styled.div`
  @media screen and (width >= 1300px) {
    display: none;
  }
  @media screen and (width <= 1300px) {
    display: flex;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing.l};
  }
  @media screen and (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;

export const BreadcrumbsContainer = styled.div`
  /* border: 1px solid red; */
  @media screen and (width <= 1300px) {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing.m};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
  /* border: 1px solid orange; */
  // margin-top: 1rem;
  .courseStatus {
    color: ${({ theme }) => theme.colors.orange4};
    margin-bottom: ${({ theme }) => theme.spacing.m};
    font-style: italic;
  }
  @media screen and (width <= 1300px) {
    display: none;
  }
`;

// export const PresentationContainer = styled.div`
//   width: calc(100% - 30px);
//   //height: calc(100% - 30px);
//   height: 600px;
//   margin: 0 auto;
//   margin-top: ${({ theme }) => theme.spacing.m};
//   /* border: 2px solid ${({ theme }) => theme.colors.grey4}; */
//   @media screen and (width <= 600px) {
//     /* border: 2px solid purple; */
//     //width: 900px;
//     height: 400px;
//   }
// `;

export const EachSection = styled.div`
  /* border: 1px solid pink; */
  height: auto;
  max-width: 100%;
  overflow-y: scroll;
  margin-block: ${({ theme }) => theme.spacing.xl};
  a {
    color: ${({ theme }) => theme.colors.orange6};
    margin-top: 0.3rem;
  }
  a:visited {
    color: ${({ theme }) => theme.colors.grey2};
  }
  .icon-arrow {
    height: 15px;
    width: 15px;
  }
  @media screen and (max-width: 768px) {
    a {
      font-size: 0.6rem;
    }
  }
`;
export const SubsectionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  width: 100%;
  align-items: center;
  //border: 2px solid green;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  h4 {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;

export const ButtonContainerPresentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem;
  gap: ${({ theme }) => theme.spacing.xl};
  margin-top: ${({ theme }) => theme.spacing.m};
  button {
    width: 200px;
    margin-left: ${({ theme }) => theme.spacing.m};
  }
  @media screen and (max-width: 768px) {
    button {
      width: 100px;
      margin-left: ${({ theme }) => theme.spacing.xs};
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 0.5rem;
    button {
      width: 50%;
      padding: 0.5rem;
      margin-left: 0;
    }
  }
`;
