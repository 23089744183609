import React, { useState, useEffect } from 'react';
import { AuthCheckAdmin } from '../../Utils/AuthCheck/AdminCheck.auth';
import { FormContainer } from '../CreateRoutes/CreateForm.styles';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { httpGetSubscribers } from '../../Hooks/admin/admin.get.hooks';

const NewsletterSubscribersList = () => {
  const [subscribers, setSubscribers] = useState([]);
  useEffect(() => {
    const GetSubscribers = async () => {
      const data = await httpGetSubscribers();
      setSubscribers(data);
    };
    GetSubscribers();
  }, []);
  return (
    <AuthCheckAdmin>
      <HelmetProvider>
        <Helmet>
          <title>Create Company | Yoda Safety Services</title>
          <meta name="Create Company" content="Create a company form" />
        </Helmet>
      </HelmetProvider>
      <FormContainer>
        <div style={{ marginBottom: '1.5rem' }}>
          <BreadCrumbs
            one="Admin Home"
            oneLink="/admin"
            two={'Newsletter Subscribers'}
          />
        </div>
        <h2>Newsletter Subscribers List</h2>
        <main>
          {subscribers?.map((s) => {
            return (
              <div key={s._id}>
                <p>{s.email}</p>
              </div>
            );
          })}
        </main>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default NewsletterSubscribersList;
