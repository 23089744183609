import { httpCreateUser } from '../../../Hooks/admin/admin.post.hooks';

export const createUserAdmin = async (
  newUser,
  setSuccessState,
  setIsOpen,
  setLoading
) => {
  try {
    let res = await httpCreateUser(newUser);
    let data = await res.json();
    if (res.ok) {
      setSuccessState('User has been created');
    } else {
      setSuccessState(data.msg);
    }
  } catch (error) {
    setSuccessState('An error occurred while creatingthe user');
    console.error('Error activating user:', error);
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
