import { useState, useEffect } from 'react';
import { fetchManagers } from '../allManagers/getManagers';

const useManagers = (id) => {
  const [managers, setManagers] = useState([]);
  const [successState, setSuccessState] = useState('');

  useEffect(() => {
    fetchManagers(id, setManagers, setManagers);
  }, [id]);

  return { managers, successState, setManagers, setSuccessState };
};

export default useManagers;
