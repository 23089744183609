import CertificateBackground from '../../Visuals/Images/Certificate.png';
import jsPDF from 'jspdf';

// For the main button on the main page
export const handleDownload = (
  name,
  course,
  dateCompleted,
  expiryDate,
  certNumber
) => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'in',
    format: [8, 11],
  });
  doc.addImage(
    CertificateBackground,
    'PNG',
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight()
  );
  doc.setFontSize(36);
  doc.setFont('helvetica');
  doc.text(name, 5.5, 4.4, { align: 'center' });
  doc.setFontSize(20);
  doc.text(course, 5.5, 5.5, { align: 'center' });
  doc.setFontSize(12);
  doc.text(dateCompleted, 2.4, 6.3);
  doc.text(expiryDate, 6.7, 6.3);
  doc.text(certNumber, 6.7, 7.2);
  doc.save(`${name}-${course}.pdf`);
};
