import React from 'react';

const ChevronRightIcon = () => {
  return (
    <svg
      height="30px"
      width="30px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="icon icon-cheveron-right-circle icon-arrow"
    >
      <circle cx="12" cy="12" r="10" className="primaryArrow primary" />
      <path
        className="secondaryArrow secondary"
        d="M10.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"
      />
    </svg>
  );
};

export default ChevronRightIcon;
