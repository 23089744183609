import { httpDisableCompany } from '../../../Hooks/company/company.put.hooks';

export const disableCompany = async (
  id,
  setIsOpen,
  setSuccessState,
  setLoading
) => {
  try {
    let res = await httpDisableCompany(id);
    // let data = await res.json();
    if (res.ok) {
      setSuccessState('Company has been deactivated');
    } else {
      setSuccessState('Company could not be deactivated');
    }
  } catch (err) {
    setSuccessState('Company could not be deactivated');
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
