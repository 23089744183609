import styled from 'styled-components';

export const ButtonContainers = styled.div`
  /* border: 1px solid orange; */
  display: flex;
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-block: 1rem;
  button {
    width: 30%;
  }
  @media screen and (width <= 768px) {
    flex-direction: column-reverse;
    align-items: center;
    button {
      min-width: 95%;
    }
  }
`;
