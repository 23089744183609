import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const s3 = new AWS.S3();

export const uploadToS3 = (pdfBlob, fileName) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME_CERTIFICATES,
      Key: fileName,
      Body: pdfBlob,
      ContentType: 'application/pdf',
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Error uploading to S3', err);
        reject(err);
      } else {
        console.log('Successfully uploaded to S3', data);
        resolve(data);
      }
    });
  });
};
