import React from 'react';
import PropTypes from 'prop-types';
import { StyledMenu } from './BurgerMenuStyles.styles';
import { Link } from 'react-router-dom';
import CloseIcon from '../../../Visuals/Icons/CloseIcon.icon';
import Auth from '../../../Helpers/auth';

const Menu = ({ open, setOpen }) => {
  //setOpen - took it out
  // const { data } = Auth.getProfile();

  const closeMenu = () => {
    setOpen(!open);
  };
  return (
    <StyledMenu open={open}>
      <div className="topNav" open={open} onClick={() => setOpen(!open)}>
        <CloseIcon />
      </div>
      <div className="bottomNav">
        <Link to="/" onClick={closeMenu}>
          Home
        </Link>
        <Link to="/resources" onClick={closeMenu}>
          Resources
        </Link>
        <Link to="/contact-us" onClick={closeMenu}>
          Contact Us
        </Link>

        {(process.env.REACT_APP_PRODUCTION === undefined ||
          process.env.REACT_APP_PRODUCTION === 'false') && (
          <>
            <Link to="/program" onClick={closeMenu}>
              Health & Safety Program Development
            </Link>
            <Link to="/wcb-certified" onClick={closeMenu}>
              Approved By NS WCB Safety Certified
            </Link>
            <Link to="/insurance" onClick={closeMenu}>
              Insurance
            </Link>
            <Link to="/online" onClick={closeMenu}>
              Online Training
            </Link>
            <Link to="/classroom" onClick={closeMenu}>
              Classroom Training
            </Link>
          </>
        )}
        {Auth.loggedIn() && (
          <>
            <Link to="/user" onClick={closeMenu}>
              User Home
            </Link>
            {/* <Link to={`/user/settings/${data.id}`} onClick={closeMenu}>
              Settings
            </Link> */}
          </>
        )}
        {Auth.loggedIn() ? (
          <Link
            onClick={(e) => {
              e.preventDefault();
              Auth.logout();
              window.location.assign('/');
              closeMenu();
            }}
            title="Logout"
            className="subLink"
            to="/about"
          >
            Logout
          </Link>
        ) : (
          <Link to="/login" onClick={closeMenu}>
            Login
          </Link>
        )}
        {(process.env.REACT_APP_PRODUCTION === undefined ||
          process.env.REACT_APP_PRODUCTION === 'false') && (
          <>
            <Link to="/admin" onClick={closeMenu}>
              Admin
            </Link>
            <Link to="/company" onClick={closeMenu}>
              Company
            </Link>
          </>
        )}
      </div>
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Menu;
