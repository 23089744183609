import {
  CourseHomepageContainer,
  BreadcrumbsContainer,
  CourseHomeRight,
  LinksContainer,
  SubsectionContainer,
  CouseHomeLeft,
  // PresentationContainer,
  EachSection,
} from '../../TakeCourse/CourseHome.styles';
import { httpGetSingleCourse } from '../../Hooks/courses.hooks';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import PlayIcon from '../../Visuals/Icons/PlayIcon.icon';
import LinkIcon from '../../Visuals/Icons/LinkIcon.icon';
import PresentationIcon from '../../Visuals/Icons/PresentationIcon.icon';
import Loader from '../../Components/Loader/LoaderComponent.component';
import ArrowIcon from '../../Visuals/Icons/ChevronRightIcon.icon';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import PDFViewer from '../../TakeCourse/PdfViewer.component';

// Page for reviewing the course
const CourseReview = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h3>An error has occured</h3>;
  }

  return (
    <AuthCheckUser>
      <HelmetProvider>
        <Helmet>
          <title>
            {data?.courseName
              ? `${data?.courseName} Review Course `
              : 'Review Course '}{' '}
            | Yoda Safety Services
          </title>
          <meta
            name="Review Course"
            content="Review the course but cannot take the quiz again"
          />
        </Helmet>
      </HelmetProvider>
      <CourseHomepageContainer>
        <CourseHomeRight>
          <LinksContainer>
            <BreadcrumbsContainer>
              <BreadCrumbs one="Home" oneLink="/user" two={data?.courseName} />
            </BreadcrumbsContainer>
            <a href="#presentation">
              <PresentationIcon /> Presentation
            </a>
            <a href="#documentation">
              <LinkIcon />
              Documentation
            </a>
            <a href="#videos">
              <PlayIcon />
              Video Links
            </a>
            <span className="courseStatus">Course has been completed</span>
          </LinksContainer>
        </CourseHomeRight>
        <CouseHomeLeft>
          {!data ? (
            <>
              <p>{error}</p>
            </>
          ) : (
            <>
              <h2>{data?.courseName}</h2>
              <p>{data?.description}</p>
              <EachSection>
                <SubsectionContainer id="presentation">
                  <PresentationIcon />
                  <h3>Presentation</h3>
                </SubsectionContainer>
                {/* <p>Course:{course?.pdf?.publicId}</p> */}
                {/* <PresentationContainer> */}
                {/* <iframe   
                    src={`${data?.pdf}#toolbar=0`}
                    title="presentation"
                    width="100%"
                    height="100%"
                  /> */}
                <PDFViewer pdfUrl={data?.pdf} />
                {/* </PresentationContainer> */}
              </EachSection>
              <EachSection>
                <SubsectionContainer id="documentation">
                  <LinkIcon />
                  <h3>Documentation</h3>
                </SubsectionContainer>
                {data?.attachments?.map((a) => {
                  return (
                    <a
                      key={a}
                      href={a}
                      target="_blank"
                      rel="noreferrer"
                      alt={'Documentation link to ' + a}
                    >
                      <ArrowIcon />
                      {a.slice(52)}
                    </a>
                  );
                })}
                <ul>
                  {/* The as may need to be change to Link */}
                  {data?.documentation?.map((l) => {
                    return (
                      <li key={l}>
                        <a
                          href={l}
                          target="_blank"
                          rel="noreferrer"
                          alt={'Documentation link to' + l}
                        >
                          {l}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </EachSection>
              <EachSection>
                <SubsectionContainer id="videos">
                  <PlayIcon />
                  <h3>Videos</h3>
                </SubsectionContainer>
                <ul>
                  {data?.videoLinks?.map((l) => {
                    return (
                      <li key={l}>
                        <a
                          href={l}
                          onChange={(e) => e.target}
                          target="_blank"
                          rel="noreferrer"
                          alt={'Video link to ' + l}
                        >
                          {l}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </EachSection>
            </>
          )}
        </CouseHomeLeft>
      </CourseHomepageContainer>
    </AuthCheckUser>
  );
};

export default CourseReview;
