import { MainContentContainer, MainSection } from '../OtherPages.styles';
// import Images from '../../Visuals/Images/large.jpg';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SideContainerComponent from '../SideContainer.component';

const ClassroomTraining = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Classroom Training | Yoda Safety Services</title>
          <meta
            name="Classroom Training"
            content="In person training offered from yoda safety services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Classroom Training</h2>
        <p>
          {' '}
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea
          ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci
          repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente
          eveniet aut expedita praesentium dolor.
        </p>
      </MainSection>
    </MainContentContainer>
  );
};

export default ClassroomTraining;
