import DisplayAnswersEach from './DisplayAnswersComponent.component';
import { EachQuestionContainer } from '../QuizPageStyles.styles';
import React from 'react';
import PropTypes from 'prop-types';

// Displays each answer
const DisplayAnswersComponent = ({
  question = {},
  idx = 0,
  userAnswers = {},
  key = '',
}) => {
  console.log('Incoming to dispaly answers', question);
  return (
    <>
      <EachQuestionContainer key={key}>
        <div key={key}>
          <h3 style={{ paddingBottom: '0.5rem' }}>
            {idx + 1}. {question?.question}
          </h3>
          {question?.answer1 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer1}
              answeredByUser={userAnswers[question?.id] === question?.answer1}
            />
          )}
          {question?.answer2 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer2}
              answeredByUser={userAnswers[question?.id] === question?.answer2}
            />
          )}
          {question?.answer3 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer3}
              answeredByUser={userAnswers[question?.id] === question?.answer3}
            />
          )}
          {question?.answer4 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer4}
              answeredByUser={userAnswers[question?.id] === question?.answer4}
            />
          )}
          {question?.answer5 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer5}
              answeredByUser={userAnswers[question?.id] === question?.answer5}
            />
          )}
          {question?.answer6 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer6}
              answeredByUser={userAnswers[question?.id] === question?.answer6}
            />
          )}
          {question?.answer7 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer7}
              answeredByUser={userAnswers[question?.id] === question?.answer7}
            />
          )}
          {question?.answer8 && (
            <DisplayAnswersEach
              corrrectAnswer={question?.correctAnswer}
              answer={question?.answer8}
              answeredByUser={userAnswers[question?.id] === question?.answer8}
            />
          )}
        </div>
      </EachQuestionContainer>
    </>
  );
};
DisplayAnswersComponent.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    question: PropTypes.string,
    answer1: PropTypes.string,
    answer2: PropTypes.string,
    answer3: PropTypes.string,
    answer4: PropTypes.string,
    answer5: PropTypes.string,
    answer6: PropTypes.string,
    answer7: PropTypes.string,
    answer8: PropTypes.string,
    correctAnswer: PropTypes.string,
  }),
  idx: PropTypes.number,
  userAnswers: PropTypes.object,
  key: PropTypes.string,
};

export default DisplayAnswersComponent;
