import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// PUT: Edits a course - protected route
async function httpEditCourse(courseId, course) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/edit-course/${courseId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(course),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Edits a company - protected route
async function httpEditCompany(companyId, company) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/edit-company/${companyId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(company),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Edits a user - protected route
async function httpEditUser(userId, user) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/edit-user/${userId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(user),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Edits a course - protected route
async function httpGetUsersByDateRange(startDate, endDate) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/users-by-date-range`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ startDate, endDate }),
    });
  } catch (err) {
    return err;
  }
}

export {
  httpEditCompany,
  httpEditCourse,
  httpEditUser,
  httpGetUsersByDateRange,
};
