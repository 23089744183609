import React from 'react';
import PropTypes from 'prop-types';
import { TrueFalseContainer } from '../Quiz.styles';
import InputTextNested from '../../InputTextNested';

const Option2Questions = ({ quest2, setQuest2 }) => {
  return (
    <>
      <TrueFalseContainer>
        <InputTextNested
          title="Question"
          value={quest2.question}
          name="question"
          nestedValue={setQuest2}
          upperValue={quest2}
        />
        <p>1. True</p>
        <p>2. False</p>
        <div style={{ display: 'flex', gap: '3rem', width: '100%' }}>
          <p>Correct Answer: {quest2.correctAnswer}</p>
          <select
            multiple={false}
            value={quest2.correctAnswer || ''}
            name="correctAnswer"
            onChange={(e) =>
              setQuest2({ ...quest2, [e.target.name]: e.target.value })
            }
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
      </TrueFalseContainer>
    </>
  );
};
Option2Questions.propTypes = {
  quest2: PropTypes.shape({
    question: PropTypes.string,
    correctAnswer: PropTypes.string,
  }),
  setQuest2: PropTypes.func,
};

export default Option2Questions;
