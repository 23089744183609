import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { httpCaptureOrder, httpCreateOrder } from '../Hooks/orders/order.hooks';
import CourseDetails from './CourseDetails.component';
import { FormContainer } from '../AdminRoutes/CreateRoutes/CreateForm.styles';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import Auth from '../Helpers/auth';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { PayPalButtonContainer } from './Course.styles';

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}
// prettier-ignore
Message.propTypes = {
  content: PropTypes.string,
};

function BuyPage() {
  const { id } = useParams();
  const [course, setCourse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState('');
  const [message, setMessage] = useState('');
  const { data } = Auth.getProfile();
  const userId = data.id;
  const navigate = useNavigate();
  const taxRate = 0.15;
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const FetchCourse = async () => {
      const data = await httpGetSingleCourse(id);
      if (!data) setErr('Error getting the course');
      setCourse(data);
      setTaxAmount(data.price * taxRate);
      let taxAmount = data.price * taxRate;
      setTotalAmount(data.price + taxAmount);
      setLoading(false);
    };
    FetchCourse();
  }, [id]);

  const initialOptions = {
    'client-id': process.env.REACT_APP_PAY_PAL_CLIENT_ID,
    'enable-funding': 'venmo',
    'disable-funding': '',
    currency: 'CAD',
    components: 'buttons',
  };

  return (
    <AuthCheckUser>
      <HelmetProvider>
        <Helmet>
          <title>
            {course?.courseName ? `${course?.courseName}` : 'Course '} | Yoda
            Safety Services
          </title>
          <meta name="About Course" content="Course details" />
        </Helmet>
      </HelmetProvider>

      <FormContainer
        style={{
          alignContent: 'center',
          display: 'flex',
        }}
      >
        <BreadCrumbs one="courses" oneLink="/online" two={course?.courseName} />
        <h2>Checkout</h2>
        <p>{err}</p>
        <p>{message} </p>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <CourseDetails
            course={course}
            taxAmount={taxAmount}
            totalAmount={totalAmount}
          />
        )}
        <PayPalButtonContainer>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              style={{
                shape: 'pill',
                layout: 'vertical',
                color: 'gold',
                label: 'pay',
              }}
              createOrder={async () => {
                try {
                  const cartOuter = { cart: { id: course._id, quantity: 1 } };
                  // console.log('THE CART', cartOuter);
                  const response = await httpCreateOrder(cartOuter);
                  const orderData = await response.json();

                  if (orderData.id) {
                    // console.log('The orderData id', orderData.id);
                    return orderData.id;
                  } else {
                    const errorDetail = orderData?.details?.[0];
                    const errorMessage = errorDetail
                      ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                      : JSON.stringify(orderData);
                    // console.log('Error creating order:', errorMessage);
                    throw new Error(errorMessage);
                  }
                } catch (error) {
                  // console.error(error);
                  setMessage(`Could not initiate PayPal Checkout...${error}`);
                }
              }}
              // actions was beside data took it out
              onApprove={async (data) => {
                try {
                  const response = await httpCaptureOrder(
                    data.orderID,
                    userId,
                    id
                  );
                  const orderData = await response.json();
                  // console.log('Order Data', orderData.status);
                  // Three cases to handle:
                  //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  //   (2) Other non-recoverable errors -> Show a failure message
                  //   (3) Successful transaction -> Show confirmation or thank you message
                  if (orderData.status === 'COMPLETED') {
                    // Redirect to the "Course Purchased" page
                    navigate(`/course-purchased/${id}`);
                  } else {
                    setMessage(`Transaction failed: ${orderData.status}`);
                    throw new Error('Transaction not completed.');
                  }
                } catch (error) {
                  console.error(error);
                  setMessage(
                    `Sorry, your transaction could not be processed...${error}`
                  );
                }
              }}
            />
          </PayPalScriptProvider>
        </PayPalButtonContainer>
        {/* </div> */}
        <Message content={message} />
        <p style={{ textAlign: 'center' }}>
          Need Help? Call{' '}
          <span style={{ color: 'var(--grey3)' }}>1-902-478-1972 </span>or email
          us at{' '}
          <span style={{ color: 'var(--grey3)' }}>
            yodasafetyservices1@outlook.com
          </span>
        </p>
      </FormContainer>
    </AuthCheckUser>
  );
}

export default BuyPage;
