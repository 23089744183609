import Menu from './BurgerMenuComponent.component';
import { useOnClickOutside } from './hook';
import React, { useState, useRef } from 'react';
import { OuterBurgerDiv } from './BurgerMenuStyles.styles';
import Burger from './BurgerComponent.component';

const OuterBurger = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  return (
    <OuterBurgerDiv ref={node}>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </OuterBurgerDiv>
  );
};

export default OuterBurger;
