import React, { useState, useEffect } from 'react';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import { useParams } from 'react-router-dom';
import EachQuestion from './EachQuestion/EachQuestionComponent.component';
import {
  QuizContainer,
  ButtonContainer,
  CourseInfoContainer,
  ButtonContainerFull,
} from './QuizPageStyles.styles';
import QuizSubmit from './QuizSubmitComponent.component';
import { useQuery } from '@tanstack/react-query';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import Loader from '../Components/Loader/LoaderComponent.component';
import {
  CourseHomeRight,
  CouseHomeLeft,
} from '../TakeCourse/CourseHome.styles';
import SidebarQuestions from './SidebarQuestions.component';
import InfoCard from '../Components/InfoCard/InfoCard.component';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import PageScroller from '../Components/PageScroller/PageScroller.component';

// prettier-ignore
// Component for doing the quiz
const QuizComponent = () => {
  const { id } = useParams();
  const [answered, setAnswered] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [userAnswers, setUserAnswers] = useState({});

  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  const calculateCorrectAnswers = (userAnswers, quizData) => {
    let correctCount = 0;
    quizData.forEach((question) => {
      if (userAnswers[question.id] === question.correctAnswer) {
        correctCount++;
      }
    });
    return correctCount;
  };

  const quizLength = data?.quiz.length;

  useEffect(() => {
    const savedAnswers = localStorage.getItem(`quizAnswers-${id}`);
    if (savedAnswers) {
      const parsedAnswers = JSON.parse(savedAnswers);
      setUserAnswers(parsedAnswers);
      const correctCount = calculateCorrectAnswers(parsedAnswers, data?.quiz || []);
      setCorrect(correctCount);
    }
  }, [id, data?.courseName, data?.quiz]);

  useEffect(() => {
    if (Object.keys(userAnswers).length > 0) {
      localStorage.setItem(`quizAnswers-${id}`, JSON.stringify(userAnswers));
    }
  }, [userAnswers, id, data?.courseName]);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h3>An error has occured</h3>;
  }

  return (
    <AuthCheckUser>
      <HelmetProvider>
      <Helmet>
        <title> {data?.courseName ?  `${data?.courseName} Quiz ` : 'Quiz '}| Yoda Safety Services</title>
        <meta name="Quiz" content="Take the courses quiz" />
      </Helmet>
      </HelmetProvider>
      <QuizContainer>
        <CourseHomeRight dissapear={true}>
          <BreadCrumbs
            one={data?.courseName}
            oneLink={`/course/home/${data?._id}`}
            two="Quiz"
          />
          <p>
            Questions Answered: {answered} / {quizLength} 
          </p>
          <SidebarQuestions questions={data?.quiz} answeredQuestions={answeredQuestions}/>
          <ButtonContainerFull>
            <QuizSubmit
              id={data?._id}
              correct={correct}
              length={quizLength}
              courseName={data?.courseName}
              course={data}
              answered={answered}
              userAnswers={userAnswers}
            />
          </ButtonContainerFull>
        </CourseHomeRight>
        <CouseHomeLeft>
          <CourseInfoContainer>
            {/* <section> */}
            <h2>{data?.courseName} Quiz</h2>
          </CourseInfoContainer>
            {/* </section> */}
            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
              <InfoCard paragraph=' To complete the course select one of the given answers for
                multiple choice or true or false. Once you have answered all the
                questions click on the Submit Quiz button. If you pass the quiz
                you will recieve a certificate of completion. If you do not pass
                the quiz you can retake the quiz until you achieve the passing
                score. If you need to leave the page for any reason your quiz answers will be saved.' span=' You must have a score of 85% or higher to pass this course.'/>
                
            </div>
            <p className="disclaimer">
          Disclaimer:

Although every effort is made to ensure the accuracy, currency and completeness of the information, We do not guarantee, warrant, represent or undertake that the information provided is correct, accurate or current. We are not liable for any loss, claim, or demand arising directly or indirectly from any use or reliance upon the information.
          </p>

          {data.quiz?.map((q, i) => {
            return (
              <EachQuestion
                key={q.id}
                i={i}
                q={q}
                userAnswers={userAnswers}
                setCorrect={setCorrect}
                setAnswered={setAnswered}
                correct={correct}
                setAnsweredQuestions={setAnsweredQuestions}
                answeredQuestions={answeredQuestions}
                setUserAnswers={setUserAnswers}
              />
            );
          })}
          {/* <div style={{border: '1px solid red', display: 'flex', flexDirection: 'column'}}> */}
          <PageScroller />
      {/* </div> */}
          <ButtonContainer>
            <QuizSubmit
              id={data?._id}
              correct={correct}
              length={quizLength}
              courseName={data?.courseName}
              course={data}
              answered={answered}
              userAnswers={userAnswers}
            />
          </ButtonContainer>
        </CouseHomeLeft>
      </QuizContainer>
    </AuthCheckUser>
  );
};

export default QuizComponent;
