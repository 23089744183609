import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DestructiveButton from '../../../Components/Buttons/DestructiveButton/DestructiveButton.component';
import { httpDeleteNewsletter } from '../../../Hooks/newsletter/newsletter.put.hooks';

const NewsletterEditDelete = ({ id }) => {
  const [status, setStatus] = useState('');
  const handleSubmit = async (e) => {
    e.stopPropagation();
    try {
      setStatus('Deleting Newsletter...', id);
      let res = await httpDeleteNewsletter(id);
      let data = await res.json();
      // console.log('Res', res, 'Data', data);
      if (res.ok) {
        setStatus(data.msg || 'Newsletter deleted successfully');
      } else {
        setStatus(data.msg || 'There was an error');
      }
    } catch (err) {
      console.log(err);
      setStatus('There was an error in the backend');
    }
  };
  return (
    <>
      <p>{status}</p>
      <DestructiveButton functionName={handleSubmit} span="Delete Newsletter" />
    </>
  );
};
NewsletterEditDelete.propTypes = {
  id: PropTypes.string,
};
export default NewsletterEditDelete;
