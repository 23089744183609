import React, { useState } from 'react';
import LogoutIcon from '../../Visuals/Icons/LogoutIcon.icon';
import {
  LeftAdmin,
  TopLeftAdmin,
  BottomLeftAdmin,
  AdminContainer,
  RightAdmin,
  AdminTable,
} from '../../AdminRoutes/Admin/AdminStyles.styles';
import { Link } from 'react-router-dom';
import UserIcon from '../../Visuals/Icons/UserIcon.icon';
import Auth from '../../Helpers/auth';
import CompanyTabsComponent from '../CompanyTabs/CompanyTabs.component';

// Main Frame for the company home
import { Helmet, HelmetProvider } from 'react-helmet-async';
const CompanyHome = () => {
  const [showEmployees, setShowEmployees] = useState(true);
  const [showForms, setShowForms] = useState(false);

  return (
    // <AuthCheckManager>
    <>
      <HelmetProvider>
        <Helmet>
          <title>Company Home | Yoda Safety Services</title>
          <meta name="Company Homepage" content="Companies homepage" />
        </Helmet>
      </HelmetProvider>
      <AdminContainer>
        <LeftAdmin>
          <TopLeftAdmin>
            {/* Insert Company Name */}
            <h2>Company Dashboard</h2>
          </TopLeftAdmin>
          <BottomLeftAdmin>
            {/* Create user */}
            <Link
              className="subLink"
              to="/admin/create-employee"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <UserIcon />
              Create Employee
            </Link>
            {/* Logout */}
            <Link
              onClick={(e) => {
                e.preventDefault();
                Auth.logout();
                window.location.assign('/');
              }}
              title="Logout"
              className="subLink"
              to="/about"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <LogoutIcon />
              Logout
            </Link>
          </BottomLeftAdmin>
        </LeftAdmin>
        <RightAdmin>
          <CompanyTabsComponent
            showEmployees={showEmployees}
            setShowEmployees={setShowEmployees}
            showForms={showForms}
            setShowForms={setShowForms}
          />
        </RightAdmin>
        <AdminTable>
          {/* Put in the <EmployeeTab /> etc */}
          {showEmployees && <h1>Employees</h1>}
          {showForms && <h1>Forms</h1>}
        </AdminTable>
      </AdminContainer>
    </>
    // {/* </AuthCheckManager> */}
  );
};

export default CompanyHome;
