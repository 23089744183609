const API_URL = process.env.REACT_APP_BACKEND_URL;
import Auth from '../../Helpers/auth';

// POST: Creates a Manager or User for a company - protected route
async function httpSignupNewsletter(email) {
  return await fetch(`${API_URL}/newsletters/sign-up`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
}

// POST: Unsubscribe from newsletter
async function httpUnsubscribeNewsletter(email) {
  return await fetch(`${API_URL}/newsletters/unsubscribe`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
}
// POST: Create a newsletter
async function httpCreateNewsletter(newsletter) {
  const token = Auth.getToken();
  return await fetch(`${API_URL}/newsletters/create`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ newsletter }),
  });
}

// POST: Sends the sample email to me
async function httpSampleNewsletter(id) {
  const token = Auth.getToken();
  return await fetch(`${API_URL}/newsletters/sample-newsletter`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ id }),
  });
}

// POST: Sends the email out to everyone
async function httpSendNewsletter(id) {
  const token = Auth.getToken();
  return await fetch(`${API_URL}/newsletters/send-newsletter`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({ id }),
  });
}

export {
  httpSignupNewsletter,
  httpUnsubscribeNewsletter,
  httpCreateNewsletter,
  httpSampleNewsletter,
  httpSendNewsletter,
};
