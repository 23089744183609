import { AdminTabs } from './AdminStyles.styles';
import PropTypes from 'prop-types';
import React from 'react';
import TabButton from '../../Components/Buttons/TabButton/TabButton.component';
// Tabs for selecting companies/courses/users on the admin homepage
// prettier-ignore
const AdminTabsComponent = ({
  showCompanies,
  showCourses,
  setShowCompanies,
  setShowCourses,
  setShowUsers,
  showUsers,
  setShowNewsletters,
  showNewsletters,
}) => {
  const handleTabClick = (tab) => {
    setShowCompanies(tab === 'companies');
    setShowCourses(tab === 'courses');
    setShowUsers(tab === 'users');
    setShowNewsletters(tab === 'newsletters');
  };

  return (
    <AdminTabs>
      {/* Companies */}
      <TabButton 
        active={showCompanies}
        functionName={() => handleTabClick('companies')}
        span="companies"
      />
      
      {/* Users */}
      <TabButton 
      active={showUsers}
      functionName={() => handleTabClick('users')}
      span="users"
      />
     
      {/* Courses */}
      <TabButton 
      active={showCourses}
      functionName={() => handleTabClick('courses')}
      span="courses"
      />

       {/* Newsletters */}
       <TabButton 
      active={showNewsletters}
      functionName={() => handleTabClick('newsletters')}
      span="newsletters"
      />
    </AdminTabs>
  );
};

AdminTabsComponent.propTypes = {
  showCompanies: PropTypes.bool,
  showCourses: PropTypes.bool,
  setShowCompanies: PropTypes.func,
  setShowCourses: PropTypes.func,
  setShowUsers: PropTypes.func,
  showUsers: PropTypes.bool,
  setShowNewsletters: PropTypes.func,
  showNewsletters: PropTypes.bool,
};

export default AdminTabsComponent;
