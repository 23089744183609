import { InputContainer } from './FormElements.styles';
import React from 'react';
import PropTypes from 'prop-types';
import AsteriskIcon from '../../Visuals/Icons/AsteriskIcon.icon';

const InputText = ({
  title = '',
  placeholder = '',
  value = '',
  setValue,
  name = '',
  className = '',
  containerClassName = '',
  detail = '',
  requiredInput = false,
  width = 100,
}) => {
  const changed = (e) => {
    setValue(e.target.value);
  };
  return (
    <InputContainer
      style={{ width: `${width}%` }}
      className={containerClassName}
    >
      <label className={className}>
        {title} {requiredInput && <AsteriskIcon />}{' '}
        <span className="detail">{detail}</span>
      </label>
      <input
        type="text"
        name={name}
        onChange={changed}
        value={value}
        required={requiredInput}
        placeholder={placeholder}
      />
    </InputContainer>
  );
};

InputText.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  detail: PropTypes.string,
  requiredInput: PropTypes.bool,
  width: PropTypes.number,
};

export default InputText;
