import styled from 'styled-components';

export const SingleCourseContainer = styled.main`
  min-height: 100vh;
  width: 100%;
  overflow: scroll;
`;
export const BreadcrumbContainer = styled.section`
  margin-left: ${({ theme }) => theme.spacing.l};
  margin-top: ${({ theme }) => theme.spacing.m};
`;
export const SingleCardContainer = styled.section`
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: ${({ theme }) => theme.spacing.m};
  height: 90vh;
  @media screen and (width <= 1500px) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing.xs};
    padding-bottom: ${({ theme }) => theme.spacing.m};
  }
`;

export const SingleTop = styled.section`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.l};
  @media screen and (width <= 1200px) {
    flex-direction: column;
  }
`;

export const SingleCourseImage = styled.aside`
  width: 40%;
  img {
    height: auto;
    max-height: 600px;
    width: 500px;
    width: 100%;
    box-shadow: ${({ theme }) => theme.shadows.shadow3};
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  @media screen and (width <= 1200px) {
    width: 100%;
  }
`;

export const SingleCourseInfo = styled.aside`
  width: 60%;
  /* h1 {
    color: ${({ theme }) => theme.colors.grey5};
    text-transform: uppercase;
    letter-spacing: ${({ theme }) => theme.letterSpacing.courseTitles};
  }
  h2 {
    color: ${({ theme }) => theme.colors.grey4};
  }
  h2::after {
    width: 0;
  } */
  h4 {
    color: ${({ theme }) => theme.colors.grey5};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }

  span,
  p,
  li {
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 1.2rem;
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
  }
  p,
  ul {
    padding-bottom: ${({ theme }) => theme.spacing.xl};
  }
  button span {
    color: ${({ theme }) => theme.colors.grey5};
  }
  .industries {
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.m};
    /* border: 1px solid orange; */
  }
  .industries ul {
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid green; */
    gap: ${({ theme }) => theme.spacing.xs};
  }
  .industry-item {
    width: calc(50% - 4rem);
    /* border: 1px solid red; */
  }
  .courseTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 2px solid ${({ theme }) => theme.colors.grey}; */
    padding-bottom: ${({ theme }) => theme.spacing.xs};
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
  .courseMiddle p {
    display: flex;
    gap: ${({ theme }) => theme.spacing.s};
    align-items: center;
  }
  .courseInfo {
    display: flex;
    gap: ${({ theme }) => theme.spacing.s};
  }

  @media screen and (width <= 1200px) {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;
export const SingleBottom = styled.section`
  //border: 1px solid green;
  margin-left: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.xl};
  width: 90%;
  h3 {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
    margin-bottom: ${({ theme }) => theme.spacing.m};
    color: ${({ theme }) => theme.colors.grey5};
  }
  p {
    margin-top: ${({ theme }) => theme.spacing.m};
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
`;

export const SingleButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: ${({ theme }) => theme.spacing.xxxl};
  //border: 1px solid green;
`;
