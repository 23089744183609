import React from 'react';
import TertiaryButton from '../Components/Buttons/TertiaryButton/TertiaryButton.component';
import { OptionsContainer, SwitchPage } from './AuthStyles.styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const LoginOptions = ({ setPage }) => {
  const navigate = useNavigate();

  const forgotPassword = (e) => {
    e.preventDefault();
    navigate('/login/forgot-password');
  };
  const switchPage = (e) => {
    e.preventDefault();
    setPage(false);
  };

  return (
    <OptionsContainer>
      {(process.env.REACT_APP_PRODUCTION === undefined ||
        process.env.REACT_APP_PRODUCTION === 'false') && (
        <SwitchPage>
          <p> Dont have an account?</p>
          <TertiaryButton functionName={switchPage} span="Sign Up" />
        </SwitchPage>
      )}
      <SwitchPage>
        <p>Forgot your password?</p>
        <TertiaryButton functionName={forgotPassword} span="reset password" />
      </SwitchPage>
    </OptionsContainer>
  );
};
LoginOptions.propTypes = {
  setPage: PropTypes.func,
};

export default LoginOptions;
