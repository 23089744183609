import { httpGetUser } from '../Hooks/user/user.get.hooks';

export const GetCourseData = async (userId, courseId) => {
  try {
    const data = await httpGetUser(userId);
    const courseData = await data?.courses.filter(
      (course) => course.courseId === courseId
    )[0];
    return courseData;
  } catch (error) {
    console.error('An error occurred fetching the purchase date', error);
  }
};
