import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { httpDeleteUserCourse } from '../../../../Hooks/admin/admin.delete.hooks';
import DestructiveButton from '../../../../Components/Buttons/DestructiveButton/DestructiveButton.component';

const DeleteCourseFromUser = ({ userId, courseId }) => {
  console.log('Uesr and course', userId, courseId);
  const [status, setStatus] = useState('');

  const handleDelete = async (e) => {
    e.preventDefault();
    const res = await httpDeleteUserCourse(userId, courseId);
    if (res.ok) {
      setStatus('Course has been removed from the user');
    } else {
      setStatus('Error removing course from user');
    }
  };
  return (
    <div>
      <p>{status}</p>
      <DestructiveButton
        span="Remove course from user"
        functionName={handleDelete}
      />
    </div>
  );
};
DeleteCourseFromUser.propTypes = {
  userId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
};

export default DeleteCourseFromUser;
