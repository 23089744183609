import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import { FormContainer } from '../../CreateRoutes/CreateForm.styles';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { useParams } from 'react-router-dom';
import { httpGetSingleNewsletter } from '../../../Hooks/newsletter/newsletter.get.hooks';
import InputTextNested from '../../../Components/Form/InputTextNested';
import NewsletterEditSubmit from './NewsSubmit.component';
import TextAreaNested from '../../../Components/Form/TextAreaNexted';
import NewsletterSampleEmail from './NewsSampleEmail.component';
import NewsletterEmail from './NewsSendEmail.component';
import NewsletterEditDelete from './NewsDelete.component';

const EditNewsletter = () => {
  const { id } = useParams();
  const [news, setNews] = useState([]);

  useEffect(() => {
    const GetNews = async () => {
      let res = await httpGetSingleNewsletter(id);
      setNews(res);
    };
    GetNews();
  }, [id]);

  return (
    <AuthCheckAdmin>
      <HelmetProvider>
        <Helmet>
          <title>Edit Newsletter | Yoda Safety Services</title>
          <meta name="Edit Newsletter" content="Edit a newsletter form" />
        </Helmet>
      </HelmetProvider>
      <FormContainer>
        <div style={{ marginBottom: '1.5rem' }}>
          <BreadCrumbs
            one="Admin Home"
            oneLink="/admin"
            two={'Edit Newsletter'}
          />
        </div>
        <h2>Edit Newsletter</h2>
        {news?.sentOut ? (
          <p>Newsletter has been sent out</p>
        ) : (
          <p>Newsletter has not been sent out </p>
        )}
        <form>
          <InputTextNested
            title="Newsletter Title"
            name="newsletterTitle"
            value={news?.newsletterTitle}
            nestedValue={setNews}
            upperValue={news}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Month"
            name="month"
            value={news?.month}
            nestedValue={setNews}
            upperValue={news}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Title 1"
            name="title1"
            value={news?.title1}
            nestedValue={setNews}
            upperValue={news}
            containerClassName="formInputUnderline"
          />
          <TextAreaNested
            title="Content 1"
            name="content1"
            value={news?.content1}
            nestedValue={setNews}
            upperValue={news}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Title 2"
            name="title2"
            value={news?.title2}
            nestedValue={setNews}
            upperValue={news}
            containerClassName="formInputUnderline"
          />
          <TextAreaNested
            title="Content 2"
            name="content2"
            value={news?.content2}
            nestedValue={setNews}
            upperValue={news}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Title 3"
            name="title3"
            nestedValue={setNews}
            upperValue={news}
            value={news?.title3}
            containerClassName="formInputUnderline"
          />

          <TextAreaNested
            title="Content 3"
            name="content3"
            nestedValue={setNews}
            upperValue={news}
            value={news?.content3}
            containerClassName="formInputUnderline"
          />
        </form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <NewsletterEditDelete id={id} />
          <NewsletterEmail id={id} />
          <NewsletterSampleEmail id={id} />
          <NewsletterEditSubmit id={id} newsletter={news} />
        </div>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default EditNewsletter;
