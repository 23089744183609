import { httpGetAllCourses } from '../../../Hooks/courses.hooks';

export const fetchCourses = async (id, setCourses, setSuccess) => {
  try {
    const found = await httpGetAllCourses(id);
    if (found) {
      setCourses(found);
    } else {
      setSuccess('No courses found');
    }
  } catch (error) {
    setSuccess('An error occurred while fetching the courses');
    console.error('Error fetching courses:', error);
  }
};
