import React from 'react';
import PropTypes from 'prop-types';
import { CourseInfoTable, TotalDiv } from './Course.styles';
const CourseDetails = ({ course, totalAmount = 0, taxAmount = 0 }) => {
  return (
    <>
      <CourseInfoTable>
        <div>
          {/* Header for this to be course */}
          <h3 style={{ paddingBottom: '0.5rem' }}>{course.courseName}</h3>
          {/* This in italic underneath the course name */}
          <span style={{ fontStyle: 'italic' }}>
            Expires in {course.expiryDate} years
          </span>
        </div>
        <div>
          <p>${course.price}</p>
        </div>
      </CourseInfoTable>
      <TotalDiv>
        <div>
          <p>Course Price</p>
          <span>${course.price}</span>
        </div>
        <div>
          <p>Tax (15%) </p>
          <span>${taxAmount}</span>
        </div>
        <div>
          <p>Total </p>
          <span>${totalAmount}</span>
        </div>
      </TotalDiv>
    </>
  );
};
CourseDetails.propTypes = {
  course: PropTypes.object,
  totalAmount: PropTypes.number,
  taxAmount: PropTypes.number,
};

export default CourseDetails;
