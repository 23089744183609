import {
  TopRightContainer,
  CourseContainer,
  OuterContainer,
  LeftContainer,
  RightContainer,
} from './UserHomeStyles.styles';
import React, { useState, Suspense } from 'react';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import Auth from '../../Helpers/auth';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Lazy-load components
const UserCourses = React.lazy(
  () => import('./UserCoursesComponent.component')
);
const UserMenu = React.lazy(() => import('./UserMenuComponent.component'));
const TabButton = React.lazy(
  () => import('../../Components/Buttons/TabButton/TabButton.component')
);
// const Loader = React.lazy(
//   () => import('../../Components/Loader/LoaderComponent.component')
// );

// The base page for the user's home
const UserHome = () => {
  const { data } = Auth.getProfile();

  const [showAllCourses, setAllCourses] = useState(true);
  const [showCompleteCourses, setShowCompleteCourses] = useState(false);
  const [showIncompleteCourses, setShowIncompleteCourses] = useState(false);
  const [chosen, setChosen] = useState('AllCourses');
  console.log(chosen, UserCourses);

  return (
    <AuthCheckUser>
      <>
        <HelmetProvider>
          <Helmet>
            <title>Home | Yoda Safety Services</title>
            <meta name="User Home" content="Users Homepage" />
          </Helmet>
        </HelmetProvider>
        <OuterContainer>
          <>
            <LeftContainer>
              <Suspense fallback={<div>Loading Menu...</div>}>
                <UserMenu data={data} />
              </Suspense>
            </LeftContainer>
            <RightContainer>
              <TopRightContainer>
                <Suspense fallback={<div>Loading Tabs...</div>}>
                  <TabButton
                    span="All Courses"
                    functionName={(e) => {
                      e.preventDefault();
                      setAllCourses(true);
                      setShowIncompleteCourses(false);
                      setShowCompleteCourses(false);
                      setChosen('AllCourses');
                    }}
                    active={showAllCourses ? true : false}
                  />
                  <TabButton
                    active={showIncompleteCourses ? true : false}
                    functionName={(e) => {
                      e.preventDefault();
                      setAllCourses(false);
                      setShowIncompleteCourses(true);
                      setShowCompleteCourses(false);
                      setChosen('IncompleteCourses');
                    }}
                    span="Incomplete Courses"
                  />
                  <TabButton
                    active={showCompleteCourses ? true : false}
                    functionName={(e) => {
                      e.preventDefault();
                      setAllCourses(false);
                      setShowIncompleteCourses(false);
                      setShowCompleteCourses(true);
                      setChosen('CompleteCourses');
                    }}
                    span="Complete Courses"
                  />
                </Suspense>
              </TopRightContainer>
              {/* Displays the user's courses that are chosen */}
              <CourseContainer>
                <Suspense fallback={<div>Loading Courses...</div>}>
                  <UserCourses user={data} chosen={chosen} />
                </Suspense>
              </CourseContainer>
            </RightContainer>
          </>
        </OuterContainer>
      </>
    </AuthCheckUser>
  );
};

export default UserHome;
