import styled from 'styled-components';

export const EachDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing.m};
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.spacing.m};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
`;
