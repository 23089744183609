import {
  httpCreateCourse,
  httpCreateAtt,
  // httpCreateImage,
  httpCreatePdf,
} from '../../../Hooks/admin/admin.post.hooks';

export const createCourse = async (
  course,
  formDataA,
  // formDataB,
  formDataC,
  setSuccessState,
  setSuccessStateA,
  // setSuccessStateB,
  setSuccessStateC,
  setIsOpen,
  setLoading
) => {
  try {
    // console.log('Creating this course', course);
    setSuccessState('Uploading the course');
    const res = await httpCreateCourse(course);
    if (res.ok) {
      setSuccessState('Course information has been created');
    } else {
      setSuccessState('Course information has not been created');
    }
    if (formDataA && [...formDataA.entries()].length > 0) {
      // for (let [key, value] of formDataA.entries()) {
      //   console.log(`C: ${key}:`, value);
      // }
      setSuccessStateA('Uploading the pdf');
      const resA = await httpCreatePdf(formDataA, course.courseName);
      const dataA = await resA.json();
      if (resA.ok) {
        setSuccessStateA(dataA.msg || 'Pdf has been uploaded');
      } else {
        setSuccessStateA(dataA.msg || 'Pdf has not been uploaded');
      }
    } else {
      setSuccessStateA('No pdf to be uploaded');
    }
    // if (formDataB && [...formDataB.entries()].length > 0) {
    //   // for (let [key, value] of formDataB.entries()) {
    //   //   // console.log(`B: ${key}:`, value);
    //   // }
    //   setSuccessStateB('Uploading the image');
    //   const resB = await httpCreateImage(formDataB, course.courseName);
    //   const dataB = await resB.json();
    //   if (resB.ok) {
    //     setSuccessState(dataB.msg || 'Image has been uploaded');
    //   } else {
    //     setSuccessStateB(dataB.msg || 'Image has not been uploaded');
    //   }
    // } else {
    //   setSuccessStateB('No image to be uploaded');
    // }
    if (formDataC && [...formDataC.entries()].length > 0) {
      // for (let [key, value] of formDataC.entries()) {
      //   console.log(`C: ${key}:`, value);
      // }
      setSuccessStateC('Uploading the attachments');
      const resC = await httpCreateAtt(formDataC, course.courseName);
      const dataC = await resC.json();
      if (resC.ok) {
        setSuccessStateC(dataC.msg || 'Attachments have been uploaded');
      } else {
        setSuccessStateC(dataC.msg || 'Attachments have not been uploaded');
      }
    } else {
      setSuccessStateC('No attatchment to be uploaded');
    }
  } catch (err) {
    setSuccessState('An error has occured creating the course', err.msg);
  } finally {
    setSuccessState('Process has been completed');
    setLoading(false);
    setIsOpen(true);
  }
};
