import styled from 'styled-components';

export const TabComponent = styled.button`
  min-width: 150px;
  width: auto;
  max-width: 350px;
  /* height: 45px; */
  height: 100%;
  /* padding: 5px 10px; */
  cursor: pointer;
  /* transition: all 0.3s ease; */
  background-color: transparent;
  /* background-color: ${({ theme }) => theme.colors.grey4}; */
  border: none;
  padding: 0.8rem 1rem;

  border-bottom: ${({ active, theme }) =>
    active
      ? `5px solid ${theme.colors.yellow5}`
      : `5px solid ${theme.colors.grey5}`};
  /* &:hover {
    background: ${({ active, theme }) =>
    active ? theme.colors.orange5 : theme.colors.grey6};
    border: 3px solid ${({ theme }) => theme.colors.grey6};
  } */
  /* border: 1px solid red; */

  span {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem !important;
    /* border: 1px solid green; */
    color: ${({ active, theme }) =>
      active ? theme.colors.yellow5 : theme.colors.grey1};
  }
  span:hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.orange5};
  }
  @media screen and (width <= 768px) {
    padding: 0.5rem 0.7rem;
  }
`;
