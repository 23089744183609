import {
  BreadcrumbContainer,
  EachBreadcrumb,
} from './BreadcrumbsStyles.styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ChevronRightIcon from '../../Visuals/Icons/ChevronRightIcon.icon';

// Breadrumbs
const BreadCrumbs = ({ one, oneLink, two, twoLink, three }) => {
  const navigate = useNavigate();

  return (
    <BreadcrumbContainer>
      {one && (
        <EachBreadcrumb>
          <span onClick={() => navigate(oneLink)}>{one}</span>
          <ChevronRightIcon />
        </EachBreadcrumb>
      )}
      {two && !three && (
        <EachBreadcrumb>
          <span>{two}</span>
        </EachBreadcrumb>
      )}
      {two && three && (
        <EachBreadcrumb>
          <span onClick={() => navigate(twoLink)}>{two}</span>
          <ChevronRightIcon />
        </EachBreadcrumb>
      )}
      {three && (
        <EachBreadcrumb>
          <span>{three}</span>
        </EachBreadcrumb>
      )}
    </BreadcrumbContainer>
  );
};
BreadCrumbs.propTypes = {
  one: PropTypes.string,
  oneLink: PropTypes.string,
  two: PropTypes.string,
  twoLink: PropTypes.string,
  three: PropTypes.string,
};

export default BreadCrumbs;
