import {
  UserSettingsMain,
  UserSettingsSection,
  InputSection,
  TitleSection,
  BottomButtonOuter,
} from './UserSettingsStyles.styles';
import { ButtonContainer } from '../../Quiz/QuizPageStyles.styles';
import { useNavigate } from 'react-router-dom';
import Auth from '../../Helpers/auth';
import React, { useState, Suspense } from 'react';
import { httpEditUser } from '../../Hooks/admin/admin.put.hooks';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { httpDeleteUserAccount } from '../../Hooks/user/user.delete.hooks';

// Lazy-load components
const PrimaryButton = React.lazy(
  () => import('../../Components/Buttons/PrimaryButton/PrimaryButton.component')
);
const SecondaryButton = React.lazy(
  () =>
    import('../../Components/Buttons/SecondaryButton/SecondaryButton.component')
);
const DestructiveButton = React.lazy(
  () =>
    import(
      '../../Components/Buttons/DestructiveButton/DestructiveButton.component'
    )
);
const InputTextNested = React.lazy(
  () => import('../../Components/Form/InputTextNested')
);
const BreadCrumbs = React.lazy(
  () => import('../../Components/Breadcrumbs/BreadcrumbsComponent.component')
);

/// User settings page
const UserSettings = () => {
  const navigate = useNavigate();
  const { data } = Auth.getProfile();
  const [user, setUser] = useState(data);
  const [err, setErr] = useState('');

  const deleteUser = async (e) => {
    e.preventDefault();
    let res = await httpDeleteUserAccount(user?.id);
    let data = await res.json();
    if (res.ok) {
      setErr('Your account has been deleted, you will now be logged out');
      setTimeout(() => {
        Auth.logout();
        navigate('/');
      }, 8000);
    } else {
      setErr(data.msg);
    }
  };

  return (
    <AuthCheckUser>
      <HelmetProvider>
        <Helmet>
          <title>Settings | Yoda Safety Services</title>
          <meta name="User settings" content="Update your profile." />
        </Helmet>
      </HelmetProvider>
      <UserSettingsMain>
        <Suspense fallback={<div>Loading breadcrumbs...</div>}>
          <BreadCrumbs one="Home" oneLink={`/user`} two="Settings" />
        </Suspense>
        <h2>Update your profile</h2>
        <form>
          <UserSettingsSection>
            {/* For first name and last name */}
            <TitleSection>
              <h4>Name</h4>
              <span className="italicSpan">
                The name displayed here is what will appear as on your
                certificate
              </span>
            </TitleSection>
            <InputSection>
              <Suspense fallback={<div>Loading input...</div>}>
                <InputTextNested
                  title="First Name"
                  value={user.firstName}
                  name="firstName"
                  nestedValue={setUser}
                  upperValue={user}
                  className="gapIt"
                />
                <InputTextNested
                  title="Last Name"
                  value={user.lastName}
                  name="lastName"
                  nestedValue={setUser}
                  upperValue={user}
                  className="gapIt"
                />
              </Suspense>
            </InputSection>
          </UserSettingsSection>
          <UserSettingsSection>
            {/* For Email and password */}
            <TitleSection>
              <h4>Login Details</h4>
            </TitleSection>
            <InputSection>
              <Suspense fallback={<div>Loading input...</div>}>
                <InputTextNested
                  title="Email"
                  value={user.email}
                  name="email"
                  nestedValue={setUser}
                  upperValue={user}
                  className="gapIt"
                />
                <InputTextNested
                  title="Password"
                  value={user.password}
                  name="password"
                  nestedValue={setUser}
                  upperValue={user}
                  className="gapIt"
                />
              </Suspense>
            </InputSection>
          </UserSettingsSection>
          <UserSettingsSection>
            <TitleSection>
              <h4>Delete your account</h4>
              <span className="italicSpan">
                Your account can be recovered up to 30 days after deletion
              </span>
            </TitleSection>
            <InputSection>
              <Suspense fallback={<div>Loading button...</div>}>
                <DestructiveButton
                  functionName={deleteUser}
                  span="Delete Account"
                />
              </Suspense>
            </InputSection>
          </UserSettingsSection>
          <p>{err}</p>
          <BottomButtonOuter>
            {/* For the bottom 2 buttons */}
            <ButtonContainer>
              <Suspense fallback={<div>Loading button...</div>}>
                <SecondaryButton
                  functionName={(e) => {
                    e.preventDefault();
                    navigate(`/user`);
                  }}
                  span="Home"
                />
                <PrimaryButton
                  functionName={async (e) => {
                    e.preventDefault();
                    let res = await httpEditUser(user?.id, user);
                    let data = await res.json();
                    if (res.ok) {
                      setErr(
                        'Your account has been updated, reflections of change may take a moment to show'
                      );
                    } else {
                      setErr(data.msg);
                    }
                  }}
                  span="Update Profile"
                />
              </Suspense>
            </ButtonContainer>
          </BottomButtonOuter>
        </form>
      </UserSettingsMain>
    </AuthCheckUser>
  );
};

export default UserSettings;
