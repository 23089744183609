import { useEffect } from 'react';
import { redirect } from 'react-router-dom';
import Auth from '../../Helpers/auth';

export const AuthCheckAdmin = ({ children }) => {
  // const navigate = useNavigate();
  const { data } = Auth.getProfile();
  const emails = ['vrose834@gmail.com', 'jbenoit.yoda@outlook.com'];

  useEffect(() => {
    if (!Auth.loggedIn()) {
      //navigate('/login');
      return redirect('/login');
    } else if (data.type !== 'Admin' && !emails.includes(data.email)) {
      //navigate('/unauthorized');
      return redirect('/unauthorized');
    }
  }, [data]);

  return children;
};
