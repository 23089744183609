import { NavLink } from 'react-router-dom';
import Logo from '../../../Visuals/Images/Logo.png';
import { Header } from './NavbarStyles.styles';
import OuterBurger from './OuterBurgerComponent.component';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Auth from '../../../Helpers/auth';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import LoginIcon from '../../../Visuals/Icons/LoginIcon.icon';
// import LogoutIcon from '../../../Visuals/Icons/LogoutIcon.icon';
import HomeIcon from '../../../Visuals/Icons/HomeIcon.icon';

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <Header>
      <nav className="topNav">
        <div className="first">
          <OuterBurger />
        </div>
        <div className="second">
          <NavLink to="/">
            <img
              className="logoImage"
              src={Logo}
              alt="Yoda Safety Services Logo"
            />
          </NavLink>
        </div>

        <div className="third">
          {Auth.loggedIn() ? (
            <>
              <div className="mobile">
                {/* <button className='primaryButton boxShadow' onClick={(e) => {
                        e.preventDefault();
                        Auth.logout();
                    }}><span>LOGOUT</span></button> */}
                <NavLink to="/user" title="User Home">
                  <HomeIcon />
                </NavLink>

                {/* <div
                  onClick={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    window.location.assign('/');
                  }}
                  title="Logout"
                >
                  <LogoutIcon />
                </div> */}
              </div>
              {/* Desktop */}
              <div className="desktop">
                <NavLink to="/user" title="User Home">
                  <HomeIcon />
                </NavLink>
                {/* <PrimaryButton
                  functionName={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    window.location.assign('/');
                  }}
                  span="LOGOUT"
                /> */}
              </div>
            </>
          ) : (
            //   If not logged in
            <>
              {/* Mobile Login button */}
              {/* {(process.env.REACT_APP_PRODUCTION === undefined ||
                process.env.REACT_APP_PRODUCTION === 'false') && ( */}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');
                }}
                className="mobile"
                title="Login"
              >
                <LoginIcon />
              </div>
              {/* )} */}
              {/* Desktop login button */}
              <div className="desktop">
                <PrimaryButton
                  functionName={(e) => {
                    e.preventDefault();
                    navigate('/login');
                  }}
                  span="LOGIN"
                />
              </div>
            </>
          )}
        </div>
      </nav>
    </Header>
  );
};

export default NavBar;
