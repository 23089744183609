import { EachAnswer, EachQuestionContainer } from '../QuizPageStyles.styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Displays each question for the quiz
const EachQuestion = ({
  q,
  i,
  setCorrect,
  correct,
  setAnswered,
  setAnsweredQuestions,
  answeredQuestions,
  setUserAnswers,
  userAnswers,
}) => {
  const {
    id,
    question,
    correctAnswer,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
  } = q;
  const [selected, setSelected] = useState();

  const checkAnswer = (e) => {
    const ans = e.target.value;
    if (ans === correctAnswer) {
      setCorrect(correct + 1);
    }
    setSelected(ans);
    setUserAnswers((prev) => ({
      ...prev,
      [id]: ans,
    }));
  };
  useEffect(() => {
    if (selected && !answeredQuestions[id]) {
      setAnswered((prev) => prev + 1);
      setAnsweredQuestions((prev) => ({
        ...prev,
        [id]: true,
      }));
    }
  }, [selected, id, answeredQuestions, setAnswered, setAnsweredQuestions]);

  useEffect(() => {
    if (Object.keys(userAnswers).length > 0) {
      localStorage.setItem(`quizAnswers-${id}`, JSON.stringify(userAnswers));
    }
  }, [userAnswers, id]);

  useEffect(() => {
    // Check if there is a saved answer for this question
    if (userAnswers[id]) {
      setSelected(userAnswers[id]); // Restore the saved answer
    }
  }, [userAnswers, id]);

  return (
    <EachQuestionContainer>
      <div key={id} id={id}>
        <p>
          {i + 1}. {question}
        </p>
        {answer1 && (
          <EachAnswer className={selected === answer1 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer1}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer1}`}
                name={`answer-${id}`}
                value={answer1}
                onClick={checkAnswer}
              />
              {answer1}
            </label>
          </EachAnswer>
        )}
        {answer2 && (
          <EachAnswer className={selected === answer2 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer2}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer2}`}
                name={`answer-${id}`}
                value={answer2}
                onClick={checkAnswer}
              />
              {answer2}
            </label>
          </EachAnswer>
        )}
        {answer3 && (
          <EachAnswer className={selected === answer3 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer3}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer3}`}
                name={`answer-${id}`}
                value={answer3}
                onClick={checkAnswer}
              />
              {answer3}
            </label>
          </EachAnswer>
        )}
        {answer4 && (
          <EachAnswer className={selected === answer4 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer4}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer4}`}
                name={`answer-${id}`}
                value={answer4}
                onClick={checkAnswer}
              />
              {answer4}
            </label>
          </EachAnswer>
        )}
        {answer5 && (
          <EachAnswer className={selected === answer5 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer5}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer5}`}
                name={`answer-${id}`}
                value={answer5}
                onClick={checkAnswer}
              />
              {answer5}
            </label>
          </EachAnswer>
        )}
        {answer6 && (
          <EachAnswer className={selected === answer6 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer6}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer6}`}
                name={`answer-${id}`}
                value={answer6}
                onClick={checkAnswer}
              />
              {answer6}
            </label>
          </EachAnswer>
        )}
        {answer7 && (
          <EachAnswer className={selected === answer7 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer7}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer7}`}
                name={`answer-${id}`}
                value={answer7}
                onClick={checkAnswer}
              />
              {answer4}
            </label>
          </EachAnswer>
        )}
        {answer8 && (
          <EachAnswer className={selected === answer8 ? 'selectedAnswer' : ''}>
            <label htmlFor={`answer-${id}-${answer8}`}>
              <input
                type="radio"
                id={`answer-${id}-${answer8}`}
                name={`answer-${id}`}
                value={answer8}
                onClick={checkAnswer}
              />
              {answer8}
            </label>
          </EachAnswer>
        )}
      </div>
    </EachQuestionContainer>
  );
};
EachQuestion.propTypes = {
  q: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    correctAnswer: PropTypes.string,
    answer1: PropTypes.string,
    answer2: PropTypes.string,
    answer3: PropTypes.string,
    answer4: PropTypes.string,
    answer5: PropTypes.string,
    answer6: PropTypes.string,
    answer7: PropTypes.string,
    answer8: PropTypes.string,
  }),
  i: PropTypes.number,
  setCorrect: PropTypes.func,
  correct: PropTypes.number,
  setAnswered: PropTypes.func,
  setAnsweredQuestions: PropTypes.func,
  answeredQuestions: PropTypes.object,
  setUserAnswers: PropTypes.func,
  userAnswers: PropTypes.object,
};

export default EachQuestion;
