import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalButtons,
  PayPalNameField,
  PayPalNumberField,
  PayPalExpiryField,
  PayPalCVVField,
} from '@paypal/react-paypal-js';
import { httpCaptureOrder, httpCreateOrder } from '../Hooks/orders/order.hooks';
import { useNavigate } from 'react-router-dom';
import Auth from '../Helpers/auth';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';

// const [message, setMessage] = useState('');
export default function Purchase() {
  //   const { id } = useParams();
  const id = '6647a1e031f109aa561a3316';
  const [course, setCourse] = useState([]);
  const { data } = Auth.getProfile();
  const userId = data.id;
  //   const [loading, setLoading] = useState(true);
  //   const [err, setErr] = useState('');
  // const taxRate = 0.15;
  // const [taxAmount, setTaxAmount] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const FetchCourse = async () => {
      const data = await httpGetSingleCourse(id);
      //   if (!data) setErr('Error getting the course');
      setCourse(data);
      // setTaxAmount(data.price * taxRate);
      // let taxAmount = data.price * taxRate;
      // setTotalAmount(data.price + taxAmount);
      //   setLoading(false);
    };
    FetchCourse();
  }, [id]);

  const [isPaying, setIsPaying] = useState(false);
  const initialOptions = {
    'client-id': process.env.REACT_APP_PAY_PAL_CLIENT_ID,
    'enable-funding': 'venmo',
    'disable-funding': '',
    // 'buyer-country': 'CA',
    currency: 'CAD',
    'data-page-type': 'product-details',
    components: 'buttons,card-fields',
    // 'data-sdk-integration-source': 'developer-studio',
  };

  const [billingAddress, setBillingAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    adminArea1: '',
    adminArea2: '',
    countryCode: '',
    postalCode: '',
  });

  function handleBillingAddressChange(field, value) {
    setBillingAddress((prev) => ({
      ...prev,
      [field]: value,
    }));
  }
  async function createOrder() {
    try {
      const cartOuter = { cart: { id: course._id, quantity: 1 } };
      // console.log('THE CART', cartOuter);
      const response = await httpCreateOrder(cartOuter);
      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      return `Could not initiate PayPal Checkout...${error}`;
    }
  }

  async function onApprove(data) {
    try {
      const response = await httpCaptureOrder(data.orderID, userId, id);
      const orderData = await response.json();
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const transaction =
        orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
        orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
      const errorDetail = orderData?.details?.[0];

      if (errorDetail || !transaction || transaction.status === 'DECLINED') {
        // (2) Other non-recoverable errors -> Show a failure message
        let errorMessage;
        if (transaction) {
          errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
        } else if (errorDetail) {
          errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
        } else {
          errorMessage = JSON.stringify(orderData);
        }

        throw new Error(errorMessage);
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');
        console.log(
          'Capture result',
          orderData,
          JSON.stringify(orderData, null, 2)
        );
        return `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`;
      }
    } catch (error) {
      return `Sorry, your transaction could not be processed...${error}`;
    }
  }

  function onError(error) {
    console.log(error);
    // Do something with the error from the SDK
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
        style={{
          shape: 'rect',
          layout: 'vertical',
          color: 'gold',
          label: 'paypal',
        }}
      />

      <PayPalCardFieldsProvider
        createOrder={createOrder}
        onApprove={onApprove}
        style={{
          input: {
            'font-size': '16px',
            'font-family': 'courier, monospace',
            'font-weight': 'lighter',
            color: '#ccc',
          },
          '.invalid': { color: 'purple' },
        }}
      >
        <PayPalNameField
          style={{ input: { color: 'blue' }, '.invalid': { color: 'purple' } }}
        />
        <PayPalNumberField />
        <PayPalExpiryField />
        <PayPalCVVField />

        <input
          type="text"
          id="card-billing-address-line-2"
          name="card-billing-address-line-2"
          placeholder="Address line 1"
          onChange={(e) =>
            handleBillingAddressChange('addressLine1', e.target.value)
          }
        />
        <input
          type="text"
          id="card-billing-address-line-2"
          name="card-billing-address-line-2"
          placeholder="Address line 2"
          onChange={(e) =>
            handleBillingAddressChange('addressLine2', e.target.value)
          }
        />
        <input
          type="text"
          id="card-billing-address-admin-area-line-1"
          name="card-billing-address-admin-area-line-1"
          placeholder="Admin area line 1"
          onChange={(e) =>
            handleBillingAddressChange('adminArea1', e.target.value)
          }
        />
        <input
          type="text"
          id="card-billing-address-admin-area-line-2"
          name="card-billing-address-admin-area-line-2"
          placeholder="Admin area line 2"
          onChange={(e) =>
            handleBillingAddressChange('adminArea2', e.target.value)
          }
        />
        <input
          type="text"
          id="card-billing-address-country-code"
          name="card-billing-address-country-code"
          placeholder="Country code"
          onChange={(e) =>
            handleBillingAddressChange('countryCode', e.target.value)
          }
        />
        <input
          type="text"
          id="card-billing-address-postal-code"
          name="card-billing-address-postal-code"
          placeholder="Postal/zip code"
          onChange={(e) =>
            handleBillingAddressChange('postalCode', e.target.value)
          }
        />
        <SubmitPayment
          isPaying={isPaying}
          setIsPaying={setIsPaying}
          billingAddress={billingAddress}
        />
      </PayPalCardFieldsProvider>
    </PayPalScriptProvider>
  );
}

const SubmitPayment = ({ isPaying, setIsPaying, billingAddress }) => {
  const { cardFieldsForm, fields } = usePayPalCardFields();
  console.log(isPaying, setIsPaying, billingAddress, fields);
  const handleClick = async () => {
    if (!cardFieldsForm) {
      const childErrorMessage =
        'Unable to find any child components in the <PayPalCardFieldsProvider />';

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    if (!formState.isFormValid) {
      return alert('The payment form is invalid');
    }
    setIsPaying(true);

    cardFieldsForm.submit({ billingAddress }).catch((err) => {
      setIsPaying(false);
      console.log(err);
    });
  };
  const navigate = useNavigate();

  return (
    <>
      <button
        className={isPaying ? 'btn' : 'btn btn-primary'}
        style={{ float: 'right' }}
        onClick={handleClick}
      >
        {isPaying ? <div className="spinner tiny" /> : 'Pay'}
      </button>
      {/* <p>{loading}</p> */}
      {/* <p>{err}</p> */}
      <button onClick={() => navigate('/finished')}>Done</button>
    </>
  );
};

SubmitPayment.propTypes = {
  isPaying: PropTypes.bool,
  setIsPaying: PropTypes.func,
  billingAddress: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    adminArea1: PropTypes.string,
    adminArea2: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
  }),
};
