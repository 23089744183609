import React, { useState, useEffect } from 'react';
import { CookieBannerContainer } from './CookieBanner.styles';
import PrimaryButtonComponent from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import DestructiveButton from '../Components/Buttons/DestructiveButton/DestructiveButton.component';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);
  const handleAccept = (e) => {
    e.preventDefault();
    localStorage.setItem('cookieConsent', true);
    setShowBanner(false);
  };
  const handleDecline = (e) => {
    e.preventDefault();
    localStorage.setItem('cookieConsent', false);
    setShowBanner(false);
  };
  if (!showBanner) {
    return null;
  }
  return (
    <>
      {showBanner && (
        <CookieBannerContainer>
          <p>
            We use cookies to improve your experience on our site. By using our
            site, you constent to cookies.
          </p>
          <div>
            <PrimaryButtonComponent span="Accept" functionName={handleAccept} />
            <DestructiveButton span="Decline" fuctionName={handleDecline} />
          </div>
        </CookieBannerContainer>
      )}
    </>
  );
};

export default CookieBanner;
