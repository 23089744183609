import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { httpGetSingleCourse } from '../../../Hooks/courses.hooks';
import EditCourseSubmit from './CourseEditSubmit.component';
import Modal from '../../../Components/Modal/ModalComponent.component';
import {
  FormContainer,
  SubmitContainer,
} from '../../CreateRoutes/CreateForm.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import InputEditCourses from './FormEditCourse/InputsEditCourse.component';
import TagsSelectedContainer from '../../../Components/Form/TagElement/TagsContainer.component';
import QuestionContainerComponent from '../../../Components/Form/QuestionElements/QuestionContainer.component';
import { format } from 'date-fns';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import PageScroller from '../../../Components/PageScroller/PageScroller.component';

// Form for editing a course
const EditCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [course, setCourse] = useState([]);
  const [courseName, setCourseName] = useState();
  const [price, setPrice] = useState();
  const [description, setDescription] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [certificatePrefix, setCertificatePrefix] = useState();
  const [tags, setTags] = useState();
  const [image, setImage] = useState('');
  const [questions, setQuestions] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [successState, setSuccessState] = useState('');
  const [pdf, setPdf] = useState('');
  const [video, setVideo] = useState();
  const [videoLinks, setVideoLinks] = useState([]);
  const [notes, setNotes] = useState('');
  const [newPdf, setNewPdf] = useState('');
  // const [newImage, setNewImage] = useState('');
  const [att, setAtt] = useState([]);
  const [activeCourse, setActiveCourse] = useState(true);
  const [partTwo, setPartTwo] = useState(false);

  useEffect(() => {
    const GetCourse = async () => {
      let c = await httpGetSingleCourse(id);
      if (c) {
        setCourse(c);
        setCourseName(c?.courseName);
        setPrice(c?.price);
        setDescription(c?.description);
        setExpiryDate(c?.expiryDate);
        setNotes(c?.notes);
        setVideoLinks(c?.videoLinks);
        setCertificatePrefix(c?.certificatePrefix);
        setTags(c?.tags);
        setQuestions(c?.quiz);
        setImage(c?.image);
        setPdf(c?.pdf);
        setAtt(c?.attachments);
        setActiveCourse(c?.active);
        setPartTwo(c?.partTwo);
      }
    };
    GetCourse();
  }, [id]);
  const formattedCreatedAt = course?.createdAt
    ? format(new Date(course?.createdAt), 'MMMM dd yyyy,  HH:mm')
    : '';
  const formattedUpdatedAt = course?.updatedAt
    ? format(new Date(course?.updatedAt), 'MMMM dd yyyy,  HH:mm')
    : '';

  return (
    <AuthCheckAdmin>
      <HelmetProvider>
        <Helmet>
          <title>Edit Course | Yoda Safety Services</title>
          <meta name="Edit Course" content="Edit a course form" />
        </Helmet>
      </HelmetProvider>
      <FormContainer>
        <div style={{ marginBottom: '1.5rem' }}>
          <BreadCrumbs one="Admin Home" oneLink="/admin" two={'Edit Course'} />
        </div>
        <h2>Edit Course</h2>
        <span style={{ fontStyle: 'italic', marginBottom: '0.5rem' }}>
          Current Certificate Count: {course?.certificateCount}
        </span>
        <span style={{ fontStyle: 'italic', marginBottom: '0.5rem' }}>
          Created At: {formattedCreatedAt}
        </span>
        <span
          style={{
            fontStyle: 'italic',
            marginBottom: '0.8rem',
            paddingBottom: '0.5rem',
            borderBottom: '1px solid lightGrey',
          }}
        >
          Last Edited At: {formattedUpdatedAt}
        </span>
        <span style={{ fontStyle: 'italic', marginBottom: '0.5rem' }}>
          Purchase Count: {course?.purchasedCount}
        </span>
        <form>
          {/* Main form inputs */}
          <InputEditCourses
            incomingProps={{
              courseName,
              description,
              price,
              expiryDate,
              certificatePrefix,
              notes,
              video,
              setCertificatePrefix,
              setCourseName,
              setDescription,
              setPrice,
              setNotes,
              videoLinks,
              setExpiryDate,
              setVideo,
              setVideoLinks,
              pdf,
              setImage,
              setNewPdf,
              image,
              att,
              setAtt,
              newPdf,
              activeCourse,
              setActiveCourse,
              partTwo,
              setPartTwo,
            }}
          />
          {/* Tags */}
          <TagsSelectedContainer tags={tags} setTags={setTags} />
          {/* Quiz */}
          <QuestionContainerComponent
            questions={questions}
            setQuestions={setQuestions}
          />
          {/* Popup modal for when the form submission happens */}
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              {successState && <p>{successState}</p>}
              <PrimaryButton
                functionName={() => navigate('/admin')}
                span="Go Home"
              />
            </Modal>
          )}
          <PageScroller />
          <SubmitContainer>
            <EditCourseSubmit
              course={course}
              pdf={newPdf}
              videoLinks={videoLinks}
              notes={notes}
              questions={questions}
              courseName={courseName}
              tags={tags}
              image={image}
              description={description}
              price={price}
              partTwo={partTwo}
              expiryDate={expiryDate}
              certificatePrefix={certificatePrefix}
              setIsOpen={setIsOpen}
              successState={successState}
              setSuccessState={setSuccessState}
              activeCourse={activeCourse}
              att={att}
            />
          </SubmitContainer>
        </form>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default EditCourse;
