import styled from 'styled-components';

export const MainContainer = styled.main`
  overflow-x: hidden;
  /* height: 100vh; */
  h2 {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }
  /* The h2 came from here  */
  @media screen and (width <= 768px) {
  }
`;

export const LightSection = styled.section`
  padding: ${({ theme }) => theme.spacing.xs};
  &:last-child {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }
`;

export const DarkSection = styled.aside`
  background-color: ${({ theme }) => theme.colors.grey7};
  h2 {
    color: ${({ theme }) => theme.colors.offWhite};
  }
  span {
    color: ${({ theme }) => theme.colors.offWhite};
  }
  label {
    color: ${({ theme }) => theme.colors.offWhite};
  }

  .center {
    width: 100%;
  }
`;

export const TitleSection = styled.div`
  /* border: 15px solid yellow; */
  position: relative;
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .text {
    animation: 1s ease-out 0s 1 slideInFromLeft;
    position: absolute;
    color: ${({ theme }) => theme.colors.white};
    top: 20%;
    left: 4%;
    /* border: 1px solid red; */
  }
  .text h1 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: ${({ theme }) => theme.spacing.xxl};
    margin: 0;
  }
  .text span {
    color: ${({ theme }) => theme.colors.yellow5};
  }
  .overlay {
    /* border: 5px solid blue; */
    /* height: auto; */
    background-color: rgba(0, 0, 0, 1);
  }
  img {
    /* border: 2px solid orange; */
    height: 50vh;
    max-height: 50vh;
    width: 100vw;
    max-width: 100vw;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    object-fit: cover;
    opacity: 0.6;
  }

  @media screen and (width <= 700px) {
    height: 30vh;
    img {
      height: 30vh;
      max-height: 30vh;
    }
    .text {
      top: 14%;
      left: 4%;
    }
    .text h1 {
      font-weight: 700;
      font-size: ${({ theme }) => theme.spacing.l};
    }
    .text span {
      font-size: ${({ theme }) => theme.spacing.s};
    }
  }

  @media screen and (width >= 1500px) {
    height: 60vh;
    img {
      height: 60vh;
      max-height: 60vh;
    }
  }
`;

export const SpecificsSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.m};
  overflow-x: hidden;
  /* border: 2px solid red; */
  div {
    padding: ${({ theme }) => theme.spacing.xs};
    width: 30%;
    text-align: center;
    border-right: 3px solid ${({ theme }) => theme.colors.grey5};
    &:last-child {
      border-right: none;
    }
  }
  .facts {
    font-size: 0.8rem;
  }
  p {
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.orange5};
    letter-spacing: 0.1rem;
    padding: 1rem;
    height: 100%;
    /* border-left: 3px solid ${({ theme }) => theme.colors.grey5}; */
  }
  @media screen and (width <= 768px) {
    flex-direction: column;
    /* border: 1px solid green; */
    flex-wrap: wrap;
    overflow-x: hidden;
    gap: ${({ theme }) => theme.spacing.xxs};
    div {
      width: 100%;
      max-width: 100%;
      border-bottom: 3px solid ${({ theme }) => theme.colors.grey5};
      border-right: none;
      padding: ${({ theme }) => theme.spacing.xxxs};
    }
    .facts {
      font-size: 0.7rem;
    }
  }
`;

export const AboutUsSection = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  overflow-x: hidden;
  h3 {
    color: ${({ theme }) => theme.colors.grey5};
  }
`;

export const ServicesSection = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.grey6};
  h2 {
    color: ${({ theme }) => theme.colors.offWhite};
  }
`;
export const ServicesContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (width <= 1100px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 1rem;
  }
  @media screen and (width <= 600px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const EachService = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 300px;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.shadow5};
  padding: ${({ theme }) => theme.spacing.s};
  text-align: left;
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding-top: 1rem;
  margin-top: 1rem;
  h4 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.grey5};
    margin-top: 1rem;
  }
  h4:hover {
    color: ${({ theme }) => theme.colors.orange5};
  }
  @media screen and (width <= 1024px) {
    width: 40%;
    height: 200px;
  }
  @media screen and (width <= 600px) {
    width: 100%;
    height: auto;
    .icon {
      height: 20px !important;
      width: 20px;
    }
    h4 {
      font-size: 0.9rem;
    }
  }
`;

export const ContactUsSection = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  overflow-x: hidden;
  p {
    color: ${({ theme }) => theme.colors.grey7};
    font-weight: bold;
  }
`;
export const ContactUsContainer = styled.div`
  /* box-shadow: ${({ theme }) => theme.shadows.boxShadow}; */
  margin-top: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.offWhite};
  /* border-radius: ${({ theme }) => theme.borderRadius}; */
  /* padding: ${({ theme }) => theme.spacing.s}; */
  padding-left: ${({ theme }) => theme.spacing.l};
  border-left: 10px solid ${({ theme }) => theme.colors.grey2};
  h3 {
    color: ${({ theme }) => theme.colors.grey6};
    /* background-color: ${({ theme }) => theme.colors.grey1}; */
    /* border-top-right-radius: ${({ theme }) => theme.borderRadius}; */
    /* border-top-left-radius: ${({ theme }) => theme.borderRadius}; */
    padding: ${({ theme }) => theme.spacing.s};
  }
  h3,
  p,
  span,
  address {
    padding-left: ${({ theme }) => theme.spacing.s};
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
  .italics {
    font-style: italic;
    color: ${({ theme }) => theme.colors.grey4};
  }
`;
