import { EachTag, TagContainer } from '../FormElements.styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { tagOptions } from './TagElements';

// For the tags of the types of courses
const TagSelect = ({ tags = [], setTags }) => {
  // These are capitalized - needs to be
  const [ctags, setCheckedTags] = useState([]);
  useEffect(() => {
    // Initialize the checked tags from the database
    setCheckedTags(Array.isArray(tags) ? tags : []);
  }, [tags]);

  // The value is the one that is capitalized not the name
  const onChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'selectAll') {
      const updatedTags = checked ? tagOptions.map((tag) => tag.value) : [];
      setCheckedTags(updatedTags);
      setTags(updatedTags);
    } else {
      const updatedCheckedTags = checked
        ? [...ctags, value]
        : ctags.filter((tag) => tag !== value);

      setCheckedTags(updatedCheckedTags);
      setTags(updatedCheckedTags);
    }
  };

  return (
    <TagContainer>
      <EachTag>
        <input
          type="checkbox"
          name="selectAll"
          value="selectAll"
          // checked={ctags}
          onChange={onChange}
        />
        <label htmlFor="selectAll">Select All</label>
      </EachTag>
      {tagOptions.map((tag) => (
        <EachTag key={tag.name}>
          <input
            type="checkbox"
            name={tag.name}
            value={tag.value}
            checked={ctags.includes(tag.value)}
            onChange={onChange}
          />
          <label htmlFor={tag.name}>{tag.label}</label>
        </EachTag>
      ))}
    </TagContainer>
  );
};
TagSelect.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
};

export default TagSelect;
