export const tagOptions = [
  {
    name: 'Automotive Dealerships',
    value: 'Automotive Dealerships',
    label: 'Automotive Dealerships',
  },
  {
    name: 'Automotive Service',
    value: 'Automotive Service',
    label: 'Automotive Service',
  },
  {
    name: 'Collision Centers',
    value: 'Collision Centers',
    label: 'Collision Centers',
  },
  { name: 'Concrete', value: 'Concrete', label: 'Concrete' },
  { name: 'Demolition', value: 'Demolition', label: 'Demolition' },
  { name: 'Drywall', value: 'Drywall', label: 'Drywall' },
  { name: 'Electrical', value: 'Electrical', label: 'Electrical' },
  { name: 'Flooring', value: 'Flooring', label: 'Flooring' },
  { name: 'Forestry', value: 'Forestry', label: 'Forestry' },
  { name: 'Foundations', value: 'Foundations', label: 'Foundations' },
  { name: 'Framing', value: 'Framing', label: 'Framing' },
  { name: 'Health Care', value: 'Health Care', label: 'Health Care' },
  {
    name: 'Heavy Equipment',
    value: 'Heavy Equipment',
    label: 'Heavy Equipment',
  },
  { name: 'Hospitality', value: 'Hospitality', label: 'Hospitality' },
  {
    name: 'HVAC Mechanical',
    value: 'HVAC Mechanical',
    label: 'HVAC Mechanical',
  },
  { name: 'Insulation', value: 'Insulation', label: 'Insulation' },
  { name: 'Janitorial', value: 'Janitorial', label: 'Janitorial' },
  {
    name: 'Landscaping & Excavation',
    value: 'Landscaping & Excavation',
    label: 'Landscaping & Excavation',
  },
  { name: 'Marine', value: 'Marine', label: 'Marine' },
  { name: 'Painting', value: 'Painting', label: 'Painting' },
  { name: 'Plumbing', value: 'Plumbing', label: 'Plumbing' },
  { name: 'Remediation', value: 'Remediation', label: 'Remediation' },
  { name: 'Rental', value: 'Rental', label: 'Rental' },
  { name: 'Roofing', value: 'Roofing', label: 'Roofing' },
  { name: 'Safety', value: 'Safety', label: 'Safety' },
  { name: 'Trucking', value: 'Trucking', label: 'Trucking' },
  {
    name: 'Welding & Fabrication',
    value: 'Welding & Fabrication',
    label: 'Welding & Fabrication',
  },
];
