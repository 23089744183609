import React from 'react';
import { MainContentContainer, MainSection } from '../OtherPages.styles';
import SideContainerComponent from '../SideContainer.component';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const ProgramDevelopment = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Program Development | Yoda Safety Services</title>
          <meta
            name="Program Development"
            content="About our program development at yoda safety services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Health & Safety Program Development</h2>
        <p>Under development</p>
      </MainSection>
    </MainContentContainer>
  );
};

export default ProgramDevelopment;
