import React from 'react';

const TagIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon icon-book-closed"
      >
        <g>
          <path
            className="secondary"
            d="M5 3h2l5 2 5-2h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2z"
          />
          <path
            className="primary"
            d="M7 3h10v11a1 1 0 0 1-1.45.9L12 13.11l-3.55 1.77A1 1 0 0 1 7 14V3z"
          />
        </g>
      </svg>
    </>
  );
};

export default TagIcon;
