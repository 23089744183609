import styled from 'styled-components';

export const PrimaryButtonContainer = styled.button`
  min-width: 160px;
  width: auto;
  max-width: 400px;
  height: 40px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0.6rem 1.5rem;
  border: 2px solid ${({ theme }) => theme.colors.yellow2};
  border: none;
  background: ${({ disabled }) =>
    disabled
      ? `var(--grey)`
      : `linear-gradient(
    180deg,
    rgba(254, 210, 66, 1) 4%,
    rgba(253, 220, 94, 1) 31%,
    rgba(242, 175, 23, 1) 100%
  )`};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  span {
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.grey7} !important;
    font-size: 0.8rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase !important;
    letter-spacing: 0.1rem !important;
    font-style: normal !important;
  }

  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.yellow5};
    color: ${({ theme }) => theme.colors.grey7};
    border: 2px solid ${({ theme }) => theme.colors.grey7};
    //box-shadow: inset 3px 4px 34px 6px rgba(0, 0, 0, 0.1);
  }
`;
