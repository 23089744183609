import styled from 'styled-components';
export const ErrorBoundaryContainer = styled.main`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
  }
  img {
    height: 45%;
    width: 45%;
  }
  button {
    background-color: orange;
    width: 250px;
    height: 50px;
    padding: ${({ theme }) => theme.spacing.m};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: none;
    margin-top: ${({ theme }) => theme.spacing.m};
  }
  span {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const ErrorWords = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
