import CreateQuestion from './CreateQuestion.component';
import DisplayQuestion from './DisplayQuestion.component';
import EditQuestion from './EditQuestion.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckmarkIcon from '../../../Visuals/Icons/CheckmarkIcon.icon';

//Sets the form for either creating or editing the quiz question
const QuestionContainerComponent = ({ questions, setQuestions }) => {
  const [createOrEdit, setCreateOrEdit] = useState(true);
  const [editQuestion, setEditQuestion] = useState([]);

  return (
    <>
      <h4>
        Quiz <CheckmarkIcon />
      </h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {createOrEdit ? (
          <CreateQuestion questions={questions} setQuestions={setQuestions} />
        ) : (
          <EditQuestion
            editQuestion={editQuestion}
            setQuestions={setQuestions}
            questions={questions}
            setEditQuestion={setEditQuestion}
            setCreateOrEdit={setCreateOrEdit}
          />
        )}
      </div>
      <DisplayQuestion
        questions={questions}
        setQuestions={setQuestions}
        setCreateOrEdit={setCreateOrEdit}
        setEditQuestion={setEditQuestion}
      />
    </>
  );
};

QuestionContainerComponent.propTypes = {
  questions: PropTypes.array,
  setQuestions: PropTypes.func,
};

export default QuestionContainerComponent;
