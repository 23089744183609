import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { UpdateCourse } from './TDGUpdateCourse';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { generateCertTDG } from './TDGCertGenerator';
import { generateCertBlobTdg } from './TDGCertBlob';
import { uploadToS3 } from '../Certificate/UploadCertificate';

// Creates the certificate for TDG
const TDGCertificate = ({
  name,
  dateCompleted,
  expiryDate,
  employer,
  address,
  updatedCourse,
  courseId,
  userId,
  setSubmitted,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCertificateGenerated, setIsCertificateGenerated] = useState(false);
  const [status, setStatus] = useState('');
  useEffect(() => {
    if (name && dateCompleted && expiryDate && employer && address) {
      setDisabled(true);
    }
  }, [name, dateCompleted, expiryDate, employer, address]);

  const generateAndUploadCertificate = async () => {
    setIsLoading(true);
    setSubmitted(true);
    setStatus('');
    const pdfBlob = generateCertBlobTdg(
      name,
      dateCompleted,
      expiryDate,
      employer,
      address
    );
    const fileName = `${name}-TDG.pdf`;
    try {
      // Need to upload the employer and the address
      const res = await uploadToS3(pdfBlob, fileName);
      if (res.Location) {
        await UpdateCourse(updatedCourse, courseId, userId, res.Location);
        setIsCertificateGenerated(true);
      } else {
        console.log('There is no res.location');
        setStatus('An error occured');
      }
    } catch (error) {
      console.error('Error during certificate generation or upload', error);
      setStatus('An error occured');
      setSubmitted(false);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <p>{status}</p>
      {isLoading ? (
        <p>Certificate is generating this may take a couple of moments...</p>
      ) : (
        <>
          {!isCertificateGenerated ? (
            <>
              <PrimaryButton
                span="Create Certificate"
                disabled={!disabled}
                functionName={() => generateAndUploadCertificate()}
              />
            </>
          ) : (
            <>
              <PrimaryButton
                span="Download Certificate"
                disabled={!disabled}
                functionName={() =>
                  generateCertTDG(
                    name,
                    dateCompleted,
                    expiryDate,
                    employer,
                    address
                  )
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};
TDGCertificate.propTypes = {
  name: PropTypes.string,
  course: PropTypes.string,
  dateCompleted: PropTypes.string,
  expiryDate: PropTypes.string,
  employer: PropTypes.string,
  address: PropTypes.string,
  updatedCourse: PropTypes.object,
  courseId: PropTypes.string,
  userId: PropTypes.string,
  setSubmitted: PropTypes.func,
};

export default TDGCertificate;
