import styled from 'styled-components';

export const TDGCertContainer = styled.main`
  /* border: 1px solid blue; */
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing.s};
`;

export const TDGCertForm = styled.form`
  /* border: 1px solid green; */
`;

export const TDGButtonContainer = styled.div`
  /* border: 1px solid orange; */
  display: flex;
  justify-content: space-evenly;
  margin-top: ${({ theme }) => theme.spacing.s};
`;
