import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

// DELETE: Deletes a course by id - protected route
async function httpDeleteCourse(id) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/delete-course/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

// DELETE: Deletes a user by id - protected route
async function httpDeleteUser(id) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/admin/delete-user/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

// DELETE: Deletes a company by id - protected route
async function httpDeleteCompany(id) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/delete-company/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

// DELETE: Deletes a user course by id - protected route
async function httpDeleteUserCourse(userId, courseId) {
  const token = Auth.getToken();
  console.log('User id and course', userId, courseId);
  try {
    return await fetch(`${API_URL}/admin/${userId}/courses/${courseId}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

export {
  httpDeleteCompany,
  httpDeleteUser,
  httpDeleteCourse,
  httpDeleteUserCourse,
};
