import React from 'react';
import PropTypes from 'prop-types';

const DownArrowIcon = ({ functionName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="icon icon-arrow-thin-down-circle"
      onClick={functionName}
    >
      <circle cx="12" cy="12" r="10" className="primary" />
      <path
        className="secondary"
        d="M11 14.59V7a1 1 0 0 1 2 0v7.59l2.3-2.3a1 1 0 1 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.42l2.3 2.3z"
      />
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="12"
        cy="12"
        r="10"
        className="primary"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        className="secondary"
        d="M11 14.59V7a1 1 0 0 1 2 0v7.59l2.3-2.3a1 1 0 1 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.42l2.3 2.3z"
      />
    </svg>
  );
};

DownArrowIcon.propTypes = {
  functionName: PropTypes.func,
};

export default DownArrowIcon;
