import { httpGetAllUsers } from '../../../Hooks/admin/admin.get.hooks';

export const fetchUsers = async (id, setUsers, setSuccess) => {
  try {
    const found = await httpGetAllUsers(id);
    if (found) {
      setUsers(found);
    } else {
      setSuccess('No users found');
    }
  } catch (error) {
    setSuccess('An error occurred while fetching the users');
    console.error('Error fetching users:', error);
  }
};
