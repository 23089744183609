export const tags = [
  { id: 'all', label: 'All Courses' },
  { id: 'Auto Dealerships', label: 'Automotive Dealerships' },
  { id: 'Automotive Service', label: 'Automotive Service' },
  { id: 'Collision Centers', label: 'Collision Centers' },
  { id: 'Concrete', label: 'Concrete' },
  { id: 'Demolition', label: 'Demolition' },
  { id: 'Drywall', label: 'Drywall' },
  { id: 'Electrical', label: 'Electrical' },
  { id: 'Flooring', label: 'Flooring' },
  { id: 'Forestry', label: 'Forestry' },
  { id: 'Foundations', label: 'Foundations' },
  { id: 'Framing', label: 'Framing' },
  { id: 'Health Care', label: 'Health Care' },
  { id: 'Heavy Equipment', label: 'Heavy Equipment' },
  { id: 'Hospitality', label: 'Hospitality' },
  { id: 'HVAC Mechanical', label: 'HVAC Mechanical' },
  { id: 'Insulation', label: 'Insulation' },
  { id: 'Janitorial', label: 'Janitorial' },
  { id: 'Landscaping & Excavation', label: 'Landscaping & Excavation' },
  { id: 'Marine', label: 'Marine' },
  { id: 'Painting', label: 'Painting' },
  { id: 'Plumbing', label: 'Plumbing' },
  { id: 'Remediation', label: 'Remediation' },
  { id: 'Rental', label: 'Rental' },
  { id: 'Roofing', label: 'Roofing' },
  { id: 'Safety', label: 'Safety' },
  { id: 'Trucking', label: 'Trucking' },
  { id: 'Welding & Fabrication', label: 'Welding & Fabrication' },
];
