import { EachInput } from '../../Authentication/AuthStyles.styles';
import React from 'react';
import PropTypes from 'prop-types';

// Input container for the login
const InputContainer = ({ label, value, name, onChange, placeholder }) => {
  return (
    <EachInput>
      <label>{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        required
      />
    </EachInput>
  );
};
InputContainer.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default InputContainer;
