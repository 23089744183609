import React, { useState } from 'react';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { httpGetUsersByDateRange } from '../../Hooks/admin/admin.put.hooks';

const GetPaymentInfo = () => {
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Submitting', date1, date2);
    try {
      const response = await httpGetUsersByDateRange(date1, date2);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.xlsx');
      document.body.appendChild(link);
      link.click();
      setStatus('Completed');
    } catch (err) {
      setStatus('An error occurred in the backend');
    }
  };
  return (
    <>
      <h3>Get users who have purchased courses between 2 months</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Start Date:
            <input
              type="date"
              value={date1}
              onChange={(e) => setDate1(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            End Date:
            <input
              type="date"
              value={date2}
              onChange={(e) => setDate2(e.target.value)}
              required
            />
          </label>
        </div>
        <p>Status: {status}</p>
        <PrimaryButton functionName={handleSubmit} span="Download" />
      </form>
    </>
  );
};

export default GetPaymentInfo;
