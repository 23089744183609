import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  /* border: 1px solid orange; */
  ul {
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
    /* border: 1px solid green; */
    gap: ${({ theme }) => theme.spacing.xxs};
  }
  li {
    width: calc(50% - 4rem);
    /* border: 1px solid red; */
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  }
`;
