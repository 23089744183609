import styled from 'styled-components';

export const FormContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 100vh;
  height: auto;
  /* overflow-y: scroll; */
  /* border: 2px solid orange; */
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  h2 {
    border-bottom: 5px solid ${({ theme }) => theme.colors.yellow4};
    margin-bottom: ${({ theme }) => theme.spacing.m};
    margin-top: ${({ theme }) => theme.spacing.m};
    text-transform: uppercase;
  }
  h4 {
    text-transform: uppercase;
    border-bottom: 2px solid ${({ theme }) => theme.colors.yellow2};
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  img {
    max-width: 25%;
    max-height: 25%;
  }
  p {
    margin-bottom: ${({ theme }) => theme.spacing.m};
    font-weight: bold;
  }
  span {
    font-weight: normal;
  }
  form {
    /* border: 2px solid red; */
  }
  input,
  label,
  textarea {
    width: 60%;
  }
  label {
    font-weight: 500;
  }
  .formInputUnderline {
    border-bottom: 1px solid ${({ theme }) => theme.colors.offWhite};
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
`;

export const SubmitContainer = styled.section`
  /* border: 1px solid yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: ${({ theme }) => theme.spacing.xl};
  gap: ${({ theme }) => theme.spacing.xxxl};
`;

export const MappedResults = styled.section`
  /* border: 1px solid orange; */
  margin-top: ${({ theme }) => theme.spacing.xl};
  h4 {
    // color: pink;
    border-bottom: 2px solid ${({ theme }) => theme.colors.orange2};
  }
  li {
    /* border: 1px solid green; */
    background-color: ${({ theme }) => theme.colors.grey};
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing.xs};
    padding: ${({ theme }) => theme.spacing.xs};
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
  }
  p {
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

export const CreateFormContainer = styled.section`
  padding: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.m};
  border-radius: ${({ theme }) => theme.borderRadius};
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
`;

export const FileContainer = styled.section`
  display: flex;
  justify-content: space-between;
  margin-block: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  /* border-bottom: 1px solid #e6e6e6; */
`;

export const DateAndTime = styled.section`
  p {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grey6};
  }
  span {
    font-weight: 500;
    font-style: italic;
    color: ${({ theme }) => theme.colors.grey3};
    padding-left: ${({ theme }) => theme.spacing.xs};
  }
  .courseHover {
    &:hover {
      text-decoration: underline;
    }
  }
`;
