import React, { useState } from 'react';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SearchInput from '../SearchInputComponent.component';
import { InsideSearch } from '../AdminStyles.styles';
import PropTypes from 'prop-types';

const SearchCompanies = ({ companies, setFiltered, setErr }) => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (val) => {
    setSearch(val);
    performSearch(val);
  };

  const performSearch = (value) => {
    setErr('');
    const searchResults = companies.filter((c) =>
      c.companyName.toLowerCase().includes(value.toLowerCase())
    );
    if (!searchResults.length) {
      setErr('No companies found');
    }
    setFiltered(searchResults);
  };

  const setSearchCompanies = (e) => {
    e.preventDefault();
    performSearch(search);
  };

  return (
    <InsideSearch>
      <SearchInput
        value={search}
        name="companies"
        setValue={handleSearchChange}
      />
      <PrimaryButton functionName={setSearchCompanies} span="search" />
    </InsideSearch>
  );
};

SearchCompanies.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string,
    })
  ),
  setFiltered: PropTypes.func,
  setErr: PropTypes.func,
};

export default SearchCompanies;
