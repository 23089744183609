import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deleteCourse } from '../../../Utils/Courses/editCourse/deleteCourse';
import ConfirmDeleteButton from '../ConfirmDelete/ConfirmDeleteButton.component';
// import { useNavigate } from 'react-router-dom';

// Deletes a course
const DeleteCourseButton = ({ setSuccessState, setIsOpen, id }) => {
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  const handleDeleteSubmit = async () => {
    setLoading(true);
    await deleteCourse(id, setIsOpen, setLoading, setSuccessState);
    // navigate('/admin');
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ConfirmDeleteButton
          onDelete={handleDeleteSubmit}
          itemName="Delete Course"
        />
      )}
    </>
  );
};

DeleteCourseButton.propTypes = {
  id: PropTypes.string,
  setSuccessState: PropTypes.func,
  setIsOpen: PropTypes.func,
};

export default DeleteCourseButton;
