import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;

//POST: Creates a course - protected route
async function httpCreateCourse(formData) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/create-course`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(formData),
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates a company - protected route
async function httpCreateCompany(company, admin) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/create-company`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ company, admin }),
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates pdfs and images - protected route
async function httpCreateFiles(formData) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/create-files`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates logo for company - protected route
async function httpCompanyLogo(formData) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/admin/create-logo`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates a user from admin - protected route
async function httpCreateUser(user) {
  const token = Auth.getToken();

  try {
    return await fetch(`${API_URL}/admin/create-user`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(user),
    });
  } catch (err) {
    return err;
  }
}
// POST: Creates Attachments - protected route
async function httpCreateAtt(formData, id) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/admin/create-attachment/${id}`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    console.log('ERROR TOP: create att', err);
    return err;
  }
}
// POST: Creates PDF - protected route
async function httpCreatePdf(formData, id) {
  const token = Auth.getToken();
  console.log('Form Data for PDF', formData, id);
  try {
    return await fetch(`${API_URL}/admin/create-pdf/${id}`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    console.log('ERROR TOP: create pdf', err);
    return err;
  }
}
// POST: Creates Image - protected route
async function httpCreateImage(formData) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/admin/create-image`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    console.log('ERROR TOP: create image', err);
    return err;
  }
}

// POST: Sends the email and password to the client
async function httpSendAccountDetails(email, password) {
  const token = Auth.getToken();
  try {
    return await fetch(`${API_URL}/admin/send-account-details`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ email, password }),
    });
  } catch (err) {
    console.log('ERROR TOP: sending the account details', err);
    return err;
  }
}

export {
  httpCompanyLogo,
  httpCreateCompany,
  httpCreateCourse,
  httpCreateFiles,
  httpCreateUser,
  httpCreateAtt,
  httpCreatePdf,
  httpCreateImage,
  httpSendAccountDetails,
};
