import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../SearchInputComponent.component';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { InsideSearch } from '../AdminStyles.styles';

const SearchNewsletters = ({ newsletters, setFiltered, setErr }) => {
  const [search, setSearch] = useState('');
  const setSearchNewsletters = async (e) => {
    e.preventDefault();
    setErr('');
    const searchTerm = newsletters.toLowerCase();
    // This needs to be imlpemented
    setFiltered(searchTerm);
  };
  return (
    <InsideSearch>
      <SearchInput value={search} name="newletters" setValue={setSearch} />
      <PrimaryButton functionName={setSearchNewsletters} span="search" />
    </InsideSearch>
  );
};
SearchNewsletters.propTypes = {
  newsletters: PropTypes.array,
  setFiltered: PropTypes.func,
  setErr: PropTypes.func,
};

export default SearchNewsletters;
