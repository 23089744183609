import styled from 'styled-components';

export const TertiaryButtonContainer = styled.button`
  min-width: 250px;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacing.m};
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  background: none;
  border: none;
  span {
    color: ${({ theme }) => theme.colors.grey5};
    font-weight: bold;
    font-size: 0.8rem !important;
    text-overflow: ellipsis;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-bottom: 3px solid ${({ theme }) => theme.colors.orange4};
  }
  &:hover {
    background: none;
    color: ${({ theme }) => theme.colors.grey3};
  }
  span:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.yellow4};
  }
`;
