import React, { useState } from 'react';
import PrimaryButtonComponent from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { httpCreateNewsletter } from '../../../Hooks/newsletter/newsletter.post.hooks';
import PropTypes from 'prop-types';

const NewsletterSubmit = ({
  mainTitle,
  month,
  title1,
  title2,
  title3,
  content1,
  content2,
  content3,
}) => {
  const [succcess, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSuccess('Creating Newsletter...');
      const newsletter = {
        newsletterTitle: mainTitle,
        month,
        title1,
        title2,
        title3,
        content1,
        content2,
        content3,
        sentOut: false,
        markedForDeletion: null,
        setForDelete: false,
      };
      let res = await httpCreateNewsletter(newsletter);
      let data = await res.json();
      // console.log('Res', res, 'Data', data);
      if (res.ok) {
        setSuccess(data.msg || 'Newsletter created successfully');
      } else {
        setSuccess(data.msg || 'There was an error');
      }
    } catch (err) {
      // console.log(err);
      setSuccess('There was an error in the backend');
    }
  };
  return (
    <>
      <p>{succcess}</p>
      <PrimaryButtonComponent
        functionName={handleSubmit}
        span="Create Newsletter"
      />
    </>
  );
};

NewsletterSubmit.propTypes = {
  mainTitle: PropTypes.string,
  month: PropTypes.string,
  title1: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
};

export default NewsletterSubmit;
