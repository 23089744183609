import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PrimaryButton from '../../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { httpCertificateDetails } from '../../../../Hooks/user/user.put.hooks';
import { handleDownload } from '../../../../Quiz/Certificate/GenerateCert';
import { generateCertBlob } from '../../../../Quiz/Certificate/GenerateBlob';
import { uploadToS3 } from '../../../../Quiz/Certificate/UploadCertificate';
// import generateCertBlob

// Creates the certificate
const Certificate = ({
  name,
  course,
  dateCompleted,
  expiryDate,
  certNumber,
  courseId,
  userId,
  setSubmitted,
}) => {
  if (
    !name ||
    !course ||
    !dateCompleted ||
    !expiryDate ||
    !certNumber ||
    !courseId ||
    !userId ||
    !setSubmitted
  )
    return null;
  const [status, setStatus] = useState('');
  const createCert = async () => {
    setSubmitted(true);
    try {
      setSubmitted(true);
      const blob = generateCertBlob(
        name,
        course,
        dateCompleted,
        expiryDate,
        certNumber
      );
      const fileName = `${name}_${course}.pdf`;
      const res = await uploadToS3(blob, fileName);
      if (res.Location) {
        setStatus(
          'Completed uploading, cert is now being updated in the database'
        );
        let resB = await httpCertificateDetails(courseId, userId, res.Location);
        let data = await resB.json();
        setStatus(
          data.msg ||
            'Certificate details has completed, cert is now being created on desktop'
        );
        handleDownload(name, course, dateCompleted, expiryDate, certNumber);
      } else {
        setStatus('An error happened');
      }
    } catch (err) {
      setStatus('An error happened in the catch');
    } finally {
      setSubmitted(false);
    }
  };
  return (
    <>
      <p>{status}</p>
      <PrimaryButton
        span="Download & Upload Certificate"
        functionName={createCert}
      />
    </>
  );
};
Certificate.propTypes = {
  name: PropTypes.string,
  course: PropTypes.string,
  dateCompleted: PropTypes.string,
  expiryDate: PropTypes.string,
  certNumber: PropTypes.string,
  courseId: PropTypes.string,
  userId: PropTypes.string,
  setSubmitted: PropTypes.func,
};

export default Certificate;
