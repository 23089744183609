import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { MainContentContainer, MainSection } from '../OtherPages.styles';
import SideContainerComponent from '../SideContainer.component';

const TermsAndConditions = () => {
  return (
    <MainContentContainer>
      <HelmetProvider>
        <Helmet>
          <title>Terms & Conditions | Yoda Safety Services</title>
          <meta
            name="Terms & Conditions"
            content="Terms and conditions for Yoda Safety Services"
          />
        </Helmet>
      </HelmetProvider>
      <SideContainerComponent />
      <MainSection>
        <h2>Terms & Conditions</h2>
        <p>Last Changed: </p>
        <p>Coming Soon</p>
      </MainSection>
    </MainContentContainer>
  );
};

export default TermsAndConditions;
