import { httpActivateCompany } from '../../../Hooks/company/company.put.hooks';

export const activateCompany = async (
  id,
  setSuccessState,
  setIsOpen,
  setLoading
) => {
  try {
    const res = await httpActivateCompany(id);
    // const data = await res.json();
    if (res.ok) {
      setSuccessState('Company has been activated');
    } else {
      setSuccessState('Company could not be deactivated');
    }
  } catch (error) {
    setSuccessState('An error occurred while activating the company');
    console.error('Error activating company:', error);
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
