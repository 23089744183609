import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './Visuals/Colors';
import React, { useEffect, useState } from 'react';

// Pages
import NavBar from './Routes/MainComponents/navigation/NavbarComponent.component';
import Footer from './Routes/MainComponents/footer/FooterComponent.component';
import CookieConstent from './Cookies/CookieBanner.component';
import UnsubscribeFromNewsletter from './Newsletter/UnsubscribeNewsletter.component';
import BasePage from './Routes/MainComponents/base/BasePage.component';
// Main Page
import AboutUs from './Routes/AboutUs/AboutUsPage.component';
import ProgramDevelopment from './Routes/ProgramDevelopment/ProgramPage.component';
import Insurance from './Routes/Insurances/InsurancePage.component';
// Links to other websites for the legislation etc
import Resources from './Routes/Resources/ResourcePage.component';
import WCBCertified from './Routes/WcbCertified/WcbPage.component';
import OnlineTraining from './Routes/Online/TrainingOnlinePage.component';
import ClassroomTraining from './Routes/Classroom/TrainingClassroomPage.component';
import NotFound from './Routes/MainComponents/not-found/NotFoundPage.component';
import PrivacyPolicy from './Routes/PrivacyPolicy/PrivacyPolicyPage.component';
import TermsAndConditions from './Routes/Terms&Conditions/TermsAndConditionsPage.component';
import ContactUs from './Routes/ContactUs/ContactUs.component';
import BuyCourse from './BuyCourse/BuyPage.component';
import Purchase from './BuyCourse/Purchase.component';
// const NavBar = React.lazy(() => import('./Routes/MainComponents/navigation/NavbarComponent.component'));
// const Footer = React.lazy(() => import('./Routes/MainComponents/footer/FooterComponent.component'));
// const CookieConstent = React.lazy(() => import('./Cookies/CookieBanner.component'));
// const AboutUs = React.lazy(() => import('./Routes/AboutUs/AboutUsPage.component'));
// const ProgramDevelopment = React.lazy(() => import('./Routes/ProgramDevelopment/ProgramPage.component'));
// const Purchase = React.lazy(() => import('./BuyCourse/Purchase.component'));
// const NotFound = React.lazy(() => import('./Routes/MainComponents/not-found/NotFoundPage.component'));
// const ContactUs = React.lazy(() => import('./Routes/ContactUs/ContactUs.component'));
// const PrivacyPolicy = React.lazy(() => import('./Routes/PrivacyPolicy/PrivacyPolicyPage.component'));
// const Resources = React.lazy(() => import('./Routes/Resources/ResourcePage.component'));
// const WCBCertified = React.lazy(() => import('./Routes/WcbCertified/WcbPage.component'));
// const OnlineTraining = React.lazy(() => import('./Routes/Online/TrainingOnlinePage.component'));
// const ClassroomTraining = React.lazy(() => import('./Routes/Classroom/TrainingClassroomPage.component'));
// const BuyCourse = React.lazy(() => import('./BuyCourse/BuyPage.component'));

// Authentication
import AuthComponent from './Authentication/AuthenticationHome.component';
import ForgotPassword from './Authentication/PasswordReset/ForgotPassword';
import ResetPassword from './Authentication/PasswordReset/ResetPassword';
import Unauthorized from './Unauthorized/UnauthorizedComponent.component';

//Admin: Protected with AuthCheckAdmin
import Admin from './AdminRoutes/Admin/AdminHome.component';
import CreateUser from './AdminRoutes/CreateRoutes/CreateUser/UserCreate.component';
import CreateCompany from './AdminRoutes/CreateRoutes/CreateCompany/CompanyCreate.component';
import CreateCourse from './AdminRoutes/CreateRoutes/CreateCourse/CourseCreate.component';
import CreateNewsletter from './AdminRoutes/CreateRoutes/CreateNewsletter/NewsletterCreate.component';
import EditCourse from './AdminRoutes/EditRoutes/EditCourse/CourseEdit.component';
import EditUser from './AdminRoutes/EditRoutes/EditUser/UserEdit.component';
import EditCompany from './AdminRoutes/EditRoutes/EditCompany/CompanyEdit.component';
import EditManagersAndUsers from './AdminRoutes/EditRoutes/EditManagers/ManagersEdit.componentPage';
import EditNewsletter from './AdminRoutes/EditRoutes/EditNewsletter/NewsEdit.component';
import UserCourseDetials from './AdminRoutes/EditRoutes/EditUser/CourseDetails/UserCourseDetails.component';
import GetInfo from './AdminRoutes/GetInformation/GetInfo.component';
import NewslettersSubscribersList from './AdminRoutes/Admin/NewsletterSubscribers.component';

//User: Protected with AuthCheckUser
import UserHome from './UserRoutes/UserHomepage/UserHomePage.component';
import UserSettings from './UserRoutes/UserSettings/UserSettingsHome.component';

//Course: Protected with AuthCheckUser
//import BuyCourse from './BuyCourse/BuyCoursePage/buyCourse.component';
import QuizComponent from './Quiz/QuizHomepage.component';
import CourseComplete from './Quiz/Completed/QuizCompletePage.component';
import IncompleteCourse from './Quiz/Incomplete/QuizIncompletePage.component';
import CourseHomepage from './TakeCourse/CourseHomepage.component';
import SingleCourse from './Courses/EachCoursePage.component';
import CourseReview from './UserRoutes/UserCourseReview/CourseReview.component';

// Company: Protected with AuthCheckManager - Imported but commented out until development is finished
import EditManagerOrEmployee from './AdminRoutes/EditRoutes/EditManagers/EditManagerOrEmployee';
import CompanyHome from './CompanyRoutes/CompanyHomepage/CompanyHome.component';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TDGCertificateHomepage from './Quiz/TDGCertificate/TDGCertHomepage.component';
import CreateImage from './AdminRoutes/CreateRoutes/CreateImage/CreateImage.component';
import NewsletterModal from './Newsletter/NewsletterModal.component';
import CoursePurchasedPage from './BuyCourse/CoursePurchase.component';

function App() {
  const queryClient = new QueryClient();
  const [showEmailModal, setShowEmailModal] = useState(false);
  useEffect(() => {
    const isFirstVisit = localStorage.getItem('isFirstVisit');
    if (!isFirstVisit) {
      setShowEmailModal(true);
      localStorage.setItem('isFirstVisit', 'false');
    }
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <div style={{ flex: '1 0 auto', border: '1px solid black' }}>
              <NavBar />
              <CookieConstent />
              {showEmailModal && (
                <NewsletterModal setShowEmailModal={setShowEmailModal} />
              )}
              <Routes>
                {/* Main public routes */}
                <Route path="/" element={<BasePage />}>
                  <Route index element={<AboutUs />} />
                  <Route path="program" element={<ProgramDevelopment />} />
                  <Route path="purchase" element={<Purchase />} />
                  <Route
                    path="terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route
                    path="unsubscribe-from-newsletter"
                    element={<UnsubscribeFromNewsletter />}
                  />
                  <Route path="buy-course/:id" element={<BuyCourse />} />
                  <Route
                    path="course-purchased/:id"
                    element={<CoursePurchasedPage />}
                  />
                  <Route path="contact-us" element={<ContactUs />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="resources" element={<Resources />} />
                  <Route path="insurance" element={<Insurance />} />
                  <Route path="wcb-certified" element={<WCBCertified />} />
                  <Route path="online" element={<OnlineTraining />} />
                  <Route path="classroom" element={<ClassroomTraining />} />
                  <Route path="login" element={<BasePage />}>
                    <Route index element={<AuthComponent />} />
                    <Route
                      path="forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="reset-password/:token"
                      element={<ResetPassword />}
                    />
                  </Route>
                  <Route path="unauthorized" element={<Unauthorized />} />
                </Route>
                {/* Admin Routes  */}
                <Route path="/admin" element={<BasePage />}>
                  <Route index element={<Admin />} />
                  <Route path="create-course" element={<CreateCourse />} />
                  <Route path="create-company" element={<CreateCompany />} />
                  <Route path="create-user" element={<CreateUser />} />
                  <Route path="create-image" element={<CreateImage />} />
                  <Route path="information" element={<GetInfo />} />
                  <Route
                    path="newsletter-subscribers"
                    element={<NewslettersSubscribersList />}
                  />
                  <Route
                    path="create-newsletter"
                    element={<CreateNewsletter />}
                  />
                  {/* <Route path='course' element={<AllCourses />} /> */}
                  <Route path="edit-user/:id" element={<EditUser />} />
                  <Route
                    path="edit-user/:id/course/:courseId"
                    element={<UserCourseDetials />}
                  />
                  <Route
                    path="edit-newsletter/:id"
                    element={<EditNewsletter />}
                  />
                  <Route path="edit-course/:id" element={<EditCourse />} />
                  <Route path="edit-company/:id" element={<BasePage />}>
                    <Route index element={<EditCompany />} />
                    <Route
                      path="managers-employees"
                      element={<EditManagersAndUsers />}
                    />
                    <Route
                      path="managers-employees/:userId"
                      element={<EditManagerOrEmployee />}
                    />
                  </Route>
                </Route>
                {/* User Routes */}
                <Route path="/user" element={<BasePage />}>
                  <Route index element={<UserHome />} />
                  <Route path="settings/:id" element={<UserSettings />} />
                </Route>

                {/* Course Routes */}
                <Route path="course" element={<BasePage />}>
                  <Route index path=":id" element={<SingleCourse />} />
                  <Route path="home/:id" element={<CourseHomepage />} />
                  <Route path="quiz/:id" element={<QuizComponent />}></Route>
                  <Route
                    path="quiz/complete/:id"
                    element={<CourseComplete />}
                  />
                  <Route
                    path="tdg-certificate/:id"
                    element={<TDGCertificateHomepage />}
                  />
                  <Route
                    path="quiz/incomplete/:id"
                    element={<IncompleteCourse />}
                  />
                  <Route path="review/:id" element={<CourseReview />} />
                </Route>

                {/* Company Routes */}
                <Route path="company" element={<BasePage />}>
                  <Route index element={<CompanyHome />} />
                </Route>

                {/* <Route path='cart/:id' element={<BuyCourse />} /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </div>
          </BrowserRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
