import React from 'react';
import PropTypes from 'prop-types';

const ActiveCourse = ({
  activeCourse,
  setActiveCourse,
  containerClassName,
  label,
}) => {
  // console.log('Active Course', activeCourse);
  return (
    <div
      className={containerClassName}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
      }}
    >
      <label>{label}</label>
      <input
        name="activeCourse"
        value={activeCourse}
        type="checkbox"
        style={{ padding: '1rem', height: '25px' }}
        checked={activeCourse}
        onChange={(e) => setActiveCourse(e.target.checked)}
      />
    </div>
  );
};
ActiveCourse.propTypes = {
  activeCourse: PropTypes.bool,
  setActiveCourse: PropTypes.func,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
};

export default ActiveCourse;
