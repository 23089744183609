import Auth from '../../Helpers/auth';
const API_URL = process.env.REACT_APP_BACKEND_URL;
// GET: Fetches a company - protected route
async function httpGetCompany(id) {
  const token = Auth.getToken();

  try {
    let company = await fetch(`${API_URL}/companies/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return company.json();
  } catch (err) {
    return err;
  }
}
// GET: Fetches the companys managers
async function httpGetCompanyManagers(id) {
  const token = Auth.getToken();

  try {
    let managers = await fetch(`${API_URL}/companies/managers/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return managers.json();
  } catch (err) {
    return err;
  }
}

// GET: Fetches the company's employees
async function httpGetCompanyEmployees(id) {
  const token = Auth.getToken();

  try {
    let employees = await fetch(`${API_URL}/companies/employees/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return employees.json();
  } catch (err) {
    return err;
  }
}

export { httpGetCompany, httpGetCompanyManagers, httpGetCompanyEmployees };
