import styled from 'styled-components';

export const DestructiveButtonContainer = styled.button`
  min-width: 130px;
  height: 40px;
  width: auto;
  /* padding: 5px 10px; */
  padding: 0.6rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  height: auto;
  padding: 0.6rem 1.5rem;
  border: 2px solid ${({ theme }) => theme.colors.errorRed};
  background: ${({ theme }) => theme.colors.errorRed};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  span {
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.white} !important;
    font-size: 0.8rem !important;
    font-style: normal !important;
    /* width: 95px; */
    letter-spacing: 0.1rem !important;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.lightRed};
    color: ${({ theme }) => theme.colors.errorRed};
    border: 2px solid ${({ theme }) => theme.colors.errorRed};
  }
  span:active {
    color: ${({ theme }) => theme.colors.errorRed};
  }
  span:hover {
    color: ${({ theme }) => theme.colors.errorRed};
  }
`;
