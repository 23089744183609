import { EachDiv } from './EachStyles.styles';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton.component';
import PropTypes from 'prop-types';
import React from 'react';

// For Editing a Single Entity
const EachComponent = ({ key = '', name = '', route = '', id = '' }) => {
  const navigate = useNavigate();

  const editItem = (e) => {
    e.preventDefault();
    navigate(route);
  };
  return (
    <EachDiv key={id}>
      <h4 key={key}>{name}</h4>
      <SecondaryButton functionName={editItem} span="Edit" />
    </EachDiv>
  );
};
EachComponent.propTypes = {
  key: PropTypes.string,
  name: PropTypes.string,
  route: PropTypes.string,
  id: PropTypes.string,
};

export default EachComponent;
