import React, { useState } from 'react';
import PrimaryButtonComponent from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import PropTypes from 'prop-types';
import { httpEditNewsletter } from '../../../Hooks/newsletter/newsletter.put.hooks';

const NewsletterEditSubmit = ({ id, newsletter }) => {
  const [status, setStatus] = useState('');
  const handleSubmit = async (e) => {
    e.stopPropagation();
    try {
      setStatus('Editing Newsletter...');
      let res = await httpEditNewsletter(id, newsletter);
      let data = await res.json();
      if (res.ok) {
        setStatus(data.msg || 'Newsletter edited successfully');
      } else {
        setStatus(data.msg || 'There was an error');
      }
    } catch (err) {
      console.log(err);
      setStatus('There was an error in the backend');
    }
  };
  return (
    <>
      <p>{status}</p>
      <PrimaryButtonComponent
        functionName={handleSubmit}
        span="Edit Newsletter"
      />
    </>
  );
};
NewsletterEditSubmit.propTypes = {
  newsletter: PropTypes.object,
  id: PropTypes.string,
};
export default NewsletterEditSubmit;
