import { SubmitContainer } from '../../../CreateRoutes/CreateForm.styles';
import EditUserSubmit from '../UserEditSubmit.component';
import DisableUserButton from '../UserEditDisable.component';
import DeleteUserButton from '../UserEditDelete.component';
import ActivateUserButton from '../UserEditActivate.component';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonEditUser = ({
  user,
  password = '',
  setIsOpen,
  setSuccessState,
  successState,
}) => {
  return (
    <>
      <SubmitContainer>
        <p>{successState}</p>
        {user.msg ? <p>{user.msg + ' or select activate user'}</p> : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignContent: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <EditUserSubmit
            user={user}
            password={password}
            setIsOpen={setIsOpen}
            setSuccessState={setSuccessState}
          />
          {user?.active ? (
            <DisableUserButton
              id={user?._id}
              setIsOpen={setIsOpen}
              setSuccessState={setSuccessState}
            />
          ) : (
            <ActivateUserButton
              id={user?._id}
              setIsOpen={setIsOpen}
              setSuccessState={setSuccessState}
            />
          )}
          <DeleteUserButton
            id={user?._id}
            setIsOpen={setIsOpen}
            setSuccessState={setSuccessState}
          />
        </div>
      </SubmitContainer>
    </>
  );
};

ButtonEditUser.propTypes = {
  user: PropTypes.object,
  password: PropTypes.string,
  setIsOpen: PropTypes.func,
  setSuccessState: PropTypes.func,
  successState: PropTypes.string,
};

export default ButtonEditUser;
