import { httpTdgCertificateDetails } from '../../Hooks/user/user.put.hooks';

export const UpdateCourse = async (course, courseId, userId, url) => {
  try {
    await httpTdgCertificateDetails(course, courseId, userId, url);
  } catch (err) {
    console.log(err);
    return err;
  }
};
