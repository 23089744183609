import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { editCourse } from '../../../Utils/Courses/editCourse/editCourse';
import DeleteCourseButton from './CourseDelete.component';
// Function for editing the course
const EditCourseSubmit = ({
  course = [],
  questions = [],
  pdf = '',
  notes = '',
  videoLinks = [],
  image = '',
  expiryDate = 0,
  certificatePrefix = 0,
  courseName = '',
  tags = [],
  description = '',
  price = 0,
  successState = '',
  setSuccessState,
  setIsOpen,
  activeCourse = false,
  partTwo = false,
  att = [],
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [successStateA, setSuccessStateA] = useState('');
  // const [successStateB, setSuccessStateB] = useState('');
  const [successStateC, setSuccessStateC] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fullCourse = {
      ...course,
      courseName: courseName,
      price: price,
      description: description,
      tags: tags,
      notes: notes,
      videoLinks: videoLinks,
      quiz: questions,
      expiryDate: expiryDate,
      partTwo: partTwo,
      image: image,
      certificatePrefix: certificatePrefix,
      active: activeCourse,
    };
    const formDataA = new FormData();
    const formDataC = new FormData();
    if (pdf) {
      const pdfFile = new File([pdf], pdf.name.replace(/\s+/g, '_'), {
        type: pdf.type,
      });
      formDataA.append('pdf', pdfFile);
    }

    const stringAttachments = att?.filter((item) => typeof item === 'string');
    const fileAttachments = att?.filter((item) => item instanceof File);
    // We upload the attachments if there are any
    if (fileAttachments && fileAttachments.length > 0) {
      fileAttachments.forEach((file, index) => {
        const attachmentFile = new File(
          [file],
          file.name.replace(/\s+/g, '_'),
          { type: file.type }
        );
        formDataC.append(`attachment_${index}`, attachmentFile);
      });
    }
    fullCourse.attachments = stringAttachments || [];
    await editCourse(
      id,
      fullCourse,
      formDataA,
      formDataC,
      setSuccessState,
      setSuccessStateA,
      setSuccessStateC,
      setIsOpen,
      setLoading
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        Main Data: <p>{successState}</p>
        PDF: <p>{successStateA}</p>
        Attachments: <p>{successStateC}</p>
      </div>
      <div style={{ display: 'flex', gap: '3rem' }}>
        <DeleteCourseButton
          setSuccessState={setSuccessState}
          setIsOpen={setIsOpen}
          id={id}
        />
        {loading ? (
          <Loader />
        ) : (
          <PrimaryButton functionName={handleSubmit} span="Edit Course" />
        )}
      </div>
    </div>
  );
};
EditCourseSubmit.propTypes = {
  questions: PropTypes.array,
  pdf: PropTypes.string,
  notes: PropTypes.string,
  videoLinks: PropTypes.array,
  image: PropTypes.string,
  expiryDate: PropTypes.number,
  certificatePrefix: PropTypes.string,
  courseName: PropTypes.string,
  tags: PropTypes.array,
  description: PropTypes.string,
  price: PropTypes.number,
  successState: PropTypes.string,
  setSuccessState: PropTypes.func,
  setIsOpen: PropTypes.func,
  activeCourse: PropTypes.bool,
  att: PropTypes.array,
  course: PropTypes.array,
  partTwo: PropTypes.bool,
};

export default EditCourseSubmit;
