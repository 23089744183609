import EachComponent from '../../../Components/Entity/EachComponent.component';
import React, { useState, useEffect } from 'react';
import SearchCourses from './SearchCoursesComponent.component';
import useCourses from '../../../Utils/Courses/allCourses/useCourses';

// Results for courses tab on Admin Home Page
const CoursesTab = () => {
  const { courses, successState } = useCourses();

  const [filtered, setFiltered] = useState([]);
  const [err, setErr] = useState('');

  // setCourses, setSuccessState
  useEffect(() => {
    setFiltered(courses);
  }, [courses]);

  return (
    <section>
      <h3>COURSES</h3>
      <SearchCourses
        courses={courses}
        setFiltered={setFiltered}
        setErr={setErr}
      />
      {err && <p style={{ color: 'red' }}>{err}</p>}
      {successState && <p style={{ color: 'green' }}>{successState}</p>}
      {filtered?.map((c) => {
        return (
          <EachComponent
            key={c?._id}
            name={c?.courseName}
            route={`/admin/edit-course/${c?._id}`}
          />
        );
      })}
    </section>
  );
};

export default CoursesTab;
